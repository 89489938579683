import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { useSelector } from "react-redux";
import { getAdminData } from "../../../../redux/features/slices/admin/AdminSlice";

export default function AdminProfile() {
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const { adminData } = useSelector((state) => state.admin);
 
  let initialValues = {
    firstName: adminData ? adminData?.firstName : "",
    lastName: adminData ? adminData?.lastName : "",
    email: adminData ? adminData?.email : "",
    company: adminData ? adminData?.company : "",
    profile_image: adminData ? adminData?.profile : "",
    isSubmit: false,
  };
  const handleSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    // const urlencoded = new URLSearchParams();
    const urlencoded = new FormData();
    urlencoded.append("firstName", values.firstName);
    urlencoded.append("lastName", values.lastName);
    urlencoded.append("email", values.email);
    urlencoded.append("company", values.company);
    urlencoded.append("profile", values.profile_image);

    dispatch(PageIndex.adminUpdateProfile(urlencoded)).then((res) => {
      if (res?.payload?.status == 200) {
        dispatch(getAdminData(res?.payload?.data));
        setTimeout(() => {
          navigate("/admin/dashboard");
        }, 1000);
      } else {
        setFieldValue("isSubmit", false);
      }
    });
  };
  const handleCancelForm = () => {
    navigate("/admin/dashboard");
  };
  return (
    <>
      <Index.Box className="dashboard-content admin-edit-profile">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              Edit Profile
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <PageIndex.Formik
          initialValues={initialValues}
          validationSchema={PageIndex.adminUpdateProfileValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <PageIndex.Form>
              <Index.Box className="create-new-project-card admin-edit-profile-create-new-project-card common-card">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 5",
                        md: "span 5",
                        lg: "span 5",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-change-password-flex">
                        <Index.Box className="profile-header">
                          <Index.Box className="profile-input-box">
                            <label htmlFor="btn-upload">
                              <img
                                src={
                                  values?.profile_image
                                    ? values?.profile_image instanceof Blob
                                      ? URL.createObjectURL(
                                          values.profile_image
                                        )
                                      : `${process.env.REACT_APP_API_BASE_IMAGE_URL}${values.profile_image}`
                                    : PageIndex.Png.profileLogo
                                }
                                alt="Image Preview"
                                className="profile-img mb-15"
                              />

                              <input
                                id="btn-upload"
                                name="profile_image"
                                className="profile-upload-btn"
                                type="file"
                                accept="image/*"
                                // hidden
                                onChange={(e) => {
                                  setFieldValue(
                                    "profile_image",
                                    e.target.files[0]
                                  );
                                }}
                              />
                              <Index.Box className="profile-edit-main">
                              <Index.Tooltip title="Edit" placement="right">
                                <Index.Box className="edit-div">
                                  {" "}
                                  <img
                                    className="profile-edit-img"
                                    src={PageIndex.Png.edit}
                                    alt=""
                                  />
                             
                                </Index.Box>
                                </Index.Tooltip>
                              </Index.Box>
                            </label>
                          
                          </Index.Box>
                        
                        </Index.Box>
                        
                      </Index.Box>
                      {errors.profile_image && touched.profile_image && (
                              <p className="error-text">
                                {errors.profile_image}
                              </p>
                            )}
                    </Index.Box>
                 
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-change-password-flex">
                        <Index.Box className="admin-change-password-flex-left">
                          <Index.FormHelperText className="label-input-title">
                            First Name:{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="admin-change-password-flex-right">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder="First name"
                            name="firstName"
                            value={values?.firstName}
                            onChange={(e)=>{
                              const newValue = e.target.value.replace(/\s/g, ""); 
                                     setFieldValue("firstName", newValue)}}
                            onBlur={handleBlur}
                            // onKeyPress={handleKeyPress}
                          />
                          {errors.firstName && touched.firstName && (
                            <p className="error-text">{errors.firstName}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-change-password-flex">
                        <Index.Box className="admin-change-password-flex-left">
                          <Index.FormHelperText className="label-input-title">
                            Last Name:{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="admin-change-password-flex-right">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder="Last name"
                            name="lastName"
                            value={values?.lastName}
                            
                            onBlur={handleBlur}
                            onChange={(e)=>{
                              const newValue = e.target.value.replace(/\s/g, ""); 
                                     setFieldValue("lastName", newValue)}}
                            // onKeyPress={handleKeyPress}
                          />
                          {errors.lastName && touched.lastName && (
                            <p className="error-text">{errors.lastName}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-change-password-flex">
                        <Index.Box className="admin-change-password-flex-left">
                          <Index.FormHelperText className="label-input-title">
                            Email ID:{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="admin-change-password-flex-right">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder="admin@gmail.com"
                            name="email"
                            value={values?.email}
                          //   onChange={(e)=>
                          //  {
                          //   const emailValue=e.target.value;
                          //    setFieldValue("email",emailValue.trim())}
                          // }
                          onChange={(e)=>{
                              const newValue = e.target.value.replace(/\s/g, ""); 
                                     setFieldValue("email", newValue)}}
                            onBlur={handleBlur}
                          />
                          {errors.email && touched.email && (
                            <p className="error-text">{errors.email}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-change-password-flex">
                        <Index.Box className="admin-change-password-flex-left">
                          <Index.FormHelperText className="label-input-title">
                            Company:{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="admin-change-password-flex-right">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder="Company name"
                            name="company"
                            value={values?.company}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.company && touched.company && (
                            <p className="error-text">{errors.company}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="user-btn-flex">
                       
                        <PageIndex.PrimaryButton
                          type="submit"
                          title="Save"
                          btnLabel="Save"
                          disabled={values?.isSubmit}
                          startIcon={
                            values?.isSubmit && (
                              <Index.CircularProgress
                                size={15}
                                color="inherit"
                              />
                            )
                          }
                          className={
                            values?.isSubmit
                              ? "create-user-btn comman-primary-btn login-btn disabled-login-button save-admin-btn"
                              : "create-user-btn comman-primary-btn login-btn save-admin-btn"
                          }
                        />
                         <PageIndex.BorderButton
                          className="cancel-user-btn border-btn cancel-admin-btn"
                          btnLabel="Cancel"
                          title="Cancel"
                          onClick={handleCancelForm}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </PageIndex.Form>
          )}
        </PageIndex.Formik>
      </Index.Box>
    </>
  );
}
