import * as Yup from "yup";

// const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
// const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,64}@(?:(?=[a-zA-Z0-9-]{1,255}\.)(?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.){1,127}[a-zA-Z]{2,63}$/;
const passwordRegex = {
  lowercase: /^(?=.*[a-z])/,
  uppercase: /^(?=.*[A-Z])/,
  number: /^(?=.*\d)/,
  specialChar: /^(?=.*[!@#$%^&*])/,
  length: /^(?=.{8,})/,
};
// const passwordRegex =
//   /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).+$/;
const startSpace = /^(?!\s).*$/;
const maxchar=""

export const adminLoginValidationSchema = ({ isLoginPage }) =>
  Yup.object().shape({
    email: Yup.string()
      .matches(startSpace, "Starting space not allow")
      .matches(emailRegex, "Please enter valid Email")
      .required("Please enter Email"),
    password: isLoginPage
      ? Yup.string()
          .matches(startSpace, "Starting space not allow")
          .required("Please enter Password")
      : Yup.string(),
  });

export const adminVerifyOtpValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .matches(/^\d{4}$/, "OTP must be a 4-digit number")
    .required("Please enter your OTP"),
});
export const adminResetPasswordValidationSchema = Yup.object().shape({
  Password: Yup.string()
  .matches(startSpace, "Starting space not allow")
  .matches(passwordRegex.lowercase, 'Password must contain at least one lowercase letter')
  .matches(passwordRegex.uppercase, 'Password must contain at least one uppercase letter')
  .matches(passwordRegex.number, 'Password must contain at least one number')
  .matches(passwordRegex.specialChar, 'Password must contain at least one special character')
  .matches(passwordRegex.length, 'Password must be at least 8 characters long')
  .min(8, "Password must be at least 8 characters")
    .max(15, "Maximum 15 charaters allowed")
    // .max(30, "Maximum 30 charaters allowed")
  .required("Please enter New Password"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("Password"), null],
      "Password and Confirm Password should be same"
    )
    .min(8, "Confirm Password must be at least 8 characters")
    .required("Please enter Confirm Password"),
});

export const adminChangePasswordValidationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .required("Please enter Old Password"),
  newPassword: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(passwordRegex.lowercase, 'Password must contain at least one lowercase letter')
    .matches(passwordRegex.uppercase, 'Password must contain at least one uppercase letter')
    .matches(passwordRegex.number, 'Password must contain at least one number')
    .matches(passwordRegex.specialChar, 'Password must contain at least one special character')
    .matches(passwordRegex.length, 'Password must be at least 8 characters long')
    .min(8, "Password must be at least 8 characters")
    .max(15, "Maximum 15 charaters allowed")
    
    .required("Please enter New Password"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Password and Confirm Password must be same"
    )
    .min(8, "Confirm Password must be at least 8 characters")
    .required("Please enter Confirm Password"),
});

export const adminUpdateProfileValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(/^[A-Za-z\s]*$/, "Enter only alphabet characters")
    .required("Please enter your First Name")
    .max(15 ,"First Name at most 15 characters")
    .min(2 ,"First Name must be at least 2 characters"),
    
  lastName: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(/^[A-Za-z\s]*$/, "Enter only alphabet characters")
    .required("Please enter your Last Name")
    .max(15,"Last Name at most 15 characters")
    .min(2 ,"Last Name must be at least 2 characters"),
  email: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(emailRegex, "Please enter valid Email")
    .required("Please enter your Email"),
  company: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(/^[A-Za-z\s]*$/, "Enter only alphabet characters")
    .max(255,"maximun 255 charaters allowed")
    .min(2 ,"Company must be at least 2 characters")
    .required("Please enter Company"),
  profile_image: Yup.mixed()
    // .test(
    //   "fileSize",
    //   "Image size is too large",
    //   (value) => value && value.size <= 102400
    // )
    .test("FileType", "Unsupported file type", (value) => {
      if (!value) return true;
      if (!value?.name) {
        const file = value?.split(".")[1];
        return ["jpeg", "jpg", "png"].includes(file);
      } else {
        return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
      }
    }),
});
