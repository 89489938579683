import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes as Routess } from "react-router-dom";
import PageIndex from "../container/PageIndex";
// import { createBrowserHistory } from "history";

//Admin
import AdminPrivateRoute from "./AdminPrivateRoute";
import AdminLogin from "../container/admin/auth/login/Login";
import AdminForgotPassword from "../container/admin/auth/forgotpassword/ForgotPassword";
import AdminResetPassword from "../container/admin/auth/resetpassword/ResetPassword";
import AdminVerifyOtp from "../container/admin/auth/otp/OTP";
import AdminDashboard from "../container/admin/pages/adminDashboard/AdminDashboard";
import AdminLayOut from "../container/admin/pages/adminLayout/AdminLayOut";
import SubscriptionList from "../container/admin/pages/subscription/SubscriptionList";
import AddEditSubscription from "../container/admin/pages/subscription/AddEditSubscription";
import EmailTemplateList from "../container/admin/pages/emailTemplate/EmailTemplateList";
import AddEditEmailTemplate from "../container/admin/pages/emailTemplate/AddEditEmailTemplate";
import AdminChangePassword from "../container/admin/pages/changePassword/AdminChangePassword";
import AdminProfile from "../container/admin/pages/profile/AdminProfile";
import AdminAnalytics from "../container/admin/pages/adminAnalytics/AdminAnalytics";
import UserManagement from "../container/admin/pages/userMangement/UserManagement";
import UserDetails from "../container/admin/pages/userMangement/userDetails/UserDetails";
import UserPaymentDetails from "../container/admin/pages/userMangement/userDetails/UserPaymentDetails";
import Payment from "../container/admin/pages/payment/Payment";
import AdminProjectReport from "../container/admin/pages/adminProjectReport/AdminProjectReport";
import AdminQRCodeReport from "../container/admin/pages/adminQRCodeReport/AdminQRCodeReport";
import HeatMap from "../container/admin/pages/adminAnalytics/charts/heatMap/HeatMap";
import AccountSetting from "../container/admin/pages/accountSetting/AccountSetting";

//User
import Login from "../container/user/auth/login/Login";
import ForgotPassword from "../container/user/auth/forgotpassword/ForgotPassword";
import LayOut from "../container/user/pages/layout/LayOut";
import Dashboard from "../container/user/pages/dashboard/Dashboard";
import Register from "../container/user/auth/register/Register";
import ResetPassword from "../container/user/auth/resetpassword/ResetPassword";
import OTP from "../container/user/auth/otp/OTP";
import ProjectDirectory from "../container/user/pages/projectdirectory/ProjectDirectory";
import QRCode from "../container/user/pages/qrcode/QRCode";
import AddProject from "../container/user/pages/addproject/AddProject";
import MenuReport from "../container/user/pages/projectreport/PojectReport";
import UploadSuccessfully from "../container/user/pages/uploadsuccessfully/UploadSuccessfully";
import MediaLibrary from "../container/user/pages/medialibrary/MediaLibrary";
import Analytics from "../container/user/pages/analytics/Analytics";
import ArProjectStudio from "../container/user/pages/arprojectstudio/ArProjectStudio";
import SaveAsDraft from "../container/user/pages/saveasdraft/SaveAsDraft";
import SaveAndPublish from "../container/user/pages/saveandpublish/SaveAndPublish";
import AskToGenerateQr from "../container/user/pages/asktogenerateqr/AskToGenerateQr";
import QrCodeGeneration from "../container/user/pages/qrcodegeneration/QrCodeGeneration";
import PublishedQrCode from "../container/user/pages/publishedqrcode/PublishedQrCode";
import NewProject from "../container/user/pages/newproject/NewProject";
import QRCodeList from "../container/user/pages/qrcodelist/QRCodeList";
import ViewQRCode from "../container/user/pages/viewqrcode/ViewQRCode";
import Profile from "../container/user/pages/profile/Profile";
import BulkQrCode from "../container/user/pages/bulkqrcode/BulkQrCode";
import SelectQrCode from "../container/user/pages/selectqrcode/SelectQrCode";
import BulkQrSuccessfully from "../container/user/pages/bulkqrsuccessfully/BulkQrSuccessfully";
import Payments from "../container/user/pages/payments/Payments";



import UploadFile from "../container/user/pages/uploadfile/UploadFile";
import Subscription from "../container/user/pages/subscription/Subscription";
import Loader from "../component/common/loader/Loader";
import QRCodeReport from "../container/user/pages/qrcodereport/QRCodeReport";
import DeleteModal from "../component/common/deletemodal/DeleteModal";
import UserPrivateRoute from "./UserPrivateRoute";
import ProjectDetails from "../container/user/pages/projectdetails/ProjectDetails";
import HelpAndSupport from "../container/user/pages/helpandsupport/HelpAndSupport";
import PublishedAndQrGenerated from "../container/user/pages/publishedandqrgenerated/PublishedAndQrGenerated";
import { useDispatch, useSelector } from "react-redux";
import { setdarkMode } from "../redux/features/slices/user/UserSlice";
import EditProjectDetails from "../container/user/pages/editprojectdetails/EditProjectDetails";


//#endregion

// const history = createBrowserHistory();
export default function Routes() {
  const [darkMode, setDarkMode] = useState(false);
const mode=useSelector((state)=>state.user.darkMode)
const dispatch=useDispatch()
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
   dispatch(setdarkMode(!darkMode))
   
  };
  console.log(darkMode,"darkMode");
  useEffect(()=>{
    mode ? document.body.classList.add("dark-theme"): document.body.classList.remove("dark-theme");
  },[mode])
  return (
    // <div className={mode ? "App dark-theme":"App"}>
    <div className="App">
    <BrowserRouter>
      <Routess>
        {/* Admin Routes Start */}
        <Route path="/admin/">
          <Route path="login" element={<AdminLogin />} />
          <Route path="forgot-password" element={<AdminForgotPassword />} />
          <Route path="otp" element={<AdminVerifyOtp />} />
          <Route path="reset-password" element={<AdminResetPassword />} />
        </Route>

        <Route element={<AdminPrivateRoute />}>
          <Route path="/admin/" element={<AdminLayOut toggleDarkMode={toggleDarkMode}/>}>
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="subscription-plan" element={<SubscriptionList />} />
            <Route
              path="subscription-plan/add"
              element={<AddEditSubscription />}
            />
             <Route
              path="subscription-plan/edit/:id"
              element={<AddEditSubscription />}
            />
            <Route path="email-template" element={<EmailTemplateList />} />
            <Route
              path="email-template/add"
              element={<AddEditEmailTemplate />}
            />
             <Route
              path="email-template/edit"
              element={<AddEditEmailTemplate />}
            />
            <Route path="user-management" element={<UserManagement />} />
            <Route
              path="user-management/user-details"
              element={<UserDetails />}
            />
            <Route
              path="user-management/user-details-payment"
              element={<UserPaymentDetails />}
            />
            <Route path="/admin/payment" element={<Payment />} />
            <Route path="change-password" element={<AdminChangePassword />} />
            <Route path="edit-profile" element={<AdminProfile />} />
            <Route path="analytic" element={<AdminAnalytics />} />
            <Route path="project-report" element={<AdminProjectReport />} />
            <Route path="qr-code-report" element={<AdminQRCodeReport />} />
            <Route path="account-setting" element={<AccountSetting />} />

          </Route>
        </Route>

        {/* Admin Routes End */}

        {/* User Routes Start */}
          <Route path="/" element={<Login />} />
        <Route path="/user">
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="register" element={<Register />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="otp" element={<OTP />} />
        </Route>
        <Route element={<UserPrivateRoute />}>
        <Route path="/user" element={<LayOut toggleDarkMode={toggleDarkMode}/>}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="project-directory" element={<ProjectDirectory />} />
          <Route path="qr-code" element={<QRCode />} />
          <Route path="add-project" element={<AddProject />} />
          <Route path="project-report" element={<MenuReport />} />
          <Route path="upload-successfully" element={<UploadSuccessfully />} />
          <Route path="media-library" element={<MediaLibrary />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="ar-project-studio" element={<ArProjectStudio />} />
          <Route path="save-as-draft" element={<SaveAsDraft />} />
          <Route path="save-and-publish" element={<SaveAndPublish />} />
          <Route path="ask-to-generate-qr" element={<AskToGenerateQr />} />
          <Route path="qr-code-generation" element={<QrCodeGeneration />} />
          <Route path="published-qr-code" element={<PublishedQrCode />} />
          <Route path="new-project" element={<NewProject />} />
          <Route path="qr-code-list" element={<QRCodeList />} />
          <Route path="view-qr-code" element={<ViewQRCode />} />
          <Route path="profile" element={<Profile />} />
          <Route path="bulk-qr-code" element={<BulkQrCode />} />
          <Route path="select-qr-code" element={<SelectQrCode />} />
          <Route path="bulk-qr-successfully" element={<BulkQrSuccessfully />} />
          <Route path="payments" element={<Payments />} />
          <Route path="upload-file" element={<UploadFile />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path="qr-code-report" element={<QRCodeReport />} />
          <Route path="loader" element={<Loader />} />
          <Route path="delete-modal" element={<DeleteModal />} />
          <Route path="project-details" element={<ProjectDetails />} />
          <Route path="help-and-support" element={<HelpAndSupport />} />
          <Route path="published-and-qr-generated" element={<PublishedAndQrGenerated />} />
          <Route path="edit-project-details" element={<EditProjectDetails />} />
        </Route>
        </Route>
        {/* User Routes End */}
      </Routess>
    </BrowserRouter>
    </div>
  );
}
