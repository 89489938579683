import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import ReCAPTCHA from "react-google-recaptcha";

export default function Login() {
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let initialValues = {
    email: "",
    password: "",
    isLoginPage: true,
    isVerifyCaptcha: false,
    isSubmit: false,
  };

  const onCaptchaChange = (value, setFieldValue) => {
    if (value) {
      setFieldValue("isVerifyCaptcha", true);
    } else {
      setFieldValue("isVerifyCaptcha", false);
    }
  };

  const handleSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", values.email);
    urlencoded.append("password", values.password);
    dispatch(PageIndex.adminLogin(urlencoded)).then((res) => {
      if (res?.payload?.status == 200) {
        setTimeout(() => {
          navigate("/admin/dashboard");
        }, 1000);
      } else {
        setTimeout(()=> setFieldValue("isSubmit", false),3000) 
      }
    });
  };

  return (
    <>
      <Index.Box className="login-user-screen">
        <Index.Box className="auth-user-row">
          <Index.Box className="auth-row-col-left">
            <Index.Box className="dark-gray">
              <Index.Box className="auth-bg-content"></Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="auth-row-col-right">
            <Index.Box className="light-gray">
              <Index.Box className="auth-page-bg login-content">
                <Index.Box className="auth-bg-pd">
                  <Index.Box className="auth-title-content">
                    <Index.Typography
                      component="h6"
                      variant="h6"
                      className="signin-title"
                    >
                      Log In
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="signin-details-title"
                    >
                      Please enter your credentials to Log In!
                    </Index.Typography>
                  </Index.Box>
                  <PageIndex.Formik
                    initialValues={initialValues}
                    validationSchema={PageIndex.adminLoginValidationSchema(
                      initialValues
                    )}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }) => (
                      <PageIndex.Form>
                        <Index.Box className="auth-signin-details">
                          <Index.Box className="scroll-content-auth">
                            <Index.Box className="input-details-auth">
                              <Index.Box className="grid-row">
                                <Index.Box
                                  sx={{ width: 1 }}
                                  className="grid-main"
                                >
                                  <Index.Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                  >
                                    <Index.Box
                                      gridColumn={{
                                        xs: "span 12",
                                        sm: "span 12",
                                        md: "span 12",
                                        lg: "span 12",
                                        xl: "span 12",
                                      }}
                                      className="grid-column"
                                    >
                                      <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                          <Index.FormHelperText className="label-input-title">
                                            Email
                                            <span className="mandatory-asterisk">
                                              *
                                            </span>
                                          </Index.FormHelperText>
                                          <Index.TextField
                                            fullWidth
                                            id="fullWidth"
                                            className="form-control"
                                            placeholder="Email"
                                            name="email"
                                            value={values?.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          />
                                          {errors.email && touched.email && (
                                            <p className="error-text">
                                              {errors.email}
                                            </p>
                                          )}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>

                                    <Index.Box
                                      gridColumn={{
                                        xs: "span 12",
                                        sm: "span 12",
                                        md: "span 12",
                                        lg: "span 12",
                                        xl: "span 12",
                                      }}
                                      className="grid-column"
                                    >
                                      <Index.Box className="input-details-auth">
                                        <Index.Box className="input-box-auth">
                                          <Index.Box className="form-group">
                                            <Index.FormHelperText className="label-input-title">
                                              Password
                                              <span className="mandatory-asterisk">
                                                *
                                              </span>
                                            </Index.FormHelperText>
                                            <Index.Box className="input-details-main">
                                              <Index.Box className="input-box cm-search-box">
                                                <Index.Box className="form-group">
                                                  <Index.Box className="form-group pass_group_main">
                                                    <Index.OutlinedInput
                                                      className="form-control custom_form_control"
                                                      id="outlined-adornment-password"
                                                      placeholder="Enter your password"
                                                      type={
                                                        showPassword
                                                          ? "text"
                                                          : "password"
                                                      }
                                                      name="password"
                                                      value={values?.password}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      endAdornment={
                                                        <Index.InputAdornment
                                                          position="end"
                                                          className="pass_position"
                                                        >
                                                          <Index.IconButton
                                                            className="icon_end_icon"
                                                            aria-label="toggle password visibility"
                                                            onClick={
                                                              handleClickShowPassword
                                                            }
                                                            onMouseDown={
                                                              handleMouseDownPassword
                                                            }
                                                            edge="end"
                                                          >
                                                            {showPassword ? (
                                                              <Index.VisibilityOff />
                                                            ) : (
                                                              <Index.Visibility />
                                                            )}
                                                          </Index.IconButton>
                                                        </Index.InputAdornment>
                                                      }
                                                      label="password"
                                                    />
                                                    {errors.password &&
                                                      touched.password && (
                                                        <p className="error-text">
                                                          {errors.password}
                                                        </p>
                                                      )}
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="forgot-details-content">
                            <Index.Link
                              to="/admin/forgot-password"
                              className="forgot-title-deatils"
                            >
                              Forgot Password ?
                            </Index.Link>
                          </Index.Box>
                          <Index.Box className="captach-main  admin-captach-main login-captach-main">
                            <ReCAPTCHA
                              sitekey={
                                process.env.REACT_APP_RE_CAPTCHA_SITE_KEY
                              }
                              onChange={(e) => {
                                onCaptchaChange(e, setFieldValue);
                              }}
                              className="captach-checkbox"
                            />
                          </Index.Box>
                          {/* <Index.Box className="condition-content">
                            <Index.Typography className="condition-text">
                              {" "}
                              Don't have an account?{" "}
                              <span className="condition-span">
                                <Index.Link className="condition-span">
                                  Sign In
                                </Index.Link>
                              </span>
                            </Index.Typography>
                          </Index.Box> */}

                          <Index.Box className="auth-primary-btn">
                            <PageIndex.PrimaryButton
                              btnLabel="Log In"
                              type="submit"
                              disabled={
                                values?.isSubmit || !values?.isVerifyCaptcha
                              }
                              startIcon={
                                values?.isSubmit && (
                                  <Index.CircularProgress
                                    size={15}
                                    color="inherit"
                                  />
                                )
                              }
                              className={
                                !values?.isVerifyCaptcha
                                  ? "comman-primary-btn login-btn disabled-login-button"
                                  : "comman-primary-btn login-btn"
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </PageIndex.Form>
                    )}
                  </PageIndex.Formik>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
