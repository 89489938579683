import React from 'react'
import Index from '../../../Index'
import PageIndex from '../../../PageIndex'

export default function NewProject() {

  // for page navigation
  const navigate = PageIndex.useNavigate()


  // for save as draftmodal
  const [openSaveAsDrafModal, setOpenSaveAsDrafModal] = React.useState(false);
  const handleOpenSaveAsDrafModal = () => setOpenSaveAsDrafModal(true);
  const handleCloseSaveAsDrafModal = () => setOpenSaveAsDrafModal(false);


  return (
    <>
      <Index.Box className="dashboard-content new-project-content">
        <Index.Box className="user-page-title-box">
          <Index.Typography className='user-page-title' component='h2' variant='h2'>Project</Index.Typography>
        </Index.Box>
        <Index.Box className="new-project-section common-card">
          <Index.Box className="new-project-lable-flex">
            <Index.Box className="new-project-lable-box">
              <Index.Typography title="Add elements from published Project" className='new-project-lable-text'>Add elements from published Project</Index.Typography>
            </Index.Box>
            {/* <Index.Box className="new-project-lable-box">
              <Index.Typography className='new-project-lable-text'>Save</Index.Typography>
            </Index.Box> */}
            <Index.Box className="new-project-lable-box">
              <Index.Typography title="Create New" className='new-project-lable-text' onClick={() => {
                  navigate("/user/add-project")
            }}>Create New</Index.Typography>
            </Index.Box>
            <Index.Box className="new-project-lable-box" onClick={()=>navigate("/user/media-library")}>
              <Index.Typography className='new-project-lable-text' title="View Media Files" >View Media Files</Index.Typography>
            </Index.Box>
            <Index.Box className="new-project-lable-box">
              <Index.Typography className='new-project-lable-text' title="View import project">View import project</Index.Typography>
            </Index.Box>
            {/* <Index.Box className="new-project-lable-box">
              <Index.Typography className='new-project-lable-text'>Save and Publish</Index.Typography>
            </Index.Box> */}
          </Index.Box>
          <Index.Box className="project-box-flex">
            <Index.Box className="new-project-lable-flex new-project-category-flex">
              <Index.Box className="new-project-lable-box">
                <Index.Typography className='new-project-lable-text' title="Text">Text</Index.Typography>
              </Index.Box>
              <Index.Box className="new-project-lable-box">
                <Index.Typography className='new-project-lable-text' title="Video">Video</Index.Typography>
              </Index.Box>
              <Index.Box className="new-project-lable-box">
                <Index.Typography className='new-project-lable-text' title="3D AR File">3D AR File</Index.Typography>
              </Index.Box>
              <Index.Box className="new-project-lable-box">
                <Index.Typography className='new-project-lable-text' title="Image">Image</Index.Typography>
              </Index.Box>
              <Index.Box className="new-project-lable-box">
                <Index.Typography className='new-project-lable-text' title="Audio">Audio</Index.Typography>
              </Index.Box>
              <Index.Box className="new-project-lable-box">
                <Index.Typography className='new-project-lable-text' title="Frame">Frame</Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="new-project-box"></Index.Box>
          </Index.Box>
          <Index.Box className="user-btn-flex">
          <PageIndex.PrimaryButton className='create-user-btn comman-primary-btn' title="Next" btnLabel="Next" onClick={handleOpenSaveAsDrafModal} />
          <PageIndex.BorderButton className='create-user-btn border-btn' title="Back" btnLabel="Back" onClick={()=>navigate("/user/project-directory")} />
          </Index.Box>
        </Index.Box>
      </Index.Box >
      <PageIndex.SaveAsDraft openSaveAsDrafModal={openSaveAsDrafModal} handleCloseSaveAsDrafModal={handleCloseSaveAsDrafModal} handleOpenSaveAsDrafModal={handleOpenSaveAsDrafModal}/>

    </>
  )
}
