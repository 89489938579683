import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { styled } from "@mui/material/styles";

// for paymenttablerows table data

function paymenttablerowsCreateData(
  PaymentID,
  Mode,
  Amount,
  PayemntDate,
  SubscriptionPlan
) {
  return { PaymentID, Mode, Amount, PayemntDate, SubscriptionPlan };
}

export default function Payments() {
  // data gride
  function createData(Sno, paymentID, mode, amount, date, subscription_plan) {
    return { Sno, paymentID, mode, amount, date, subscription_plan };
  }

  const columns = [
    { field: "Sno", headerName: "Sr.No." },
    { field: "paymentID", headerName: "Payment ID"},
    { field: "mode", headerName: "Mode"},
    { field: "amount", headerName: "Amount"},
    {
      field: "date",
      headerName: "Date",
    },
    {
      field: "subscription_plan",
      headerName: "Subscription Plan",
    },
  ];

  const rows = [
    {
      id: 1,
      Sno: 1,
      paymentID: 11201,
      mode: "PayPal",
      amount: "1220",
      date: "12-03-2023",
      subscription_plan: "plan 1",
    },
    {
      id: 2,
      Sno: 2,
      paymentID: 11202,
      mode: "PayPal",
      amount: "1220",
      date: "12-03-2023",
      subscription_plan: "plan 1",
    },
    {
      id: 3,
      Sno: 3,
      paymentID: 11203,
      mode: "PayPal",
      amount: "1220",
      date: "12-03-2023",
      subscription_plan: "plan 1",
    },
    {
      id: 4,
      Sno: 4,
      paymentID: 11204,
      mode: "PayPal",
      amount: "1220",
      date: "12-03-2023",
      subscription_plan: "plan 1",
    },
    {
      id: 5,
      Sno: 5,
      paymentID: 11205,
      mode: "PayPal",
      amount: "1220",
      date: "12-03-2023",
      subscription_plan: "plan 1",
    },
  ];

  // for paymenttablerows table data

  const paymenttablerows = [
    paymenttablerowsCreateData(
      "11012",
      "PayPal",
      "1220",
      "12-03-2023",
      "Plan 1"
    ),
    paymenttablerowsCreateData(
      "11012",
      "PayPal",
      "1220",
      "12-03-2023",
      "Plan 1"
    ),
    paymenttablerowsCreateData(
      "11012",
      "PayPal",
      "1220",
      "12-03-2023",
      "Plan 1"
    ),
    paymenttablerowsCreateData(
      "11012",
      "PayPal",
      "1220",
      "12-03-2023",
      "Plan 1"
    ),
    paymenttablerowsCreateData(
      "11012",
      "PayPal",
      "1220",
      "12-03-2023",
      "Plan 1"
    ),
    paymenttablerowsCreateData(
      "11012",
      "PayPal",
      "1220",
      "12-03-2023",
      "Plan 1"
    ),
    paymenttablerowsCreateData(
      "11012",
      "PayPal",
      "1220",
      "12-03-2023",
      "Plan 1"
    ),
  ];

  // for page navigation
  const navigate = PageIndex.useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState(7);
  const [openSubscription, setOpenSubscription] = React.useState(false);
  const handleOpenSubscription = () => setOpenSubscription(true);
  const handleCloseSubscription = () => setOpenSubscription(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Index.Box className="dashboard-content project-report-content user-payment-history-wrape">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              Payment History
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="userlist-inner-btn-flex">
              <PageIndex.PrimaryButton
                className="adduser-btn comman-primary-btn"
                title="Upgrade"
                btnLabel="Upgrade"
                imgSrc={PageIndex.Svg.exporticon}
                onClick={() => {
                  handleOpenSubscription();
                }}
              />
            </Index.Box>
            <Index.Box className="user-search-flex">
              <Index.Box className="user-page-sub-title-box">
                {/* <Index.Typography className='user-page-sub-title' component='h2' variant='h2'>Non Published</Index.Typography> */}
              </Index.Box>
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img
                    src={PageIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="menu-report-section common-card">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="page-table-main user-payment-table">
                  <Index.Box className="data-table-main">
                    <Index.DataGrid
                      class=""
                      rows={rows}
                      // columns={columns}
                      columns={columns.map((col) => ({
                        ...col,
                        renderHeader: (params) => <PageIndex.CustomHeader {...params} column={col} />,
                      }))}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 15 },
                        },
                      }}
                      pageSizeOptions={[15, 20, 25]}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {/* <Index.Box className="pagination-main">
            <PageIndex.Pagination
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              count={count}
            />
          </Index.Box> */}
        </Index.Box>
      </Index.Box>

      <PageIndex.Subscription
        openSubscription={openSubscription}
        handleCloseSubscription={handleCloseSubscription}
      />
    </>
  );
}
