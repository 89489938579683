import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { useSelector } from "react-redux";

export default function AdminChangePassword() {
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  // const { token } = useSelector((state) => state.admin.adminData);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  let initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    isSubmit: false,
  };
  const handleClickshowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickshowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickshowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCancelForm = () => {
    navigate("/admin/dashboard");
  };
  const handleSubmit = (values,{setFieldValue}) => {
    setFieldValue("isSubmit",true)
    const urlencoded = new URLSearchParams();
    urlencoded.append("oldPassword", values.oldPassword);
    urlencoded.append("newPassword", values.newPassword);
  
    dispatch(PageIndex.adminChangePassword(urlencoded)).then((res) => {
      if (res?.payload?.status == 200) {
        dispatch(PageIndex.adminLogout())
        setTimeout(() => {
          navigate("/admin/login");
        }, 1000);
      }
      else{
        setTimeout(()=> setFieldValue("isSubmit",false),4000)
       
      }
    });
  };
  return (
    <>
      <Index.Box className="dashboard-content admin-change-password-list">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              Change Password
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <PageIndex.Formik
          initialValues={initialValues}
          validationSchema={PageIndex.adminChangePasswordValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleBlur, handleChange }) => (
            <PageIndex.Form >
              <Index.Box className="create-new-project-card admin-change-password-create-new-project-card common-card">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-change-password-flex">
                        <Index.Box className="admin-change-password-flex-left">
                          <Index.FormHelperText className="label-input-title">
                            Old Password:  <span className='mandatory-asterisk'>*</span>
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="admin-change-password-flex-right">
                          <Index.Box className="form-group">
                            <Index.Box className="form-group pass_group_main">
                              <Index.OutlinedInput
                                className="form-control custom_form_control"
                                id="outlined-adornment-password"
                                placeholder="Enter old password"
                                type={showOldPassword ? "text" : "password"}
                                name="oldPassword"
                                value={values?.oldPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                endAdornment={
                                  <Index.InputAdornment
                                    position="end"
                                    className="pass_position"
                                  >
                                    <Index.IconButton
                                      className="icon_end_icon"
                                      aria-label="toggle password visibility"
                                      onClick={handleClickshowOldPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showOldPassword ? (
                                        <Index.VisibilityOff />
                                      ) : (
                                        <Index.Visibility />
                                      )}
                                    </Index.IconButton>
                                  </Index.InputAdornment>
                                }
                                label="Password"
                              />
                              {errors.oldPassword &&
                                touched.oldPassword && (
                                  <p className="error-text">
                                    {errors.oldPassword}
                                  </p>
                                )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-change-password-flex">
                        <Index.Box className="admin-change-password-flex-left">
                          <Index.FormHelperText className="label-input-title">
                            New Password: <span className='mandatory-asterisk'>*</span>
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="admin-change-password-flex-right">
                          <Index.Box className="form-group">
                            <Index.Box className="form-group pass_group_main">
                              <Index.OutlinedInput
                                className="form-control custom_form_control"
                                id="outlined-adornment-password"
                                placeholder="Enter new password"
                                type={showNewPassword ? "text" : "password"}
                                name="newPassword"
                                value={values?.newPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                endAdornment={
                                  <Index.InputAdornment
                                    position="end"
                                    className="pass_position"
                                  >
                                    <Index.IconButton
                                      className="icon_end_icon"
                                      aria-label="toggle password visibility"
                                      onClick={handleClickshowNewPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showNewPassword ? (
                                        <Index.VisibilityOff />
                                      ) : (
                                        <Index.Visibility />
                                      )}
                                    </Index.IconButton>
                                  </Index.InputAdornment>
                                }
                                label="Password"
                              />
                               {errors.newPassword &&
                                touched.newPassword && (
                                  <p className="error-text">
                                    {errors.newPassword}
                                  </p>
                                )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-change-password-flex">
                        <Index.Box className="admin-change-password-flex-left">
                          <Index.FormHelperText className="label-input-title">
                            Confirm Password: <span className='mandatory-asterisk'>*</span>
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="admin-change-password-flex-right">
                          <Index.Box className="form-group">
                            <Index.Box className="form-group pass_group_main">
                              <Index.OutlinedInput
                                className="form-control custom_form_control"
                                id="outlined-adornment-password"
                                placeholder="Enter confirm password"
                                type={showConfirmPassword ? "text" : "password"}
                                name="confirmPassword"
                                value={values?.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                endAdornment={
                                  <Index.InputAdornment
                                    position="end"
                                    className="pass_position"
                                  >
                                    <Index.IconButton
                                      className="icon_end_icon"
                                      aria-label="toggle password visibility"
                                      onClick={handleClickshowConfirmPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showConfirmPassword ? (
                                        <Index.VisibilityOff />
                                      ) : (
                                        <Index.Visibility />
                                      )}
                                    </Index.IconButton>
                                  </Index.InputAdornment>
                                }
                                label="Password"
                              />
                              {errors.confirmPassword &&
                                touched.confirmPassword && (
                                  <p className="error-text">
                                    {errors.confirmPassword}
                                  </p>
                                )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="user-btn-flex">
                       
                        <PageIndex.PrimaryButton
                          // className="create-user-btn comman-primary-btn"
                          btnLabel="Save"
                          title="Save"
                          type="submit"
                          disabled={values?.isSubmit}
                              startIcon={values?.isSubmit && <Index.CircularProgress size={15} color="inherit" /> }
                              className={
                                values?.isSubmit
                                  ? "create-user-btn comman-primary-btn login-btn disabled-login-button"
                                  : "create-user-btn comman-primary-btn login-btn"
                              }
                          imgSrc={PageIndex.Svg.changepassword}
                        />
                         <PageIndex.BorderButton
                          className="cancel-user-btn border-btn"
                          btnLabel="Cancel"
                          title="Cancel"
                          onClick={handleCancelForm}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </PageIndex.Form>
          )}
        </PageIndex.Formik>
      </Index.Box>
    </>
  );
}
