import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default function AddEditEmailTemplate() {
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  const editData = location?.state;

  const initialValues = {
    title: editData ? editData?.title : "",
    subject: editData ? editData?.subject : "",
    // content: editData ? editData?.content : "",
  };

  console.log(editData, 899);
  const handleCancelForm = () => {
    navigate("/admin/email-template");
  };
  const handleSubmit = (value) => {
    navigate("/admin/email-template", { state: { updatedData: value } });
  };
  return (
    <>
      <Index.Box className="dashboard-content email-templete-add-Edit-page">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              {editData ? "Edit Email Template" : "Add Email Template"}
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <PageIndex.Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <PageIndex.Form>
              <Index.Box className="create-new-project-card email-templete-create-new-project-card common-card">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    {/* {!editData && (
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box-auth">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Title"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              )} */}

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder="Title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder="Subject"
                            name="subject"
                            value={values.subject}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth">
                        <Index.Box className="form-group">
                          {/* <Index.TextareaAutosize
                      aria-label="minimum height"
                      minRows={2}
                      placeholder="Body of the email"
                      className="form-control-textarea"
                    /> */}
                          <CKEditor
                            editor={ClassicEditor}
                            // data={values.content}
                            // onChange={(event, editor) => {
                            //   const content = editor.getData();
                            //   setFieldValue("content", content);
                            // }}
                            // onBlur={handleBlur}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="user-btn-flex">
                        <PageIndex.PrimaryButton
                          className="create-user-btn comman-primary-btn"
                          type="submit"
                          title="Save"
                          btnLabel="Save"
                          imgSrc={PageIndex.Svg.plus}
                        />
                        <PageIndex.BorderButton
                          className="cancel-user-btn border-btn"
                          title="Cancel"
                          btnLabel="Cancel"
                          onClick={handleCancelForm}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </PageIndex.Form>
          )}
        </PageIndex.Formik>
      </Index.Box>
    </>
  );
}
