import React, { useEffect, useState } from "react";
import Index from "../../Index";
import PageIndex from "../../PageIndex";

export default function AdminSidebar(props) {
  const location = PageIndex.useLocation();
  // for submenu
  const { adminData } = PageIndex.useSelector((state) => state.admin);
  const [open, setOpen] = React.useState(true);
  const [screen, setScreen] = useState("");
  const handleClickSubmenu = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (window.screen.width < 768) {
      setScreen("Mobile");
    } else {
      setScreen("Desktop");
    }
  }, [window.screen.availHeight]);

  // for custom switch design

  const IOSSwitch = Index.styled((props) => (
    <Index.Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 34,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 3,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#3e54a0",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 14,
      height: 14,
      boxShadow: "none",
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#5a5959" : "#3e54a0",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  // for submenu

  const [openQrCode, setOpenQrCode] = React.useState(false);
  const handleClickQrCode = () => {
    setOpenQrCode(!openQrCode);
  };

  const [openReport, setOpenReport] = React.useState(false);
  const handleClickReport = () => {
    setOpenReport(!openReport);
  };

  return (
    <>
      <Index.Box
        className={`user-sidebar-main ${
          (screen == "Mobile" ? !props.open : props.open) ? "active" : ""
        }`}
      >
        <Index.Box className="user-sidebar-inner-main">
          <Index.Box className="user-sidebar-logo-main">
            <Index.Box className="user-sibar-logo-flex">
              <Index.Link className="" to="/admin/dashboard">
                <img
                  src={PageIndex.Png.logo}
                  className="user-sidebar-logo"
                  alt="dashboard bell icon"
                ></img>
              </Index.Link>
              <Index.Typography className="user-sidebar-logo-text">
                {adminData.company ? adminData.company : "Code Scooter"}
              </Index.Typography>
            </Index.Box>
            <Index.Button
              onClick={() => {
                props.setOpen(!props.open);
                document.body.classList[props.open ? "add" : "remove"](
                  "body-overflow"
                );
              }}
              className="sidebar-close-btn"
            >
              <img
                src={PageIndex.Svg.closeicon}
                className="close-icon"
                alt="logo"
              />
            </Index.Button>
          </Index.Box>

          <Index.Box className="user-sidebar-list-main">
            <Index.List className="user-sidebar-list">
              <Index.ListItem className="user-sidebar-listitem">
                  <Index.Link
                    to="/admin/dashboard"
                    title="Dashboard"
                    className={
                      location?.pathname.includes("/admin/dashboard")
                        ? "user-sidebar-link active"
                        : "user-sidebar-link"
                    }
                    onClick={() => {
                      window.innerWidth <= 1023 && props.setOpen(!props.open);
                    }}
                  >
                    <img
                      src={PageIndex.Svg.dashboard}
                      alt="sidebar icon"
                      className="user-sidebar-icons"
                    />
                    <span className="sidebar-link-span">Dashboard</span>
                  </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="user-sidebar-listitem">
                  <Index.Link
                    to="/admin/user-management"
                    title="User Management"
                    className={
                      location?.pathname.includes("/admin/user-management")
                        ? "user-sidebar-link active"
                        : "user-sidebar-link"
                    }
                    onClick={() => {
                      window.innerWidth <= 1023 && props.setOpen(!props.open);
                    }}
                  >
                    <img
                      src={PageIndex.Svg.usermanagment}
                      alt="sidebar icon"
                      className="user-sidebar-icons"
                    />
                    <span className="sidebar-link-span">User Management</span>
                  </Index.Link>
              </Index.ListItem>

              {/* <Index.ListItem className='user-sidebar-listitem user-submenu-listitem-main'>
                <Index.Box className='user-submenu-link'>
                  <Index.Box onClick={handleClickQrCode} className="user-submenu-link-box user-sidebar-link">
                    QR Code
                    {openQrCode ? <Index.ExpandLess className='expandless-icon' /> : <Index.ExpandMore className='expandmore-icon' />}
                  </Index.Box>
                  <Index.Box className='submenu-main'>
                    <Index.Collapse in={openQrCode} timeout="auto" unmountOnExit className='submenu-collapse'>
                      <Index.List component="div" disablePadding className='user-submenu-submenulist'>
                        <Index.ListItem className='user-submenu-listitem'>
                          <Index.Link className='user-submenu-link user-sidebar-link'>
                            QR Code List
                          </Index.Link>
                        </Index.ListItem>
                        <Index.ListItem className='user-submenu-listitem'>
                          <Index.Link className='user-submenu-link user-sidebar-link' >
                            Bulk QR Code
                          </Index.Link>
                        </Index.ListItem>
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem> */}

              <Index.ListItem className="user-sidebar-listitem">
                  <Index.Link
                    to="/admin/email-template"
                    title="Email Template Management"
                    className={
                      location?.pathname.includes("/admin/email-template")
                        ? "user-sidebar-link active"
                        : "user-sidebar-link"
                    }
                    onClick={() => {
                      window.innerWidth <= 1023 && props.setOpen(!props.open);
                    }}
                  >
                    <img
                      src={PageIndex.Svg.emailadmin}
                      alt="sidebar icon"
                      className="user-sidebar-icons"
                    />
                    <span className="sidebar-link-span">
                      Email Template Management
                    </span>
                  </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="user-sidebar-listitem">
                  <Index.Link
                    to="/admin/payment"
                     title="Payments"
                    className={
                      location?.pathname.includes("/admin/payment")
                        ? "user-sidebar-link active"
                        : "user-sidebar-link"
                    }
                    onClick={() => {
                      window.innerWidth <= 1023 && props.setOpen(!props.open);
                    }}
                  >
                    <img
                      src={PageIndex.Svg.paymentadmin}
                      alt="sidebar icon"
                      className="user-sidebar-icons"
                    />
                    <span className="sidebar-link-span">Payments</span>
                  </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="user-sidebar-listitem">
                  <Index.Link
                    to="/admin/subscription-plan"
                    title="Subscription Plans"
                    className={
                      location?.pathname.includes("/admin/subscription-plan")
                        ? "user-sidebar-link active"
                        : "user-sidebar-link"
                    }
                    onClick={() => {
                      window.innerWidth <= 1023 && props.setOpen(!props.open);
                    }}
                  >
                    <img
                      src={PageIndex.Svg.subscriptionadmin}
                      alt="sidebar icon"
                      className="user-sidebar-icons"
                    />
                    <span className="sidebar-link-span">
                      Subscription Plans
                    </span>
                  </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="user-sidebar-listitem">
                  <Index.Link
                    to="/admin/analytic"
                    title="Analytics"
                    className={
                      location?.pathname.includes("/admin/analytic")
                        ? "user-sidebar-link active"
                        : "user-sidebar-link"
                    }
                    onClick={() => {
                      window.innerWidth <= 1023 && props.setOpen(!props.open);
                    }}
                  >
                    <img
                      src={PageIndex.Svg.analyticsadmin}
                      alt="sidebar icon"
                      className="user-sidebar-icons"
                    />
                    <span className="sidebar-link-span">Analytics</span>
                  </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="user-sidebar-listitem user-submenu-listitem-main">
                <Index.Box className="user-submenu-link">
                    <Index.Box
                      onClick={handleClickReport}
                      className="user-submenu-link-box user-sidebar-link"
                      title="Reports"
                    >
                      <img
                        src={PageIndex.Png.reports}
                        alt="sidebar icon"
                        className="user-sidebar-icons"
                      />
                      <span className="sidebar-link-span">Reports</span>
                      {openReport ? (
                        <Index.ExpandLess className="expandless-icon" />
                      ) : (
                        <Index.ExpandMore className="expandmore-icon" />
                      )}
                    </Index.Box>
                  <Index.Box className="submenu-main">
                    <Index.Collapse
                      in={openReport}
                      timeout="auto"
                      unmountOnExit
                      className="submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="user-submenu-submenulist"
                      >
                        <Index.ListItem className="user-submenu-listitem">
                            <Index.Link
                               title=" Project Reports"
                              className={
                                location?.pathname.includes(
                                  "/admin/project-report"
                                )
                                  ? "user-sidebar-link user-submenu-link active"
                                  : "user-sidebar-link user-submenu-link"
                              }
                              to="/admin/project-report"
                              onClick={() => {
                                window.innerWidth <= 1023 &&
                                  props.setOpen(!props.open);
                              }}
                            >
                              <img
                                src={PageIndex.Png.reports}
                                alt="sidebar icon"
                                className="user-sidebar-icons"
                              />
                              <span className="sidebar-link-span">
                                Project Reports
                              </span>
                            </Index.Link>
                        </Index.ListItem>
                        <Index.ListItem className="user-submenu-listitem">
                            <Index.Link
                               title="QR Code Reports"
                              className={
                                location?.pathname.includes(
                                  "/admin/qr-code-report"
                                )
                                  ? "user-sidebar-link user-submenu-link active"
                                  : "user-sidebar-link user-submenu-link"
                              }
                              to="/admin/qr-code-report"
                              onClick={() => {
                                window.innerWidth <= 1023 &&
                                  props.setOpen(!props.open);
                              }}
                            >
                              <img
                                src={PageIndex.Png.reports}
                                alt="sidebar icon"
                                className="user-sidebar-icons"
                              />
                              <span className="sidebar-link-span">
                                QR Code Reports
                              </span>
                            </Index.Link>
                        </Index.ListItem>
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
              {/* <Index.ListItem className="user-sidebar-listitem">
                <Index.Link
                  to="/admin/qr-code-report"
                  className={
                    location?.pathname.includes("/admin/qr-code-report")
                      ? "user-sidebar-link active"
                      : "user-sidebar-link"
                  }
                >
                  QR Code Report
                </Index.Link>
              </Index.ListItem> */}
            </Index.List>
          </Index.Box>

          {/* <Index.Box className="switch-main user-sidebar-switch-main">
            <Index.FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
              label="Dark Mode"
              className="switch-lable"
            />
          </Index.Box> */}
        </Index.Box>
      </Index.Box>
    </>
  );
}
