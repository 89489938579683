import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import PageIndex from "../container/PageIndex";
import DataService from "../config/DataService";
import { userLogout } from "../redux/features/slices/user/UserSlice";
import { fetchUserMasterList } from "../redux/features/slices/admin/AdminService";

export default function UserPrivateRoute() {
  const dispatch = PageIndex.useDispatch();
  const location = PageIndex.useLocation();
  const navigate = PageIndex.useNavigate();
  const { userToken } = PageIndex.useSelector((state) => state.user);
  const [userActivity, setUserActivity] = useState(true);
  const [userLogoutNewTab, setUserLogoutNewTab] = useState(true);

  const isLogin = JSON.parse(localStorage.getItem("userLogin"));
  console.log(isLogin, "isLoginisLogin");

  const isAuthenticate = (token) => {
    if (!token) return false;
    PageIndex.DataService.defaults.headers.common.auth = token;
    return true;
  };

  // change for api integrate start
  useEffect(() => {
    dispatch(fetchUserMasterList());
  }, [location.pathname]);
  // change for api integrate end

  //Static Admin Logout
  useEffect(() => {
    if (!isLogin) {
      dispatch(PageIndex.userLogout());
      navigate("/");
    }
  }, [location.pathname, isLogin]);

  useEffect(() => {
    let timeoutId;

    const handleTimeout = () => {
      dispatch(PageIndex.userLogout());
      navigate("/");
    };

    const resetTimeout = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(handleTimeout, 30 * 60 * 1000);
    };

    resetTimeout();

    const activityListener = () => {
      setUserActivity(true);
      resetTimeout();
    };

    document.addEventListener("mousemove", activityListener);
    document.addEventListener("keydown", activityListener);

    return () => {
      document.removeEventListener("mousemove", activityListener);
      document.removeEventListener("keydown", activityListener);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [navigate]);

  DataService.interceptors.response.use(
    (response) => {
      const status = response.status;
      console.log(status, "statusstatus");

      return response;
    },
    (error) => {
      const status = error.response.data.status;
      if (status == 401) {
        navigate("/");
        // PageIndex.toast.success("You have been logged out");
        dispatch(userLogout());
      }
      return Promise.reject(error);
    }
  );

  // useEffect(() => {
  //   const activityListener = () => {
  //     setUserLogoutNewTab(false);
  //     dispatch(PageIndex.userLogout());
  //   };

  //   // Add event listener for visibility change
  //   document.addEventListener("visibilitychange", activityListener);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener("visibilitychange", activityListener);
  //   };
  // }, []);

  // change for api integrate start
  useEffect(() => {
    dispatch(fetchUserMasterList());
  }, [location.pathname]);
  // change for api integrate end

  return isAuthenticate(userToken) && userActivity && isLogin ? (
    <Outlet />
  ) : (
    <PageIndex.Navigate to="/" replace={true} />
  );
}
