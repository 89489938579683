import React from 'react'
import Index from '../../../Index'
import PageIndex from '../../../PageIndex'
import { styled } from '@mui/material/styles';

// start checkbox

// for custom checkbox design


const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    border: "1px solid #114A65",
    width: 12,
    height: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : 'transparent',
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: 'transparent',
    '&:before': {
        display: 'block',
        width: 12,
        height: 12,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: 'transparent',
    },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
    return (
        <Index.Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}

// end checkbox


// for published table data

function PublishedCreateData(Checkbox, ProjectTitle, Tags, CreatedDate, PublishedDate, Actions) {
    return { Checkbox, ProjectTitle, Tags, CreatedDate, PublishedDate, Actions };
}




export default function BulkQrCode() {

    // for open handleChangedropdown 

    const [age, setAge] = React.useState('');

    const handleChangedropdown = (event) => {
        setAge(event.target.value);
    };


    // for ViewQrCode modal
    const [openSelectQrCode, setOpenSelectQrCode] = React.useState(false);
    const handleOpenSelectQrCode = () => setOpenSelectQrCode(true);
    const handleCloseSelectQrCode = () => setOpenSelectQrCode(false);



    // for page navigation
    const navigate = PageIndex.useNavigate()

    // for published table data
    const publishedtablerows = [
        PublishedCreateData('QR1', 'T1', <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />, '12-02-2022', '12-02-2013', <Index.Box className="table-icon-flex"><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.view} className="table-view-icons" />
        </Index.Button></Index.Box>),


        PublishedCreateData('QR1', 'T1', <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />, '12-02-2022', '12-02-2013', <Index.Box className="table-icon-flex"><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.view} className="table-view-icons" />
        </Index.Button> </Index.Box>),

        PublishedCreateData('QR1', 'T1', <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />, '12-02-2022', '12-02-2013', <Index.Box className="table-icon-flex"><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.view} className="table-view-icons" />
        </Index.Button> </Index.Box>),

        PublishedCreateData('QR1', 'T1', <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />, '12-02-2022', '12-02-2013', <Index.Box className="table-icon-flex"><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.view} className="table-view-icons" />
        </Index.Button> </Index.Box>),

        PublishedCreateData('QR1', 'T1', <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />, '12-02-2022', '12-02-2013', <Index.Box className="table-icon-flex"><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.view} className="table-view-icons" />
        </Index.Button> </Index.Box>),
    ];

    return (
        <>
            <Index.Box className="dashboard-content project-report-content">
                <Index.Box className="user-list-flex">
                    <Index.Box className="user-page-title-box">
                        <Index.Typography className='user-page-title' component='h2' variant='h2'>Bulk Qr Codes</Index.Typography>
                    </Index.Box>
                    <Index.Box className="userlist-btn-flex">

                        <Index.Box className="userlist-inner-btn-flex">
                            <PageIndex.PrimaryButton className='adduser-btn comman-primary-btn' btnLabel="Generate Bulk Codes" onClick={() => {
                                handleOpenSelectQrCode()
                            }} />
                        </Index.Box>
                    </Index.Box>
                </Index.Box>

                <Index.Box className="menu-report-section common-card">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box display="grid" className="display-row" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}>
                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                                <Index.Box className="user-search-flex">
                                    <Index.Box className="user-page-sub-title-box">
                                        <Index.Typography className='user-page-sub-title' component='h2' variant='h2'>Non Published</Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="user-search-box">
                                        <Index.Box className="form-group">
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control"
                                                placeholder="Search"
                                            />
                                            <img
                                                src={PageIndex.Svg.search}
                                                className="search-grey-img" alt='search grey img'
                                            ></img>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="page-table-main bulk-non-published-projects-table">
                                    <Index.TableContainer component={Index.Paper} className='table-container'>
                                        <Index.Table aria-label="simple table" className='table'>
                                            <Index.TableHead className='table-head'>
                                                <Index.TableRow className='table-row'>
                                                    <Index.TableCell component='th' variant='th' className='table-th'><Index.Box className="table-check table-check-head"> <BpCheckbox /></Index.Box></Index.TableCell>
                                                    <Index.TableCell component='th' variant='th' className='table-th'>ProjectTitle</Index.TableCell>
                                                    <Index.TableCell component='th' variant='th' className='table-th'>Tags</Index.TableCell>
                                                    <Index.TableCell component='th' variant='th' className='table-th'>Created Date</Index.TableCell>
                                                    <Index.TableCell component='th' variant='th' className='table-th'>Published Date</Index.TableCell>
                                                    <Index.TableCell component='th' variant='th' className='table-th'>Actions</Index.TableCell>
                                                </Index.TableRow>
                                            </Index.TableHead>
                                            <Index.TableBody className='table-body'>
                                                {publishedtablerows.map((publishedtablerows) => (
                                                    <Index.TableRow
                                                        key={publishedtablerows.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <Index.TableCell component='td' variant='td' scope="row" className='table-td'>
                                                            <Index.Box className="table-check"> <BpCheckbox /></Index.Box>
                                                        </Index.TableCell>
                                                        <Index.TableCell component='td' variant='td' scope="row" className='table-td'>
                                                            {publishedtablerows.ProjectTitle}
                                                        </Index.TableCell>
                                                        <Index.TableCell component='td' variant='td' className='table-td'>{publishedtablerows.Tags}</Index.TableCell>
                                                        <Index.TableCell component='td' variant='td' className='table-td'>{publishedtablerows.CreatedDate}</Index.TableCell>
                                                        <Index.TableCell component='td' variant='td' className='table-td'>{publishedtablerows.PublishedDate}</Index.TableCell>
                                                        <Index.TableCell component='td' variant='td' className='table-td'>{publishedtablerows.Actions}</Index.TableCell>
                                                    </Index.TableRow>
                                                ))}
                                            </Index.TableBody>
                                        </Index.Table>
                                    </Index.TableContainer>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="pagination-main">
                        <Index.Pagination count={3} variant="outlined" shape="rounded" className='pagination' />
                    </Index.Box>
                </Index.Box>
            </Index.Box>

            <PageIndex.SelectQrCode openSelectQrCode={openSelectQrCode} handleCloseSelectQrCode={handleCloseSelectQrCode} />
        </>
    )
}
