import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

export default function ResetPassword() {
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const location = PageIndex.useLocation();
  const userId = location?.state;
  console.log(userId, "resId");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickshowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickshowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  let initialValues = {
    Password: "",
    confirmPassword: "",
    isSubmit: false,
  };

  const handleSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("userId", userId);
    urlencoded.append("password", values.Password);
    dispatch(PageIndex.userResetPassword(urlencoded)).then((res) => {
      if (res?.payload?.status == 200) {
        setTimeout(() => {
          navigate("/");
        }, 1000);
      } else {
        setTimeout(()=> setFieldValue("isSubmit", false),3000) 
      }
    });
  };
  // adminResetPasswordValidationSchema
  return (
    <>
      <Index.Box className="login-user-screen">
        <Index.Box className="auth-user-row">
          <Index.Box className="auth-row-col-left">
            <Index.Box className="dark-gray">
              <Index.Box className="auth-bg-content"></Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="auth-row-col-right">
            <Index.Box className="light-gray">
              <Index.Box className="auth-page-bg login-content">
                <Index.Box className="auth-bg-pd">
                  <Index.Box className="auth-title-content">
                    <Index.Typography
                      component="h6"
                      variant="h6"
                      className="signin-title"
                    >
                      Reset Password
                    </Index.Typography>
                  </Index.Box>
                  <PageIndex.Formik
                    initialValues={initialValues}
                    validationSchema={
                      PageIndex.adminResetPasswordValidationSchema
                    }
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      setFieldValue
                    }) => (
                      <PageIndex.Form>
                        <Index.Box className="auth-signin-details">
                          <Index.Box className="scroll-content-auth">
                            <Index.Box className="input-details-auth">
                              <Index.Box className="grid-row">
                                <Index.Box
                                  sx={{ width: 1 }}
                                  className="grid-main"
                                >
                                  <Index.Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                  >
                                    <Index.Box
                                      gridColumn={{
                                        xs: "span 12",
                                        sm: "span 12",
                                        md: "span 12",
                                        lg: "span 12",
                                        xl: "span 12",
                                      }}
                                      className="grid-column"
                                    >
                                      <Index.Box className="input-details-auth">
                                        <Index.Box className="input-box-auth">
                                          <Index.Box className="form-group">
                                            <Index.FormHelperText className="label-input-title">
                                              New Password{" "}
                                              <span className="mandatory-asterisk">
                                                *
                                              </span>
                                            </Index.FormHelperText>
                                            <Index.Box className="input-details-main">
                                              <Index.Box className="input-box cm-search-box">
                                                <Index.Box className="form-group">
                                                  <Index.Box className="form-group pass_group_main">
                                                    <Index.OutlinedInput
                                                      className="form-control custom_form_control"
                                                      id="outlined-adornment-password"
                                                      placeholder="Enter new password"
                                                      type={
                                                        showNewPassword
                                                          ? "text"
                                                          : "password"
                                                      }
                                                      name="Password"
                                                      value={values?.Password}
                                                      onChange={(e) => {
                                              const newValue =
                                                e.target.value.replace(
                                                  /\s/g,
                                                  ""
                                                );
                                              setFieldValue("Password", newValue);
                                            }}
                                                      onBlur={handleBlur}
                                                      endAdornment={
                                                        <Index.InputAdornment
                                                          position="end"
                                                          className="pass_position"
                                                        >
                                                          <Index.IconButton
                                                            className="icon_end_icon"
                                                            aria-label="toggle password visibility"
                                                            onClick={
                                                              handleClickshowNewPassword
                                                            }
                                                            onMouseDown={
                                                              handleMouseDownPassword
                                                            }
                                                            edge="end"
                                                          >
                                                            {showNewPassword ? (
                                                              <Index.VisibilityOff />
                                                            ) : (
                                                              <Index.Visibility />
                                                            )}
                                                          </Index.IconButton>
                                                        </Index.InputAdornment>
                                                      }
                                                      label="Password"
                                                    />
                                                    {errors.Password &&
                                                      touched.Password && (
                                                        <p className="error-text">
                                                          {errors.Password}
                                                        </p>
                                                      )}
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>

                                    <Index.Box
                                      gridColumn={{
                                        xs: "span 12",
                                        sm: "span 12",
                                        md: "span 12",
                                        lg: "span 12",
                                        xl: "span 12",
                                      }}
                                      className="grid-column"
                                    >
                                      <Index.Box className="input-details-auth">
                                        <Index.Box className="input-box-auth">
                                          <Index.Box className="form-group">
                                            <Index.FormHelperText className="label-input-title">
                                              Confirm Password
                                              <span className="mandatory-asterisk">
                                                *
                                              </span>
                                            </Index.FormHelperText>
                                            <Index.Box className="input-details-main">
                                              <Index.Box className="input-box cm-search-box">
                                                <Index.Box className="form-group">
                                                  <Index.Box className="form-group pass_group_main">
                                                    <Index.OutlinedInput
                                                      className="form-control custom_form_control"
                                                      id="outlined-adornment-password"
                                                      placeholder="Enter confirm password"
                                                      type={
                                                        showConfirmPassword
                                                          ? "text"
                                                          : "password"
                                                      }
                                                      name="confirmPassword"
                                                      value={
                                                        values?.confirmPassword
                                                      }
                                                      onChange={(e) => {
                                              const newValue =
                                                e.target.value.replace(
                                                  /\s/g,
                                                  ""
                                                );
                                              setFieldValue("confirmPassword", newValue);
                                            }}
                                                      onBlur={handleBlur}
                                                      endAdornment={
                                                        <Index.InputAdornment
                                                          position="end"
                                                          className="pass_position"
                                                        >
                                                          <Index.IconButton
                                                            className="icon_end_icon"
                                                            aria-label="toggle password visibility"
                                                            onClick={
                                                              handleClickshowConfirmPassword
                                                            }
                                                            onMouseDown={
                                                              handleMouseDownPassword
                                                            }
                                                            edge="end"
                                                          >
                                                            {showConfirmPassword ? (
                                                              <Index.VisibilityOff />
                                                            ) : (
                                                              <Index.Visibility />
                                                            )}
                                                          </Index.IconButton>
                                                        </Index.InputAdornment>
                                                      }
                                                      label="Password"
                                                    />
                                                    {errors.confirmPassword &&
                                                      touched.confirmPassword && (
                                                        <p className="error-text">
                                                          {
                                                            errors.confirmPassword
                                                          }
                                                        </p>
                                                      )}
                                                  </Index.Box>
                                                </Index.Box>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box className="auth-primary-btn">
                            <PageIndex.PrimaryButton
                              btnLabel="Submit"
                              type="submit"
                              // className="comman-primary-btn submit-btn"
                              disabled={values?.isSubmit}
                              startIcon={
                                values?.isSubmit && (
                                  <Index.CircularProgress
                                    size={15}
                                    color="inherit"
                                  />
                                )
                              }
                              className={
                                values?.isSubmit
                                  ? "comman-primary-btn login-btn disabled-login-button"
                                  : "comman-primary-btn login-btn"
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </PageIndex.Form>
                    )}
                  </PageIndex.Formik>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
