import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function SaveAndPublish({
  openSaveAndPublish,
  handleCloseSaveAndPublish,
  handleOpenSaveAsDrafModal,
  handleCloseSaveAsDrafModal,
}) {
  // const [openSaveAndPublish, setOpenSaveAndPublish] = React.useState(false);
  // const handleOpenSaveAndPublish = () => setOpenSaveAndPublish(true);
  // const handleCloseSaveAndPublish = () => setOpenSaveAndPublish(false);

  const [openAskToGenerate, setOpenAskToGenerate] = useState(false);

  const handleOpenGenerate = () => {
    setOpenAskToGenerate(true);
  };

  const handleCloseAskToGenerate = () => {
    setOpenAskToGenerate(false);
  };
  const handleClick = () => {
    handleCloseSaveAndPublish();
    handleCloseSaveAsDrafModal();
    // handleOpenSaveAsDrafModal()
    handleOpenGenerate();
  };

  // for page navigation
  const navigate = PageIndex.useNavigate();
  return (
    <>
      {/* <Index.Button onClick={handleOpenSaveAndPublish}>Open modal</Index.Button> */}
      <Index.Modal
        open={openSaveAndPublish}
        onClose={handleCloseSaveAndPublish}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="common-modal"
      >
        <Index.Box
          sx={style}
          className="common-modal-inner save-publish-modal-inner"
        >
          <img src={PageIndex.Svg.close} className="modal-close-icon" title="Close" 
          onClick={handleCloseSaveAndPublish}
          onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleCloseSaveAndPublish();
              }
            }}
            tabIndex={0}

          />
          <Index.Box className="save-publish-modal-content">
            <Index.Typography className="modal-title-text">
              Choose Your Plan
            </Index.Typography>
            <Index.Box className="publish-radio-flex">
              <Index.Box className="publish-radio-box">
                <Index.Box className="publish-radio-content-main active-plan">
                  <Index.Typography className="publish-radio-title">
                    Plan 1
                  </Index.Typography>
                  <Index.Typography className="publish-radio-para para-select">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Mauris a lectus vitae tortor ultrices aliquam. Sed porttitor
                    velit eu risus ultricies, sit amet consectetur justo
                    ultrices.
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="publish-radio-box">
                <Index.Box className="publish-radio-content-main">
                  <Index.Typography className="publish-radio-title">
                    Plan 2
                  </Index.Typography>
                  <Index.Typography className="publish-radio-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Mauris a lectus vitae tortor ultrices aliquam. Sed porttitor
                    velit eu risus ultricies, sit amet consectetur justo
                    ultrices.
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="publish-radio-box">
                <Index.Box className="publish-radio-content-main">
                  <Index.Typography className="publish-radio-title">
                    Plan 3
                  </Index.Typography>
                  <Index.Typography className="publish-radio-para">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Mauris a lectus vitae tortor ultrices aliquam. Sed porttitor
                    velit eu risus ultricies, sit amet consectetur justo
                    ultrices.
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="modal-user-btn-flex">
            <PageIndex.PrimaryButton
                className="create-user-btn comman-primary-btn"
                btnLabel="Select"
                title="Select"
                onClick={handleClick}
              />
              <PageIndex.BorderButton
                className="cancel-user-btn border-btn"
                btnLabel="Cancel"
                title="Cancel"
                onClick={() => {
                  handleCloseSaveAndPublish();
                  handleCloseSaveAsDrafModal();
                }}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <PageIndex.AskToGenerateQr
        openAskToGenerate={openAskToGenerate}
        handleCloseAskToGenerate={handleCloseAskToGenerate}
        handleOpenSaveAsDrafModal={handleOpenSaveAsDrafModal}
      />
    </>
  );
}
