import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import PropTypes from "prop-types";

const columns = [
  { field: "id", headerName: "Sr.No.",},
  { field: "ProjectName", headerName: "Project Title",},
  { field: "SerialCode", headerName: "Serial Code",},
  { field: "QrTitle", headerName: "QR Title",},
  { field: "QRCode", headerName: "QR Code",  disableColumnMenu:true, sortable:false, renderCell: (params) => (
    <img src={PageIndex.Png.qrCode} className="table-thumbnail-icons" alt="Thumbnail" />
  ),},
  { field: "StartDate", headerName: "Start Date",},
  { field: "EndDate", headerName: "EndDate",},
 
];

function PublishedCreateData(
  ProjectName,
  QrTitle,
  SerialCode,
  QRCode,
  StartDate,
  EndDate,
  id
) {
  return {
    ProjectName,
    QrTitle,
    SerialCode,
    QRCode,
    StartDate,
    EndDate,
    id,
  };
}

const publishedtablerows = [
  PublishedCreateData(
    "Project Name",
    "QR1",
    "T1",
    <img src={PageIndex.Png.qrCode} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    1
  ),

  PublishedCreateData(
    "Project Name",
    "QR1",
    "T2",
    <img src={PageIndex.Png.qrCode} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    2
  ),

  PublishedCreateData(
    "Project Name",
    "QR1",
    "T3",
    <img src={PageIndex.Png.qrCode} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    3
  ),

  PublishedCreateData(
    "Project Name",
    "QR1",
    "T4",
    <img src={PageIndex.Png.qrCode} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    4
  ),
  PublishedCreateData(
    "Project Name",
    "QR1",
    "T5",
    <img src={PageIndex.Png.qrCode} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    5
  ),
];

export default function AdminQRCodeReport() {
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState(publishedtablerows.length);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // for tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Index.Box className="dashboard-content qr-code-content report-qr-code-content">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              QR Code Reports
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="userlist-inner-btn-flex">
              <Index.Box className="print-icon-box" title="Print">
                <img
                  src={PageIndex.Svg.printicon}
                  className="print-icons"
                  alt=""
                />
              </Index.Box>
              <PageIndex.PrimaryButton
                className="adduser-btn comman-primary-btn"
                btnLabel="Export"
                title="Export"
              />
            </Index.Box>
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img
                    src={PageIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="tabs-main-box">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box className="tabpanel-main common-card">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="user-search-flex">
                      <Index.Box className="user-page-sub-title-box">
                        <Index.Typography
                          className="user-page-sub-title"
                          component="h2"
                          variant="h2"
                        >
                          Published
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main adminQRcode-projects-table">
                    <Index.Box className="data-table-main">
                  <Index.DataGrid
                    rows={publishedtablerows}
                    // columns={columns}
                    columns={columns.map((col) => ({
                        ...col,
                        renderHeader: (params) => <PageIndex.CustomHeader {...params} column={col} />,
                      }))}
                    initialState={{
                      pagination: {
                        paginationModel: { page: page, pageSize: rowsPerPage },
                      },
                    }}
                    handleChangePage={handleChangePage}
                    pageSizeOptions={[15, 20, 25]}
                  />
                </Index.Box>
                    
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
             
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
