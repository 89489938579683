import React from 'react'
import Index from '../../../Index'
import PageIndex from '../../../PageIndex'

export default function HelpAndSupport() {
      const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
      return (
            <>
                  <Index.Box className="dashboard-content qr-code-content">
                        <Index.Box className="user-list-flex">
                              <Index.Box className="user-page-title-box">
                                    <Index.Typography
                                          className="user-page-title"
                                          component="h2"
                                          variant="h2"
                                    >
                                          Help And Support
                                    </Index.Typography>
                              </Index.Box>
                              <Index.Box className="user-search-box">
                                    <Index.Box className="form-group">
                                          <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control"
                                                placeholder="Search"
                                          />
                                          <img
                                                src={PageIndex.Svg.search}
                                                className="search-grey-img"
                                                alt="search grey img"
                                          ></img>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>

                        <Index.Box className="help-info-main common-card">
                              <Index.Box className="help-info-flex">
                                    <Index.Box className="help-info-lable">Contact Us:</Index.Box>
                                    <Index.Box className="help-info-data">+1888-256-8321</Index.Box>
                              </Index.Box>
                              <Index.Box className="help-info-flex">
                                    <Index.Box className="help-info-lable">Email Id:</Index.Box>
                                    <Index.Box className="help-info-data">info@webscooter.com</Index.Box>
                              </Index.Box>
                        </Index.Box>

                        <Index.Box className="cust-acc-main common-card">
                              <Index.Box className="user-page-sub-title-box">
                                    <Index.Typography className='user-page-sub-title' component='h2' variant='h2'>FAQs</Index.Typography>
                              </Index.Box>
                              <Index.Accordion className="cust-accordian" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <Index.AccordionSummary
                                          expandIcon={<Index.ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="cust-acc-summary"
                                    >
                                          <Index.Box className="acc-summary-title-box">
                                                <Index.Typography className='acc-summary-title-text'>
                                                      SUBSCRIPTION - Can i cancel my subscription at any time?
                                                </Index.Typography>
                                          </Index.Box>
                                    </Index.AccordionSummary>
                                    <Index.AccordionDetails className="cust-acc-details">
                                          <Index.Box className="acc-details-main">
                                                <Index.Typography className='acc-details-para'>
                                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                </Index.Typography>
                                          </Index.Box>
                                    </Index.AccordionDetails>
                              </Index.Accordion>
                              <Index.Accordion className="cust-accordian" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <Index.AccordionSummary
                                          expandIcon={<Index.ExpandMoreIcon />}
                                          aria-controls="panel2a-content"
                                          id="panel2a-header"
                                          className="cust-acc-summary"
                                    >
                                          <Index.Box className="acc-summary-title-box">
                                                <Index.Typography className='acc-summary-title-text'>SUBSCRIPTION - Which payment
                                                </Index.Typography>
                                          </Index.Box>
                                    </Index.AccordionSummary>
                                    <Index.AccordionDetails className="cust-acc-details">
                                          <Index.Box className="acc-details-main">
                                                <Index.Typography className='acc-details-para'>
                                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                </Index.Typography>
                                          </Index.Box>
                                    </Index.AccordionDetails>
                              </Index.Accordion>

                              <Index.Accordion className="cust-accordian" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <Index.AccordionSummary
                                          expandIcon={<Index.ExpandMoreIcon />}
                                          aria-controls="panel3a-content"
                                          id="panel3a-header"
                                          className="cust-acc-summary"
                                    >
                                          <Index.Box className="acc-summary-title-box">
                                                <Index.Typography className='acc-summary-title-text'>
                                                      SUBSCRIPTION - process needs
                                                </Index.Typography>
                                          </Index.Box>
                                    </Index.AccordionSummary>
                                    <Index.AccordionDetails className="cust-acc-details">
                                          <Index.Box className="acc-details-main">
                                                <Index.Typography className='acc-details-para'>
                                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                                                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                                                </Index.Typography>
                                          </Index.Box>
                                    </Index.AccordionDetails>
                              </Index.Accordion>
                        </Index.Box>
                  </Index.Box>
            </>
      )
}
