import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

// for published table data

const columns = [
  { field: "id", headerName: "Sr.No." , title:"Sr.No."},
  { field: "ProjectID", headerName: "Project ID", title: "Project ID" },
  { field: "ProjectName", headerName: " Project Title" ,title:"Project Title"},
  { field: "Description", headerName: "Description" ,title:"Description"},
  { field: "Createdat", headerName: " Created At" ,title:"Created At"},
  { field: "Publishedat", headerName: "Published At",title:"Published At" },

  { field: "Serialcode", headerName: "Serial Code",title:"Serial Code" },
  {
    field: "ProjectThumbnail",
    headerName: "Project Thumbnail",
    title:"Project Thumbnail",
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params) => (
      <img
        src={PageIndex.Svg.thumbnail}
        className="table-thumbnail-icons"
        alt="Thumbnail"
      />
    ),
  },
  { field: "StartDate", headerName: " Start Date" ,title:"Start Date"},
  { field: "EndDate", headerName: "End Date", title:"End Date" },
];

const rows = [
  {
    id: 1,
    ProjectID: 11207,
    Sno: "01",
    ProjectName: "Proje01",
    Description: "project description",
    Createdat: "01/12/2000",
    Publishedat: "01/12/2000",
    Serialcode: "RQ1",
    ProjectThumbnail: "",
    StartDate: "02/23/2023",
    EndDate: "02/24/2023",
  },

  {
    id: 2,
    ProjectID: 11207,
    Sno: "02",

    ProjectName: "Proje02",
    Description: "project description",
    Createdat: "01/11/2000",
    Publishedat: "01/11/2000",
    Serialcode: "RQ2",
    ProjectThumbnail: "",
    StartDate: "02/23/2023",
    EndDate: "02/24/2023",
  },
  {
    id: 3,
    ProjectID: 11207,
    Sno: "02",

    ProjectName: "Proje02",
    Description: "project description",
    Createdat: "01/11/2000",
    Publishedat: "01/11/2000",
    Serialcode: "RQ3",
    ProjectThumbnail: "",
    StartDate: "02/23/2023",
    EndDate: "02/24/2023",
  },
  {
    id: 4,
    ProjectID: 11207,
    Sno: "02",

    ProjectName: "Proje02",
    Description: "project description",
    Createdat: "01/11/2000",
    Publishedat: "01/11/2000",
    Serialcode: "RQ4",
    ProjectThumbnail: "",
    StartDate: "02/23/2023",
    EndDate: "02/24/2023",
  },
  {
    id: 5,
    ProjectID: 11207,
    Sno: "02",

    ProjectName: "Proje02",
    Description: "project description",
    Createdat: "01/11/2000",
    Publishedat: "01/11/2000",
    Serialcode: "RQ5",
    ProjectThumbnail: "",
    StartDate: "02/23/2023",
    EndDate: "02/24/2023",
  },
];
const CustomHeader = ({ column }) => {
  const { headerName, title } = column;

  return (
    <div title={headerName} >
      <span>{headerName}</span>
    </div>
  );
};
const handleGetGridCellParams = (params) => {
  if (params.field !== 'id') {
    return {
      ...params,
      title: undefined, // Remove the 'title' attribute from cells, but keep it for other headers
    };
  }
  return params;
};
export default function PojectReport() {
  // for open handleChangedropdown

  return (
    <>
      <Index.Box className="dashboard-content project-report-content user-project-report-wrape">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              Project Reports
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="userlist-inner-btn-flex">
              <Index.Box className="print-icon-box" title="Print">
                <img src={PageIndex.Svg.printicon} className="print-icons" />
              </Index.Box>
              <PageIndex.PrimaryButton
                title="Export"
                className="adduser-btn comman-primary-btn"
                btnLabel="Export"
              />
            </Index.Box>
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img
                    src={PageIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="menu-report-section common-card">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="page-table-main projects-report-table">
                  <Index.Box className="data-table-main">
                    <Index.DataGrid
                      rows={rows}
                      // columns={columns}
                      columns={columns.map((col) => ({
                        ...col,
                        renderHeader: (params) => <CustomHeader {...params} column={col} />,
                      }))}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 15 },
                        },
                      }}
                      pageSizeOptions={[15, 20, 25]}
                      getGridCellParams={handleGetGridCellParams}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
