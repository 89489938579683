import { createSlice } from "@reduxjs/toolkit";
import PageIndex from "../../../../container/PageIndex";

export const UserSlice = createSlice({
  name: "user",
  initialState: {
    userToken: "",
    isAuthenticated: false,
    isLoading: false,
    userData: {},
    darkMode:false  
  },
  reducers: {
    getUserData: (state, action) => {
      state.userData = action.payload;
    },
    setdarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    userLogout: (state, action) => {
      state.userToken = ""
      state.isAuthenticated= false
      state.isLoading= false
      state.userData= {}
    },
  },
  
  extraReducers: (builder) => {
    // builder.addCase(LoginAdmin.fulfilled, (state, action) => {
    //   state.isAuthenticated = true;
    // });
    builder.addCase(PageIndex.userLogin.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.userData = action?.payload?.data;
      state.userToken = action?.payload?.data?.token;
    });
  },
});

export const { getUserData ,userLogout,setdarkMode} = UserSlice.actions;

export default UserSlice.reducer;
