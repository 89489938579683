import React from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function SaveAsDraft({
  openSaveAsDrafModal,
  handleCloseSaveAsDrafModal,
  handleOpenSaveAsDrafModal,
}) {
  // const [openSaveAsDrafModal, setOpenSaveAsDrafModal] = React.useState(false);
  // const handleOpenSaveAsDrafModal = () => setOpenSaveAsDrafModal(true);
  // const handleCloseSaveAsDrafModal = () => setOpenSaveAsDrafModal(false);

  const [openSaveAndPublish, setOpenSaveAndPublish] = React.useState(false);
  const handleOpenSaveAndPublish = () => {
    setOpenSaveAndPublish(true);
    handleCloseSaveAsDrafModal();
  };
  const handleCloseSaveAndPublish = () => setOpenSaveAndPublish(false);

  // for page navigation
  const navigate = PageIndex.useNavigate();

  return (
    <>
      {/* <Index.Button onClick={handleOpenSaveAsDrafModal}>Open modal</Index.Button> */}
      <Index.Modal
        open={openSaveAsDrafModal}
        onClose={handleCloseSaveAsDrafModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="common-modal"
      >
        <Index.Box
          sx={style}
          className="common-modal-inner save-as-draft-modal-inner"
        >
          <img
            src={PageIndex.Svg.close}
            className="modal-close-icon"
            onClick={handleCloseSaveAsDrafModal}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleCloseSaveAsDrafModal();
              }
            }}
            tabIndex={0}
            title="Close"
          />
          <Index.Box className="save-draft-modal-content">
            <Index.Typography className="modal-title-text">
              Do you want to Save and Publish ?
            </Index.Typography>
            <Index.Box className="modal-user-center-btn-flex">
              <PageIndex.BorderButton
                className="cancel-user-btn border-btn"
                btnLabel="Save as Draft"
                title="Save as Draft"
                onClick={() => {
                  handleCloseSaveAsDrafModal();
                  navigate("/user/project-directory", { state: { tabId: 1 } });
                }}
              />
              <PageIndex.PrimaryButton
                className="create-user-btn comman-primary-btn"
                btnLabel="Save & Publish"
                title="Save & Publish"
                onClick={handleOpenSaveAndPublish}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <PageIndex.SaveAndPublish
        openSaveAndPublish={openSaveAndPublish}
        handleCloseSaveAndPublish={handleCloseSaveAndPublish}
        handleOpenSaveAsDrafModal={handleOpenSaveAsDrafModal}
        handleCloseSaveAsDrafModal={handleCloseSaveAsDrafModal}
      />
    </>
  );
}
