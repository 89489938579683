// service
import { createAsyncThunk } from "@reduxjs/toolkit";
import PageIndex from "../../../../container/PageIndex";

export const adminLogin = createAsyncThunk("admin/adminLogin", async (data) => {
  try {
    const response = await PageIndex.doPost(
      PageIndex.Api.Admin.Auth.login,
      data
    );
    localStorage.setItem("adminLogin", true);
    return response.data;
  } catch (error) {}
});

export const adminForgotPassword = createAsyncThunk(
  "admin/adminForgotPassword",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.Admin.Auth.forgotPassword,
        data
      );
      return response.data;
    } catch (error) {}
  }
);
export const adminVerifyOtp = createAsyncThunk(
  "admin/adminVerifyOtp",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.Admin.Auth.verifyOtp,
        data
      );
      return response.data;
    } catch (error) {}
  }
);
export const adminResetPassword = createAsyncThunk(
  "admin/adminResetPassword",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.Admin.Auth.resetPassword,
        data
      );
      return response.data;
    } catch (error) {}
  }
);

export const adminChangePassword = createAsyncThunk(
  "admin/adminChangePassword",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.Admin.Auth.changePassword,
        data
        // data.token,
      );
      return response.data;
    } catch (error) {}
  }
);
export const adminUpdateProfile = createAsyncThunk(
  "admin/adminUpdateProfile",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.Admin.Auth.updateProfile,
        data
      );
      return response.data;
    } catch (error) {}
  }
);

//---------------Start User Master----------------//

//Fetch User Master
export const fetchUserMasterList = createAsyncThunk(
  "admin/fetchUserMasterList",
  async () => {
    try {
      const response = await PageIndex.doGet(PageIndex.Api.Admin.UserList.get);
      return response.data;
    } catch (error) {}
  }
);

//Fetch Single User Master
export const fetchSingleUserMaster = createAsyncThunk(
  "admin/fetchSingleUserMaster",
  async (id) => {
    try {
      const response = await PageIndex.doGetParams(
        PageIndex.Api.Admin.UserList.getSingle,
        id
      );
      return response.data;
    } catch (error) {}
  }
);

//User Master Status Update
export const updateUserMasterStatus = createAsyncThunk(
  "admin/updateUserMasterStatus",
  async (data) => {
    try {
      const response = await PageIndex.doPost(
        PageIndex.Api.Admin.UserList.updateStatus,
        data
      );
      return response.data;
    } catch (error) {}
  }
);

//---------------End User Master----------------//

export const getRecentUsers = createAsyncThunk(
  "admin/fetchUserMasterList",
  async () => {
    try {
      const response = await PageIndex.doGet(
        PageIndex.Api.Admin.UserList.getRecentUsers
      );
      return response.data;
    } catch (error) {}
  }
);
