import React from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import "./uploadfile.css";
// for tabs design

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// for file upload

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function UploadFile() {
  // for page navigation
  const navigate = PageIndex.useNavigate();

  // for tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Index.Box className="dashboard-content media-library-content">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              Upload File
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="tabs-main-box common-card">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="user-tabs-main"
              >
                <Index.Tab
                  title="Audio"
                  label="Audio"
                  {...a11yProps(0)}
                  className="user-tab"
                />
                <Index.Tab
                  title="Images"
                  label="Images"
                  {...a11yProps(1)}
                  className="user-tab"
                />
                <Index.Tab
                  title="3D VR Files "
                  label="3D VR Files "
                  {...a11yProps(0)}
                  className="user-tab"
                />
                <Index.Tab
                  title="Video Files"
                  label="Video Files"
                  {...a11yProps(1)}
                  className="user-tab"
                />
              </Index.Tabs>
            </Index.Box>
            <TabPanel value={value} index={0} className="user-tabpanel">
              <Index.Box className="tabpanel-main">
                <Index.Box className="media-section">
                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="upload-cards">
                            <Index.Box className="input-box-auth">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label-input-title">
                                  Caption
                                </Index.FormHelperText>
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box-auth">
                            <Index.FormHelperText className="label-input-title">
                              Select
                            </Index.FormHelperText>
                            <Index.Box className="file-upload-btn-main media-upload-btn-main">
                              <Index.Button
                                title="Add File"
                                component="label"
                                variant="contained"
                                className="file-upload-btn media-upload-btn"
                              >
                                <img
                                  src={PageIndex.Svg.plus}
                                  className="file-upload-plus-icon"
                                />
                                <VisuallyHiddenInput type="file" />
                              </Index.Button>
                              <Index.Typography className="choose-img-text">
                                Note: Max size allowed 128 MB
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={1} className="user-tabpanel">
              <Index.Box className="tabpanel-main">
                <Index.Box className="media-section">
                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="upload-cards">
                            <Index.Box className="input-box-auth">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label-input-title">
                                  Caption
                                </Index.FormHelperText>
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box-auth">
                            <Index.FormHelperText className="label-input-title">
                              Select
                            </Index.FormHelperText>
                            <Index.Box className="file-upload-btn-main media-upload-btn-main">
                              <Index.Button
                                title="Add File"
                                component="label"
                                variant="contained"
                                className="file-upload-btn media-upload-btn"
                              >
                                <img
                                  src={PageIndex.Svg.plus}
                                  className="file-upload-plus-icon"
                                />
                                <VisuallyHiddenInput type="file" />
                              </Index.Button>
                              <Index.Typography className="choose-img-text">
                                Note: Max size allowed 128 MB
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={2} className="user-tabpanel">
              <Index.Box className="tabpanel-main">
                <Index.Box className="media-section">
                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="upload-cards">
                            <Index.Box className="input-box-auth">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label-input-title">
                                  Caption
                                </Index.FormHelperText>
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box-auth">
                            <Index.FormHelperText className="label-input-title">
                              Select
                            </Index.FormHelperText>
                            <Index.Box className="file-upload-btn-main media-upload-btn-main">
                              <Index.Button
                                title="Add File"
                                component="label"
                                variant="contained"
                                className="file-upload-btn media-upload-btn"
                              >
                                <img
                                  src={PageIndex.Svg.plus}
                                  className="file-upload-plus-icon"
                                />
                                <VisuallyHiddenInput type="file" />
                              </Index.Button>
                              <Index.Typography className="choose-img-text">
                                Note: Max size allowed 128 MB
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={3} className="user-tabpanel">
              <Index.Box className="tabpanel-main">
                <Index.Box className="media-section">
                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="upload-cards">
                            <Index.Box className="input-box-auth">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label-input-title">
                                  Caption
                                </Index.FormHelperText>
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box-auth">
                            <Index.FormHelperText className="label-input-title">
                              Select
                            </Index.FormHelperText>
                            <Index.Box className="file-upload-btn-main media-upload-btn-main">
                              <Index.Button
                                title="Add File"
                                component="label"
                                variant="contained"
                                className="file-upload-btn media-upload-btn"
                              >
                                <img
                                  src={PageIndex.Svg.plus}
                                  className="file-upload-plus-icon"
                                />
                                <VisuallyHiddenInput type="file" />
                              </Index.Button>
                              <Index.Typography className="choose-img-text">
                                Note: Max size allowed 128 MB
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="user-btn-flex">
                <PageIndex.PrimaryButton
                  className="create-user-btn comman-primary-btn"
                  title="Upload"
                  btnLabel="Upload"
                  onClick={() => {
                    navigate("/user/upload-successfully", {
                      state: { value },
                    });
                  }}
                />
                <PageIndex.BorderButton
                  className="cancel-user-btn border-btn"
                  title="Cancel"
                  btnLabel="Cancel"
                  onClick={() => {
                    navigate("/user/media-library");
                  }}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
