import React from 'react'
import Index from '../../Index'

export default function PrimaryButton(props) {
    return (
        <>
            <Index.Box className='primary-btn-content'>
                <Index.Button className={props.className} type={props.type} onClick={props.onClick}   title={props.btnLabel} disableRipple {...props} ><span>{props.btnLabel}</span></Index.Button>
            </Index.Box>

            {/* use this button like below demo */}
            {/* <Index.PrimaryButton btnLabel="View Button"/> */}
        </>
    )
}
