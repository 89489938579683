import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import OTPTimer from "../../../user/auth/otp/OTPTimer";

export default function OTP() {
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const location = PageIndex.useLocation();
  const [otp, setOtp] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [active, setActive] = useState(false);
  const [otpError, setOtpError] = useState("");
  const adminId = location?.state;
  const handleChange = (newValue) => {
    // const numericValue = newValue.replace(/[^0-9]/g, "");
    const numericValue = newValue.replace(/\D/g, "");
    setOtp(numericValue);
    if (!newValue.trim()) {
      setOtpError("Please enter your OTP");
    } else if (!isNaN(numericValue) && numericValue.length === 6) {
      setOtpError("");
      setActive(true);
      // Enable the button when 4 digits are entered
    } else {
      // setOtpError("OTP must be a 4-digit number");
      setActive(false);
      // Disable the button if less than 4 digits are entered
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!otp) {
      setOtpError("Please enter your OTP");
    } else if (!isNaN(otp) && otp.length !== 6) {
      setOtpError("Otp must be a 6-digit number");
    } else if (!isNaN(otp) && otp.length === 6) {
      setOtpError("");
    }
    if (!otpError) {
      setIsDisable(true);
      const urlencoded = new URLSearchParams();
      urlencoded.append("id", adminId);
      urlencoded.append("otp", +otp);
      dispatch(PageIndex.adminVerifyOtp(urlencoded)).then((res) => {
        if (res?.payload?.status == 200) {
          const id = res?.payload?.data?._id;
          setTimeout(() => {
            navigate("/admin/reset-password", { state: id });
          }, 1000);
        } else {
          setTimeout(() => setIsDisable(false), 3000);
        }
      });
    }
  };
  const handleResendOTP = () => {
    // const obj = new URLSearchParams();
    // urlencoded.append("id", userId);
    // dispatch(PageIndex.userResendOTP(obj)).then((res) => {});
  };
  return (
    <>
      <Index.Box className="login-user-screen">
        <Index.Box className="auth-user-row">
          <Index.Box className="auth-row-col-left">
            <Index.Box className="dark-gray">
              <Index.Box className="auth-bg-content"></Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="auth-row-col-right">
            <Index.Box className="light-gray">
              <Index.Box className="auth-page-bg login-content">
                <Index.Box className="auth-bg-pd">
                  <Index.Box className="auth-title-content">
                    <Index.Typography
                      component="h6"
                      variant="h6"
                      className="signin-title"
                    >
                      OTP
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="signin-details-title"
                    >
                      {/* Please enter your OTP */}
                    </Index.Typography>
                  </Index.Box>
                  <form onSubmit={handleSubmit}>
                    <Index.Box className="auth-signin-details">
                      <Index.Box className="scroll-content-auth">
                        <Index.Box className="input-details-auth">
                          <Index.Box className="grid-row">
                            <Index.Box sx={{ width: 1 }} className="grid-main">
                              <Index.Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                              >
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 12",
                                    md: "span 12",
                                    lg: "span 12",
                                    xl: "span 12",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box-auth otp-input-box">
                                    <Index.OTPInput
                                      onChange={handleChange}
                                      numInputs={6}
                                      value={otp}
                                      name="otp"
                                      shouldAutoFocus
                                      inputType="tel"
                                      renderInput={(props) => (
                                        <input
                                          {...props}
                                          inputmode="numeric"
                                          pattern="[0-9]*"
                                        />
                                      )}
                                      // renderSeparator={<span>-</span>}
                                      className="otp-input"
                                    />
                                    {/* <Index.MuiOtpInput
                                      value={otp}
                                      onChange={handleChange}
                                      autoFocus
                                      length={6}
                                      // inputMode="numeric"
                                      inputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                      }}
                                      className="otp-input"
                                      name="otp"
                                    /> */}
                                    {otpError !== " " && (
                                      <p className="error-text">{otpError}</p>
                                    )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="condition-content">
                        <Index.Typography className="condition-text">
                          {" "}
                          Didn't get OTP{" "}
                          <span className="condition-span">
                            <OTPTimer onResend={handleResendOTP} />
                            {/* <Index.Link className="condition-span">
                              Resend
                            </Index.Link> */}
                          </span>
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="auth-primary-btn">
                        <PageIndex.PrimaryButton
                          btnLabel="Submit"
                          type="submit"
                          disabled={isDisable || !active || otpError}
                          startIcon={
                            isDisable && (
                              <Index.CircularProgress
                                size={15}
                                color="inherit"
                              />
                            )
                          }
                          className={
                            isDisable
                              ? "comman-primary-btn login-btn disabled-login-button"
                              : "comman-primary-btn login-btn"
                          }
                        />
                      </Index.Box>
                    </Index.Box>
                  </form>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
