import React from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function AskToGenerateQr({
  openAskToGenerate,
  handleCloseAskToGenerate,
  handleOpenSaveAsDrafModal,
}) {
  const navigate = PageIndex.useNavigate();

  // const [openAskToGenerateQr, setOpenAskToGenerateQr] = React.useState(false);
  // const handleOpenAskToGenerateQr = () => setOpenAskToGenerateQr(true);
  // const handleCloseAskToGenerateQr = () => setOpenAskToGenerateQr(false);
  return (
    <>
      {/* <Index.Button onClick={handleOpenAskToGenerateQr}>Open modal</Index.Button> */}
      <Index.Modal
        open={openAskToGenerate}
        onClose={handleCloseAskToGenerate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="common-modal"
      >
        <Index.Box
          sx={style}
          className="common-modal-inner ask-togenerate-modal-inner"
        >
          <img
            src={PageIndex.Svg.close}
            className="modal-close-icon"
            title="Close"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleCloseAskToGenerate();
              }
            }}
            tabIndex={0}
            onClick={handleCloseAskToGenerate}
          />
          <Index.Box className="save-draft-modal-content">
            <Index.Typography className="modal-title-text">
              Do you want to generate a QR Code now?
            </Index.Typography>
            <Index.Box className="modal-user-center-btn-flex">
              <PageIndex.PrimaryButton
                className="create-user-btn comman-primary-btn"
                btnLabel="Yes"
                title="Yes"
                onClick={() => {
                  handleCloseAskToGenerate();
                  navigate("/user/qr-code-generation");
                }}
              />
              <PageIndex.BorderButton
                className="cancel-user-btn border-btn"
                btnLabel="No"
                title="No"
                onClick={() => {
                  handleCloseAskToGenerate();
                  navigate("/user/project-directory");
                }}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
