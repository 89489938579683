import * as Yup from "yup";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { easing } from "@mui/material";
// const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
// const emailRegex= /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,64}@(?:(?=[a-zA-Z0-9-]{1,255}\.)(?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.){1,127}[a-zA-Z]{2,63}$/
// const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
// const passwordRegex =
//   /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).+$/;
// const emailRegex =
// /^(?=.{1,64}@.{1,255}$)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const emailRegex = /^(?=.{1,64}@.{1,254}$)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const checkEmail= /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const email=/[a-zA-Z]{2,}$/

//^(?=.{1,64}@)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:(?=[a-zA-Z0-9-]{1,255}\.)(?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.){1,127}[a-zA-Z]{2,63}$/


  // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,64}@(?:(?=[a-zA-Z0-9-]{1,255}\.)(?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.){1,127}[a-zA-Z]{2,63}$/;

const passwordRegex = {
  lowercase: /^(?=.*[a-z])/,
  uppercase: /^(?=.*[A-Z])/,
  number: /^(?=.*\d)/,
  specialChar: /^(?=.*[()!@#$%^&*])/,
  length: /^(?=.{8,})/,
};
const alphanumericRegex = /^[0-9a-zA-Z]*$/
const startSpace = /^(?!\s).*$/;
const phoneRegex = /^(?!(\d)(?:-?\1){9,})[6789]\d{9}$/;
// const address=/(^|\s)[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+($|\s)/
const address = /^[A-Za-z0-9\s,()#-/]+$/;

export const userLoginValidationSchema = ({ isLoginPage }) =>
  Yup.object().shape({
    input: Yup.string()
      .matches(startSpace, "Starting space not allow")
      .required("Please enter Email or Phone"),
    password: isLoginPage
      ? Yup.string()
          .matches(startSpace, "Starting space not allow")
          .required("Please enter Password")
      : Yup.string(),
  });
export const isValidEmail = (email) => {
  return emailRegex.test(email);
};
export const isValidPhoneNumber = (phoneNumber) => {
  return phoneRegex.test(phoneNumber);
};
export const userRegistrationValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(/^[A-Za-z\s]*$/, "Enter only alphabet characters")
    .required("Please enter your First Name")
    .max(15, "First Name at most 15 characters")
    .min(2, "First Name must be at least 2 characters"),
  lastName: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(/^[A-Za-z\s]*$/, "Enter only alphabet characters")
    .max(15, "Last Name at most 15 characters")
    .min(2, "Last Name must be at least 2 characters")
    .required("Please enter your Last Name"),
  email: Yup.string()
    .matches(startSpace, "Starting space not allow")
    // .max(50, "Email at most 50 characters")
    .matches(emailRegex, "Please enter valid Email")
    .matches(checkEmail, "Please enter valid Email")
    .matches(email, "Please enter valid Email")
    // .max(320, "Email should not exceed 320 characters")
    .required("Please enter your Email"),

  phoneNumber: Yup.string().required("Please enter your Number"),

  password: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(
      passwordRegex.lowercase,
      "Password must contain at least one lowercase letter"
    )
    .matches(
      passwordRegex.uppercase,
      "Password must contain at least one uppercase letter"
    )
    .matches(passwordRegex.number, "Password must contain at least one number")
    .matches(
      passwordRegex.specialChar,
      "Password must contain at least one special character"
    )
    // .matches(
    //   passwordRegex.length,
    //   "Password must be at least 8 characters long"
    // )
    .matches(
      passwordRegex.length,
      "Example: John@123"
    )
    .min(8, "Password must be at least 8 characters")
    .max(15, "Maximum 15 charaters allowed")
    .required("Please enter Password"),
  confirmPassword: Yup.string()
    .required("Please enter Confirm Password")
    .oneOf([Yup.ref("password"), null], "Confirm password does not match with new password"),
  addressLine1: Yup.string()
    // .matches(startSpace, "Starting space not allow")
    .matches(address, "Please enter valid Address Line 1")
    // .test(
    //   "Please enter valid Address",
    //   "Please enter valid Address",
    //   (value) => {
    //     return address.test(value);
    //   }
    // )
    // .test(
    //   "not-numeric-only",
    //   "Please enter valid Address Line 1",
    //   (value) => {
    //     //  return value.trim().length === 0 || /\d/.test(value);
    //     return /\D/.test(value); 
    //   }
    // )
    .test(
      "not-continuous-numbers",
      "Please enter valid Address Line 1",
      (value) => {
        return !/\d{6,}/.test(value);
      }
    )
    
    .max(255, "Address cannot exceed 255 characters")
    .min(20, "Address must be at least 20 characters")
    .required("Please enter your Address Line 1"),

  city: Yup.string()
    // .matches(startSpace, "Starting space not allow")
    .matches(/^[A-Za-z\s]*$/, "Enter only alphabet characters")
    .max(250, "City name cannot exceed 250 characters")
    .min(3, "City name must be at least 3 characters")
    .required("Please enter your City"),
  state: Yup.string()
    // .matches(startSpace, "Starting space not allow")
    .matches(/^[A-Za-z\s]*$/, "Enter only alphabet characters")
    .max(250, "State name cannot exceed 250 characters")
    .min(3, "State name must be at least 3 characters")
    .required("Please enter your State"),
  country: Yup.string()
    // .matches(startSpace, "Starting space not allow")
   
    .required("Please select your Country"),
  zip: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(alphanumericRegex, "ZIP should be alphanumeric or numbers only")
    .test("not-start-with-zero", "Please enter valid Zip", (value) => {
      return !value.startsWith('0');
    })
    .test("is-valid-zip", "Please enter valid Zip", (value) => {
      return /\d/.test(value); 
    })
    .test("is-valid-zip", "Please enter valid Zip", (value) => {
      return value.trim().length !== 0 && parseInt(value, 10) !== 0;
    })
    .test("not-greater-than-1000", "Please enter valid Zip", (value) => {
      const numericValue = parseInt(value, 10);
      return isNaN(numericValue) || numericValue >= 1000;
    })
    .min(4,"Please enter your Zip")
    .required("Please enter your Zip")

    .max(8),
   
  agreeToTerms: Yup.boolean()
    .oneOf([true], "You must agree to the Terms and Conditions")
    .required("You must Agree to the Terms and Conditions"),
});

export const userUpdateProfileValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(/^[A-Za-z\s]*$/, "Enter only alphabet characters")
    .required("Please enter your First Name")
    .max(15, "First Name cannot exceed 15 characters")
    .min(2, "First Name must be at least 2 characters"),
  lastName: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(/^[A-Za-z\s]*$/, "Enter only alphabet characters")
    .required("Please enter your Last Name")
    .max(15, "Last Name cannot exceed 15 characters")
    .min(2, "Last Name must be at least 2 characters"),
  email: Yup.string()
    .matches(startSpace, "Starting space not allow")
    .matches(emailRegex, "Please enter valid Email")
    .matches(checkEmail, "Please enter valid Email")
    .matches(email, "Please enter valid Email")

    .required("Please enter your Email"),
  // companyName: Yup.string()
  //   .matches(startSpace, "Starting space not allow")
  //   .matches(/^[A-Za-z\s]*$/, "Enter only alphabet characters")
  //   .required("Please enter company name"),
  addressLine1: Yup.string()
    // .matches(startSpace, "Starting space not allow")
   
    .test(
      "Please enter valid Address Line 1",
      "Please enter valid Address Line 1",
      (value) => {
        return address.test(value);
      }
    )

    .test(
      "not-continuous-numbers",
      "Please enter valid Address Line 1",
      (value) => {
        return !/\d{6,}/.test(value); 
      }
    )
    .max(255, "Address cannot exceed 255 characters")
    .min(20, "Address must be at least 20 characters")
    .required("Please enter your Address Line 1"),
  city: Yup.string()
   
    .matches(/^[A-Za-z\s]*$/, "Enter only alphabet characters")
    .required("Please enter your City")
    .max(250, "City name cannot exceed 250 characters")
    .min(3, "City name must be at least 3 characters"),
  state: Yup.string()
    
    .matches(/^[A-Za-z\s]*$/, "Enter only alphabet characters")
    .max(250, "State name cannot exceed 250 characters")
    .min(3, "State name must be at least 3 characters")
    .required("Please enter your State"),
  country: Yup.string()
    .required("Please enter your Country"),
  zip: Yup.string()
    .matches(alphanumericRegex, "ZIP should be alphanumeric or numbers only")
    .test("not-start-with-zero", "Please enter valid Zip", (value) => {
      return !value.startsWith('0');
    })
    .test("is-valid-zip", "Please enter valid Zip", (value) => {
      return value.trim().length !== 0 && parseInt(value, 10) !== 0;
    })
    .test("is-valid-zip", "Please enter valid Zip", (value) => {
      return /\d/.test(value); 
    })
    .test("not-greater-than-1000", "Please enter valid Zip", (value) => {
      const numericValue = parseInt(value, 10);
      return isNaN(numericValue) || numericValue >= 1000;
    })
    .required("Please enter your Zip")
    .min(4,"Please enter your Zip")
    .max(8),
  profile_image: Yup.mixed()
    // .test(
    //   "fileSize",
    //   "Image size is too large",
    //   (value) => value && value.size <= 102400
    // )
    .test("FileType", "Unsupported file type", (value) => {
      if (!value) return true;
      if (!value?.name) {
        const file = value?.split(".")[1];
        return ["jpeg", "jpg", "png"].includes(file);
      } else {
        return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
      }
    }),
  phoneNumber: Yup.string()
    .required("Please enter your Number")
    
});

export const validateMobileNo = (mobileNo, countryCode) => {
  // try {
   
  const parsedPhoneNumber = parsePhoneNumberFromString(mobileNo, countryCode);

  if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
    return "Please enter a valid Number";
  }
 
  return "";
};
