import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import PropTypes from "prop-types";

// for tabs design
const CustomHeader = ({ column }) => {
  const { headerName } = column;

  return (
    <div title={headerName} >
      <span>{headerName}</span>
    </div>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// for published table data

const columns = [
  { field: "SrNo", headerName: "Sr.No.",},
  { field: "QrTitle", headerName: "QR Title",},
  { field: "SerialCode", headerName: " Serial Code",},

  {
    field: "ProjectThumbnail",
    headerName: "QR Code",
    disableColumnMenu:true, sortable:false,
    renderCell: (params) => (
      <img
        src={PageIndex.Png.qrCode}
        className="table-thumbnail-icons"
        alt="Thumbnail"
      />
    ),
  },
  { field: "StartDate", headerName: " Start Date",},
  { field: "EndDate", headerName: "End Date",},
  { field: "Shape", headerName: "Shape",},

  { field: "ForeColor", headerName: "Fore Color",},
  { field: "BackColor", headerName: "Back Color",},
];

const rows = [
  {
    id: 1,
    SrNo: "01",
    QrTitle: "QR1",
    SerialCode: "T1",
    ProjectThumbnail: "",
    StartDate: "02/23/2023",
    EndDate: "02/24/2023",
    Shape: "Rectangle",
    ForeColor: "White",
    BackColor: "Black",
  },

  {
    id: 2,
    SrNo: "02",
    QrTitle: "QR1",
    SerialCode: "T1",
    StartDate: "02/23/2023",
    EndDate: "02/24/2023",
    Shape: "Rectangle",
    ForeColor: "White",
    BackColor: "Black",
  },
  {
    id: 3,
    SrNo: "03",
    QrTitle: "QR1",
    SerialCode: "T1",
    ProjectThumbnail: "",
    StartDate: "02/23/2023",
    EndDate: "02/24/2023",
    Shape: "Rectangle",
    ForeColor: "White",
    BackColor: "Black",
  },

  {
    id: 4,
    SrNo: "04",
    QrTitle: "QR1",
    SerialCode: "T1",
    StartDate: "02/23/2023",
    EndDate: "02/24/2023",
    Shape: "Rectangle",
    ForeColor: "White",
    BackColor: "Black",
  },
];
export default function QRCodeReport() {
  // for tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // for published table data

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Index.Box className="dashboard-content qr-code-content user-qr-code-report-wrape">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              QR Code Reports
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="userlist-inner-btn-flex">
                <Index.Box className="print-icon-box" title="Print">
                  <img src={PageIndex.Svg.printicon} className="print-icons" />
                </Index.Box>
              <PageIndex.PrimaryButton
                className="adduser-btn comman-primary-btn"
                btnLabel="Export"
                title="Export"
              />
            </Index.Box>
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img
                    src={PageIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="tabs-main-box">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box className="tabpanel-main common-card">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="page-table-main qr-code-report-table">
                      <Index.Box className="data-table-main">
                        <Index.DataGrid
                          className=""
                          rows={rows}
                          // columns={columns}
                          columns={columns.map((col) => ({
                        ...col,
                        renderHeader: (params) => <CustomHeader {...params} column={col} />,
                      }))}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 15 },
                            },
                          }}
                          pageSizeOptions={[15, 20, 25]}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              {/* <Index.Box className="pagination-main">
                <PageIndex.Pagination
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  count={count}
                />
              </Index.Box> */}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
