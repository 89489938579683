import dashboard from "./images/svg/dashboard.svg";
import notification from "./images/svg/notification.svg";
import settings from "./images/svg/setting.svg";
import userlist from "./images/svg/user-list.svg";
import editpage from "./images/svg/edit-page.svg";
import changepassword from "./images/svg/change-pass.svg";
import profile from "./images/svg/profile.svg";
import profilegrey from "./images/svg/profile-grey.svg";
import save from "./images/svg/save.svg";
import edit from "./images/svg/edit.svg";
import blueedit from "./images/svg/blue-edit.svg";
import trash from "./images/svg/trash.svg";
import yelloweye from "./images/svg/yellow-eye.svg";
import plus from "./images/svg/plus.svg";
import down from "./images/svg/down.svg";
import search from "./images/svg/search.svg";
import closecircle from "./images/svg/check-circle.svg";
import bergurmenu from "./images/svg/bergur-menu.svg";
import paginationleft from "./images/svg/pagination-left.svg";
import paginationright from "./images/svg/pagination-left.svg";
import filter from "./images/svg/filter.svg";
import close from "./images/svg/close.svg";
import instagram from "./images/svg/instagram.svg";
import youtube from "./images/svg/youtube.svg";
import skype from "./images/svg/skype.svg";
import linkdin from "./images/svg/linkedin.svg";
import twitter from "./images/svg/twitter.svg";
import closeblack from "./images/svg/close-black.svg";
import deleteicon from "./images/svg/delete.svg";
import facebook from "./images/svg/facebook.svg";
import discord from "./images/svg/discord.svg";
import usercard from "./images/svg/user-card.svg";
import captchicon from "./images/svg/captcha.svg";
import view from "./images/svg/view.svg";
import thumbnail from "./images/svg/thumbnail.svg";
import projecdirectory from "./images/svg/project.svg";
import qrcode from "./images/svg/qr.svg";
import medialibrary from "./images/svg/media-library.svg";
import report from "./images/svg/report.svg";
import upload from "./images/svg/upload.svg";
import share from "./images/svg/share.svg";
import exporticon from "./images/svg/export.svg";
import dashboard1 from "./images/svg/dash1.svg";
import dashboard2 from "./images/svg/dash2.svg";
import dashboard3 from "./images/svg/dash3.svg";
import dashboard4 from "./images/svg/dash4.svg";
import dashboard5 from "./images/svg/dash5.svg";
import dashboard6 from "./images/svg/dash6.svg";
import jpegicon from "./images/svg/jpgicon.svg";
import svgicon from "./images/svg/svgicon.svg";
import printicon from "./images/svg/printer.svg";
import copy from "./images/svg/copy.svg";
import qrscanner from "./images/svg/qrscanner.svg";
import closeicon from "./images/svg/close-icon.svg";
import downloads from "./images/svg/downloads.svg";
import analytics from "./images/svg/analytics.svg";
import helpsupport from "./images/svg/help-support.svg";
import payment from "./images/svg/credit-card.svg";
import logout from "./images/svg/logout.svg";
import help from "./images/svg/help.svg";
import emailadmin from "./images/svg/email-managment.svg";
import subscriptionadmin from "./images/svg/subscription-plan.svg";
import usermanagment from "./images/svg/user-managment.svg";
import analyticsadmin from "./images/svg/analytics.svg";
import paymentadmin from "./images/svg/payment.svg";
import cloudicon from "./images/svg/cloud.svg";
const Svg = {
  dashboard,
  notification,
  settings,
  changepassword,
  editpage,
  userlist,
  profile,
  save,
  edit,
  blueedit,
  trash,
  yelloweye,
  plus,
  down,
  search,
  closecircle,
  profilegrey,
  bergurmenu,
  paginationleft,
  paginationright,
  filter,
  close,
  instagram,
  youtube,
  skype,
  twitter,
  linkdin,
  closeblack,
  deleteicon,
  facebook,
  discord,
  usercard,
  captchicon,
  view,
  thumbnail,
  projecdirectory,
  qrcode,
  medialibrary,
  report,
  upload,
  share,
  exporticon,
  dashboard1,
  dashboard2,
  dashboard3,
  dashboard4,
  dashboard5,
  dashboard6,
  printicon,
  svgicon,
  jpegicon,
  copy,
  qrscanner,
  closeicon,
  downloads,
  analytics,
  helpsupport,
  payment,
  logout,
  help,
  emailadmin,
  subscriptionadmin,
  usermanagment,
  analyticsadmin,
  paymentadmin,
  cloudicon,
};

export default Svg;
