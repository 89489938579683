import React from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { styled } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// for file upload

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// for custom switch design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3e54a0",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#5a5959" : "#3e54a0",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function QrCodeGeneration() {
  // for delete modal
  const [openPublishedQrCode, setOpenPublishedQrCode] = React.useState(false);
  const handleOpenPublishedQrCode = () => setOpenPublishedQrCode(true);
  const handleClosePublishedQrCode = () => setOpenPublishedQrCode(false);

  // for page navigation
  const navigate = PageIndex.useNavigate();
  return (
    <>
      <Index.Box className="dashboard-content qr-code-generation-content">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              QR Code Generation
            </Index.Typography>
          </Index.Box>
        </Index.Box>

        <Index.Box className="qr-code-generation-section common-card">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
               
                  <Index.Box
                    display="grid"
                    className="display-row display-flex"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 8",
                        sm: "span 8",
                        md: "span 8",
                        lg: "span 8",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-details-auth">
                        <Index.Box className="input-box-auth">
                          <Index.Box className="form-group">
                            <Index.FormHelperText className="label-input-title">
                              Serial Number{" "}
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="PROJ12072023"
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 4",
                        sm: "span 4",
                        md: "span 4",
                        lg: "span 4",
                      }}
                      className="grid-column"
                    >
                    <Index.Box className="edit-main">
                    <Index.Button
                        title="Edit"
                        className="edit-icon-btn"
                        // onClick={() => {
                        //   navigate("/user/edit-project-details");
                        // }}
                      >
                        {" "}
                        <img
                          src={PageIndex.Svg.edit}
                          className="edit-view-icons"
                        />
                      </Index.Button>

                    </Index.Box>
                      
          
                  </Index.Box>
                  {/* <Index.Box className="input-box-auth">
                    <Index.Box className="form-group">
                      <Index.FormHelperText className="label-input-title">
                        Serial Number{" "}
                        <span className="mandatory-asterisk">*</span>
                      </Index.FormHelperText>
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="PROJ12072023"
                      />
                      
                    </Index.Box>
                    
                  </Index.Box> */}
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="input-details-auth">
                  <Index.Box className="input-box-auth">
                    <Index.FormHelperText className="label-input-title">
                      Add Serial No. in QR Code
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.Box className="switch-main">
                        <Index.Tooltip title="Add Serial No. in QR code">
                          <Index.FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                            className="switch-lable"
                          />
                        </Index.Tooltip>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="input-details-auth">
                  <Index.Box className="input-box-auth">
                    <Index.Box className="form-group radio-main">
                      <Index.FormHelperText className="label-input-title">
                        Shape of the QR Code{" "}
                        <span className="mandatory-asterisk">*</span>
                      </Index.FormHelperText>
                      <Index.RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className="radiogroup"
                      >
                        <Index.FormControlLabel
                          value="female"
                          control={<Index.Radio className="radio" />}
                          label="Rectangle"
                        />
                        <Index.FormControlLabel
                          value="male"
                          control={<Index.Radio className="radio" />}
                          label="Circle"
                        />
                      </Index.RadioGroup>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="input-details-auth">
                  <Index.Box className="input-box-auth">
                    <Index.Box className="form-group">
                      <Index.FormHelperText className="label-input-title">
                        Foreground Color
                      </Index.FormHelperText>
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        type="color"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="input-details-auth">
                  <Index.Box className="input-box-auth">
                    <Index.Box className="form-group">
                      <Index.FormHelperText className="label-input-title">
                        Background Color
                      </Index.FormHelperText>
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        type="color"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="input-details-auth">
                  <Index.Box className="input-box-auth">
                    <Index.Box className="form-group">
                      <Index.FormHelperText className="label-input-title">
                        Title
                      </Index.FormHelperText>
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Enter title"
                        type="Title"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="input-details-auth">
                  <Index.Box className="input-box-auth">
                    <Index.Box className="form-group">
                      <Index.FormHelperText className="label-input-title">
                        Start Date <span className="mandatory-asterisk">*</span>
                      </Index.FormHelperText>
                      <Index.Box className="date-picker-main analytic-date-picker-main">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker className="form-control" />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="input-details-auth">
                  <Index.Box className="input-box-auth">
                    <Index.Box className="form-group">
                      <Index.FormHelperText className="label-input-title">
                        End Date <span className="mandatory-asterisk">*</span>
                      </Index.FormHelperText>
                      {/* <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        type="date"
                      /> */}
                      <Index.Box className="date-picker-main analytic-date-picker-main">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker className="form-control" />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 7",
                  lg: "span 7",
                }}
                className="grid-column"
              >
                <Index.Box className="input-details-auth">
                  <Index.Box className="input-box-auth">
                    <Index.Box className="form-group">
                      <Index.FormHelperText className="label-input-title">
                        Logo
                      </Index.FormHelperText>
                      <Index.Box className="file-upload-btn-main">
                        <Index.Button
                          component="label"
                          variant="contained"
                          className="file-upload-btn"
                          title="Add Logo"
                        >
                          <img
                            src={PageIndex.Svg.plus}
                            className="file-upload-plus-icon"
                          />
                          <VisuallyHiddenInput type="file" />
                        </Index.Button>
                        {/* <Index.Typography className="choose-img-text">
                          Select Logo
                        </Index.Typography>{" "}
                        <Index.Box className="switch-main select-logo-switch-main">
                          <Index.FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                            className="switch-lable"
                          />
                        </Index.Box> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="user-btn-flex">
                  <PageIndex.PrimaryButton
                    className="create-user-btn comman-primary-btn"
                    // title="Genrate QR"
                    btnLabel="Generate QR"
                    onClick={() => {
                      handleOpenPublishedQrCode();
                    }}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <PageIndex.PublishedQrCode
        openPublishedQrCode={openPublishedQrCode}
        handleClosePublishedQrCode={handleClosePublishedQrCode}
      />
    </>
  );
}
