import { styled, alpha } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import PageIndex from "../../PageIndex";
import Index from "../../Index";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";


const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function MyApp() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
    <Index.Box className="dark-light-btn-main">
      {/* {theme.palette.mode} */}
      <Index.IconButton
        onClick={colorMode.toggleColorMode}
        color="inherit"
        className="dark-light-btn"
      >
        {theme.palette.mode === "dark" ? (
          <Index.Brightness7Icon />
        ) : (
          <Index.Brightness4Icon />
        )}
      </Index.IconButton>
    </Index.Box>
  );
}

export default function AdminHeader(props) {
  // for open in header profile menu
  
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const { adminData } = PageIndex.useSelector((state) => state.admin);
  const { darkMode } = PageIndex.useSelector((state) => state.user);
  const profile = adminData?.profile;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (path) => {
    setAnchorEl(null);
    // navigate(path);
  };
  const handleLogout = () => {
    handleClose("/admin/login");

    dispatch(PageIndex.adminLogout());
    localStorage.setItem("adminLogin", false);

  };
  const [mode, setMode] = React.useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );
  const StyledMenu = styled((props) => (
    <Index.Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 3,
      marginTop: theme.spacing(1),
      minWidth: 258,
      paddingTop: 30,
      paddingBottom: 0,
      paddingLeft: 23,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow: "0px 3px 6px #00000029",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <>
      <Index.Box
        className={`user-header-main ${
          props.open ? "active" : "user-header-deactive"
        }`}
      >
        <Index.Box className="user-header-left">
          <Index.Box className="user-header-logo-main">
            <img
              src={PageIndex.Png.logo}
              className="user-sidebar-logo mobile-show"
              alt="logo"
            />
            <Index.Button
              className="user-bergur-button"
              onClick={() => {
                props.setOpen(!props.open);
                document.body.classList[props.open ? "add" : "remove"](
                  "body-overflow"
                );
              }}
            >
              <img
                src={PageIndex.Svg.bergurmenu}
                className="bergurmenu-icon dark-to-light-img"
                alt="dashboard logo"
              ></img>
            </Index.Button>
          </Index.Box>
          <Index.Box className="user-header-right dark-light-btn-main">
            <Index.Button className="user-header-icon-box " title="Notification">
              <img
                src={PageIndex.Svg.notification}
                className="user-header-icon dark-to-light-img"
                alt="dashboard logo"
              ></img>
            </Index.Button>
            <Index.Button  className="user-header-icon-box dark-light-btn" onClick={props.toggleDarkMode} title="Switch Mode">
                {darkMode ? (
                  <Index.Brightness7Icon  title="Switch to Light Mode"/>
                ) : (
                  <Index.Brightness4Icon  title="Switch to Dark Mode"/>

                )}
              </Index.Button>
            {/* <Index.Button className="user-header-icon-box">
              <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                  <MyApp />
                </ThemeProvider>
              </ColorModeContext.Provider>
            </Index.Button> */}
            {/* <Index.Button className='user-header-icon-box'>
              <img src={PageIndex.Png.usericon} className="user-header-profile-icon" alt='dashboard bell icon'></img>
            </Index.Button> */}
            <Index.Box className="user-header-drop-main">

              <Index.Button
                className="drop-header-btn"
                title="Profile"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Index.Box className="flex-drop-main">
                <img
                    src={
                      profile
                        ? profile instanceof Blob
                          ? URL.createObjectURL(profile)
                           : `${process.env.REACT_APP_API_BASE_IMAGE_URL}${profile}`
                        : PageIndex.Png.usericon
                    }
                    className="user-header-profile-icon"
                    alt="dashboard bell icon"
                  ></img>
                  <Index.Box className="title-user-drop">
                    {/* <Index.Typography variant="h5" component="h5" className='user-header-drop'>user</Index.Typography> */}
                  </Index.Box>
                </Index.Box>
              </Index.Button>
            </Index.Box>
            <Index.Menu
              className={darkMode?"drop-header-menu dark-theme":"drop-header-menu"}
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Index.MenuItem
                title="My Profile"
               onClick={() => {
                    navigate("/admin/edit-profile");
                    handleClose();
                  }}
      
                className="drop-header-menuitem"
              >
                {" "}
                <img
                  src={PageIndex.Svg.profilegrey}
                  className="drop-header"
                />{" "}
                My Profile
              </Index.MenuItem>
              <Index.MenuItem
                title="Change Password"
                onClick={() => {
                    navigate("/admin/change-password");
                    handleClose();
                  }}
               
                className="drop-header-menuitem"
              >
                {" "}
                <img
                  src={PageIndex.Svg.profilegrey}
                  className="drop-header"
                />{" "}
                Change Password
              </Index.MenuItem>
              <Index.MenuItem
                title="Settings"
                onClick={() => {
                  navigate("/admin/account-setting")
                  handleClose();
                }}
                className="drop-header-menuitem"
              >
                {" "}
                <img src={PageIndex.Svg.settings} className="drop-header" />
                 Settings
              </Index.MenuItem>
              <Index.MenuItem
                title="Log Out"
                onClick={handleLogout}
                className="drop-header-menuitem"
              >
                {" "}
                <img src={PageIndex.Svg.edit} className="drop-header" /> Log
                Out
              </Index.MenuItem>
            </Index.Menu>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
