import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const columns = [
  { field: "id", headerName: "Sr.No.",},
  { field: "email", headerName: "User Email ID",},
  { field: "title", headerName: "Date",},
  { field: "tags", headerName: "Project Tags",},
  {
    field: "publishedate",
    headerName: "Date of Publish",
  },
  {
    field: "isQRgenerated",
    headerName: "Is QR Generated",
  },
  {
    field: "serialNumber",
    headerName: "Serial Number",
  },
  {
    field: "QRCode",
    headerName: "Project Thumbnail",
    disableColumnMenu:true, sortable:false,
    renderCell: (params) => (
      <img
        src={PageIndex.Svg.thumbnail}
        className="table-thumbnail-icons"
        alt="Thumbnail"
      />
    ),
  },
  {
    field: "StartDate",
    headerName: "Start Date",
  },
  {
    field: "EndDate",
    headerName: "End Date",
  },
];
function PublishedCreateData(
  email,
  title,
  tags,
  publishedate,
  isQRgenerated,
  serialNumber,
  QRCode,
  StartDate,
  EndDate,
  id
) {
  return {
    email,
    title,
    tags,
    publishedate,
    isQRgenerated,
    serialNumber,
    QRCode,
    StartDate,
    EndDate,
    id,
  };
}

const publishedtablerows = [
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    PageIndex.Svg.thumbnail,
    "02/12/2022",
    "02/12/2013",
    1
  ),

  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    2
  ),

  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    3
  ),

  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    4
  ),

  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    5
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    6
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    7
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    8
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    9
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    10
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    11
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    12
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    13
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    14
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    15
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    16
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    17
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    18
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    19
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    20
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    21
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    22
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    23
  ),
  PublishedCreateData(
    "email@gmail.com",
    "Projtitle",
    "Projtags",
    "12/12/2012",
    "yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "02/12/2022",
    "02/12/2013",
    24
  ),
];

export default function AdminProjectReport() {
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState(publishedtablerows.length);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Index.Box className="dashboard-content admin-project-report-content">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              Project Reports
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="userlist-inner-btn-flex">
                <Index.Box className="print-icon-box" title="Print">
                  <img
                    src={PageIndex.Svg.printicon}
                    className="print-icons"
                    alt=""
                  />
                </Index.Box>
              <PageIndex.PrimaryButton
                className="adduser-btn comman-primary-btn"
                btnLabel="Export"
                title="Export"
              />
            </Index.Box>
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box user-search-box-inner">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img
                    src={PageIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="menu-report-section common-card">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="page-table-main admin-projects-report-table">
                  <Index.Box className="data-table-main">
                    <Index.DataGrid
                      rows={publishedtablerows}
                      // columns={columns}
                      columns={columns.map((col) => ({
                        ...col,
                        renderHeader: (params) => <PageIndex.CustomHeader {...params} column={col} />,
                      }))}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            page: page,
                            pageSize: rowsPerPage,
                          },
                        },
                      }}
                      handleChangePage={handleChangePage}
                      pageSizeOptions={[15, 20, 25]}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
