import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { styled } from "@mui/material/styles";
import { Field, useField } from "formik";
import cryptoRandomString from "crypto-random-string";
import ReCAPTCHA from "react-google-recaptcha";
import MuiPhoneInput from "material-ui-phone-number";
import { CuntryList } from "./CountryList";
import { validateMobileNo } from "../../../../validation/UserValidation";

// for custom checkbox design

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  border: "1px solid #114A65",
  width: 12,
  height: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  const [field, meta] = useField(props.name);
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      {...field}
      {...props.restProps}
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export default function Register() {

  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  // for password field
  const [showPassword, setShowPassword] = useState(false);
  const [countryChange, setCountryChange] = useState("");
  const [showConfirmPassword, setShowconfirmPassword] = useState(false);
  const [suggestedPassword, setSuggestedPassword] = useState("");
  const [showSuggestedPassword, setShowSuggestedPassword] = useState(false);
  const [mobileError, setMobileError] = useState("");
  const handleKeyPress = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  const generateRandomPassword = () => {
    const minLength = 8;
    const maxLength = 15;
    const numChars =
      Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;

    const captial = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const small = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const specialChars = "!@#$%^&*";

    let randomPassword = "";

    for (let i = 0; i < numChars; i++) {
      let charSet;
      if (i % 4 === 0) {
        charSet = captial;
      } else if (i % 4 === 1) {
        charSet = numbers;
      } else if (i % 4 === 2) {
        charSet = specialChars;
      } else {
        charSet = small;
      }
      const randomChar = charSet[Math.floor(Math.random() * charSet.length)];
      randomPassword += randomChar;
    }

    // const randomDigit = cryptoRandomString({ length: 3, characters: small });
    // randomPassword += randomDigit;

    randomPassword = shuffleString(randomPassword);

    return randomPassword;
  };

  const shuffleString = (str) => {
    const array = str.split("");
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array.join("");
  };

  const handleGeneratePassword = () => {
    const randomPassword = generateRandomPassword();
    setSuggestedPassword(randomPassword);
    setShowSuggestedPassword(true);
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowconfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    deaultCountryCode: "in",
    countryCode: "",
    agreeToTerms: false,
    isVerifyCaptcha: false,
    isSubmit: false,
  };
  const handleSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    if (mobileError == "") {
      const urlencoded = new URLSearchParams();

      urlencoded.append("firstName", values.firstName);
      urlencoded.append("lastName", values.lastName);
      urlencoded.append("email", values.email);

      urlencoded.append("phoneNumber", values.phoneNumber);
      urlencoded.append("countryCode", values.countryCode);
      urlencoded.append("password", values.password);
      urlencoded.append("addressLine1", values.addressLine1);
      urlencoded.append("addressLine2", values.addressLine2);
      urlencoded.append("city", values.city);
      urlencoded.append("state", values.state);
      urlencoded.append("country", values.country);
      urlencoded.append("zip", values.zip);

      dispatch(PageIndex.checkUser(urlencoded)).then((res) => {
        if (res?.payload?.status == 200) {
          const checkData = res?.payload?.data;
          console.log(checkData,174)
          setTimeout(() => {
            navigate("/user/otp", {
              state: {
                type: "register",
                checkData: checkData,
                email: values.email,
                data: values,
              },
            });
          }, 1000);
        } else {
          setFieldValue("isSubmit", false);
        }
      });
    }
  };
  const onCaptchaChange = (value, setFieldValue) => {
    if (value) {
      setFieldValue("isVerifyCaptcha", true);
    } else {
      setFieldValue("isVerifyCaptcha", false);
    }
  };
  const handleMobileChange = (e, data, setFieldValue) => {
    const inputValue = e;

    const trimmedMobileNumber = inputValue
      .replace(/^(\+91[\-\s]?)?[0]?(91)?/, "")
      .replace(/-/g, "");

    setFieldValue("phoneNumber", trimmedMobileNumber);
  };
  const handleZipChange = (event, setFieldValue) => {
    // const inputValue = event.target.value;
    const inputValue = event.target.value.replace(/\W/g, "").substring(0, 8);
    if (inputValue.length <= 8) {
      setFieldValue("zip", inputValue);
    }
  };

  return (
    <>
      <Index.Box className="login-user-screen register-page">
        <Index.Box className="auth-user-row">
          <Index.Box className="auth-row-col-left">
            <Index.Box className="dark-gray">
              <Index.Box className="auth-bg-content"></Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="auth-row-col-right">
            <Index.Box className="light-gray">
              <Index.Box className="auth-page-bg register-content">
                <Index.Box className="auth-bg-pd">
                  <Index.Box className="auth-title-content">
                    <Index.Typography
                      component="h6"
                      variant="h6"
                      className="signin-title"
                    >
                      Register
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="signin-details-title"
                    >
                      Please enter your credentials to Register!
                    </Index.Typography>
                  </Index.Box>
                  <PageIndex.Formik
                    initialValues={initialValues}
                    validationSchema={
                      PageIndex.userRegistrationValidationSchema
                    }
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }) => (
                      <PageIndex.Form>
                        <Index.Box className="auth-signin-details">
                          <Index.Box className="scroll-content-auth">
                            <Index.Box className="grid-row">
                              <Index.Box
                                sx={{ width: 1 }}
                                className="grid-main"
                              >
                                <Index.Box
                                  display="grid"
                                  gridTemplateColumns="repeat(12, 1fr)"
                                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                >
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 6",
                                      lg: "span 6",
                                      xl: "span 6",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="input-details-auth">
                                      <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                          <Index.FormHelperText className="label-input-title">
                                            First Name{" "}
                                            <span className="mandatory-asterisk">
                                              *
                                            </span>
                                          </Index.FormHelperText>
                                          <Index.TextField
                                            fullWidth
                                            name="firstName"
                                            id="fullWidth"
                                            value={values.firstName}
                                            onChange={(e) => {
                                              // const newValue =
                                              //   e.target.value.replace(
                                              //     /\s/g,
                                              //     ""
                                              //   );
                                              const newValue =
                                                e.target.value.replace(
                                                  /[^A-Za-z]/g,
                                                  ""
                                                );

                                              setFieldValue(
                                                "firstName",
                                                newValue
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            className="form-control"
                                            placeholder="First Name"
                                            // onKeyPress={handleKeyPress}
                                          />
                                          {errors.firstName &&
                                            touched.firstName && (
                                              <p className="error-text">
                                                {errors.firstName}
                                              </p>
                                            )}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 6",
                                      lg: "span 6",
                                      xl: "span 6",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="input-details-auth">
                                      <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                          <Index.FormHelperText className="label-input-title">
                                            Last Name{" "}
                                            <span className="mandatory-asterisk">
                                              *
                                            </span>
                                          </Index.FormHelperText>
                                          <Index.TextField
                                            fullWidth
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={(e) => {
                                              // const newValue =
                                              //   e.target.value.replace(
                                              //     /\s/g,
                                              //     ""
                                              //   );
                                              const newValue =
                                                e.target.value.replace(
                                                  /[^A-Za-z]/g,
                                                  ""
                                                );

                                              setFieldValue(
                                                "lastName",
                                                newValue
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            id="fullWidth"
                                            className="form-control"
                                            placeholder="Last Name"
                                            // onKeyPress={handleKeyPress}
                                          />
                                          {errors.lastName &&
                                            touched.lastName && (
                                              <p className="error-text">
                                                {errors.lastName}
                                              </p>
                                            )}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 6",
                                      lg: "span 6",
                                      xl: "span 6",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="input-details-auth">
                                      <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                          <Index.FormHelperText className="label-input-title">
                                            Email{" "}
                                            <span className="mandatory-asterisk">
                                              *
                                            </span>
                                          </Index.FormHelperText>
                                          <Index.TextField
                                            fullWidth
                                            id="fullWidth"
                                            name="email"
                                            value={values.email}
                                            inputProps={{ maxLength: 254 }}
                                            onChange={(e) => {
                                              const { value } = e.target;
                                              const atIndex =
                                                value.indexOf("@");

                                              if (
                                                atIndex !== -1 &&
                                                atIndex <= 64
                                              ) {
                                                const newValue = value.replace(
                                                  /\s/g,
                                                  ""
                                                );

                                                const limitedValue =
                                                  newValue.slice(0, 254);
                                                setFieldValue(
                                                  "email",
                                                  limitedValue
                                                );
                                              } else if (
                                                atIndex === -1 &&
                                                value.length <= 64
                                              ) {
                                                const newValue = value.replace(
                                                  /\s/g,
                                                  ""
                                                );

                                                const limitedValue =
                                                  newValue.slice(0, 64);
                                                setFieldValue(
                                                  "email",
                                                  limitedValue
                                                );
                                              }
                                            }}
                                            // onChange={(e) => {
                                            //   const newValue =
                                            //     e.target.value.replace(
                                            //       /\s/g,
                                            //       ""
                                            //     );
                                            //   setFieldValue("email", newValue);
                                            // }}
                                            onBlur={handleBlur}
                                            className="form-control"
                                            placeholder="Email"
                                          />
                                           
                                          {errors.email && touched.email && (
                                            <p className="error-text">
                                              {errors.email}
                                            </p>
                                          )}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                 
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 6",
                                      lg: "span 6",
                                      xl: "span 6",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="input-details-auth">
                                      <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                          <Index.FormHelperText className="label-input-title">
                                            Number{" "}
                                            <span className="mandatory-asterisk">
                                              *
                                            </span>
                                          </Index.FormHelperText>
                                          <MuiPhoneInput
                                            fullWidth
                                            name="phoneNumber"
                                            id="fullWidth"
                                            value={values?.phoneNumber}
                                            defaultCountry={
                                              values?.deaultCountryCode ? values?.deaultCountryCode :"in"
                                            }
                                            // defaultCountry={'in'}
                                            // onChange={(e, countryDetails) => {
                                            //   handleChange(e);
                                            //   handleMobileChange(
                                            //     e,
                                            //     countryDetails,
                                            //     setFieldValue
                                            //   );
                                            //   setFieldValue('countryCode', countryData.dialCode);
                                            //   setFieldValue('country', countryData.name);
                                            //   setMobileError(validateMobileNo(value, countryData.dialCode))
                                            // }}

                                            // defaultCountry={'in'}
                                            // value={values?.mobileNumber}
                                            onChange={(value, countryData) => {
                                            
                                              if (
                                                value.length > 0 &&
                                                value.length !== 0
                                              ) {
                                                const phoneNumberDigits =
                                                  value.replace(/\D/g, "");
                                                let countryCode =
                                                  countryData?.dialCode;
                                                setFieldValue(
                                                  "phoneNumber",
                                                  +phoneNumberDigits.slice(
                                                    countryCode?.length,
                                                    phoneNumberDigits?.length
                                                  )
                                                );
                                                // const paddedMobileNumber = phoneNumberDigits.padStart(10, '0').slice(-10); // Pad and take the last 10 digits
                                                setFieldValue(
                                                  "countryCode",
                                                  countryData?.dialCode
                                                );
                                                setFieldValue(
                                                  "country",
                                                  countryData?.name
                                                );
                                                // setFieldValue('mobileNumber', parseInt(paddedMobileNumber, 10));
                                              }
                                              setMobileError(
                                                validateMobileNo(
                                                  value,
                                                  countryData?.dialCode
                                                )
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            className="form-control country-form-control"
                                            placeholder="Number"
                                          />
 {(errors.phoneNumber && 
                                    touched.phoneNumber ) || mobileError && (
                                      <p className="error-text">
                                      {mobileError ||
                                                errors.phoneNumber}
                                      </p>
                                    )}
                                          {/* {touched.phoneNumber && (
                                            <p className="error-text">
                                              {mobileError ||
                                                errors.phoneNumber}
                                            </p>
                                          )} */}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 6",
                                      lg: "span 6",
                                      xl: "span 6",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="input-details-auth">
                                      <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                          <Index.FormHelperText className="label-input-title">
                                            Password{" "}
                                            <span className="mandatory-asterisk">
                                              *
                                            </span>
                                          </Index.FormHelperText>
                                          <Index.Box className="input-details-main">
                                            <Index.Box className="input-box cm-search-box">
                                              <Index.Box className="form-group">
                                                <Index.Box className="form-group pass_group_main">
                                                  <Index.OutlinedInput
                                                    className="form-control custom_form_control"
                                                    id="outlined-adornment-password"
                                                    placeholder="Enter Password"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={(e) => {
                                                      const newValue =
                                                        e.target.value.replace(
                                                          /\s/g,
                                                          ""
                                                        );
                                                      setFieldValue(
                                                        "password",
                                                        newValue
                                                      );
                                                    }}
                                                    onBlur={handleBlur}
                                                    type={
                                                      showPassword
                                                        ? "text"
                                                        : "password"
                                                    }
                                                    endAdornment={
                                                      <Index.InputAdornment
                                                        position="end"
                                                        className="pass_position"
                                                      >
                                                        <Index.IconButton
                                                          className="icon_end_icon"
                                                          // aria-label="toggle password visibility"
                                                          onClick={
                                                            handleClickShowPassword
                                                          }
                                                          onMouseDown={
                                                            handleMouseDownPassword
                                                          }
                                                          edge="end"
                                                        >
                                                          {showPassword ? (
                                                            <Index.VisibilityOff />
                                                          ) : (
                                                            <Index.Visibility />
                                                          )}
                                                        </Index.IconButton>
                                                      </Index.InputAdornment>
                                                    }
                                                    label="Password"
                                                  />
                                                  <Index.Box className="generate-password">
                                                    <Index.Button
                                                      type="button"
                                                      size="small"
                                                      onClick={
                                                        handleGeneratePassword
                                                      }
                                                      className="generate-button"
                                                    >
                                                      Generate Password
                                                    </Index.Button>
                                                    {showSuggestedPassword && (
                                                      <Index.Box className="suggested-password-box">
                                                        <Index.Typography className="suggested-password">
                                                          {" "}
                                                          {suggestedPassword}
                                                        </Index.Typography>
                                                      </Index.Box>
                                                    )}
                                                  </Index.Box>
                                                  {errors.password &&
                                                    touched.password && (
                                                      <p className="error-text">
                                                        {errors.password}
                                                      </p>
                                                    )}
                                                </Index.Box>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 6",
                                      lg: "span 6",
                                      xl: "span 6",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="input-details-auth">
                                      <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                          <Index.FormHelperText className="label-input-title">
                                            Confirm Password{" "}
                                            <span className="mandatory-asterisk">
                                              *
                                            </span>
                                          </Index.FormHelperText>
                                          <Index.Box className="input-details-main">
                                            <Index.Box className="input-box cm-search-box">
                                              <Index.Box className="form-group">
                                                <Index.Box className="form-group pass_group_main">
                                                  <Index.OutlinedInput
                                                    className="form-control custom_form_control"
                                                    id="outlined-adornment-password"
                                                    placeholder="Enter Confirm password"
                                                    name="confirmPassword"
                                                    value={
                                                      values.confirmPassword
                                                    }
                                                    onChange={(e) => {
                                                      const newValue =
                                                        e.target.value.replace(
                                                          /\s/g,
                                                          ""
                                                        );
                                                      setFieldValue(
                                                        "confirmPassword",
                                                        newValue
                                                      );
                                                    }}
                                                    onBlur={handleBlur}
                                                    type={
                                                      showConfirmPassword
                                                        ? "text"
                                                        : "password"
                                                    }
                                                    endAdornment={
                                                      <Index.InputAdornment
                                                        position="end"
                                                        className="pass_position"
                                                      >
                                                        <Index.IconButton
                                                          className="icon_end_icon"
                                                          // aria-label="toggle password visibility"
                                                          onClick={
                                                            handleClickShowConfirmPassword
                                                          }
                                                          onMouseDown={
                                                            handleMouseDownPassword
                                                          }
                                                          edge="end"
                                                        >
                                                          {showConfirmPassword ? (
                                                            <Index.VisibilityOff />
                                                          ) : (
                                                            <Index.Visibility />
                                                          )}
                                                        </Index.IconButton>
                                                      </Index.InputAdornment>
                                                    }
                                                    label="Confirm Password"
                                                  />
                                                  {errors.confirmPassword &&
                                                    touched.confirmPassword && (
                                                      <p className="error-text">
                                                        {errors.confirmPassword}
                                                      </p>
                                                    )}
                                                </Index.Box>
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 6",
                                      lg: "span 6",
                                      xl: "span 6",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="input-details-auth">
                                      <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                          <Index.FormHelperText className="label-input-title">
                                            Address Line 1{" "}
                                            <span className="mandatory-asterisk">
                                              *
                                            </span>
                                          </Index.FormHelperText>
                                          <Index.TextField
                                            fullWidth
                                            id="fullWidth"
                                            name="addressLine1"
                                            value={values.addressLine1}
                                            onChange={(e) => {
                                              const newValue = e.target.value
                                                .replace(/^\s+/, "")
                                                .replace(/\s\s+/g, " ");
                                              setFieldValue(
                                                "addressLine1",
                                                newValue
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            className="form-control"
                                            placeholder="Address Line 1"
                                          />
                                          {errors.addressLine1 &&
                                            touched.addressLine1 && (
                                              <p className="error-text">
                                                {errors.addressLine1}
                                              </p>
                                            )}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 6",
                                      lg: "span 6",
                                      xl: "span 6",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="input-details-auth">
                                      <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                          <Index.FormHelperText className="label-input-title">
                                            Address Line 2
                                          </Index.FormHelperText>
                                          <Index.TextField
                                            fullWidth
                                            id="fullWidth"
                                            name="addressLine2"
                                            value={values.addressLine2}
                                            onChange={(e) => {
                                              const newValue = e.target.value
                                                .replace(/^\s+/, "")
                                                .replace(/\s\s+/g, " ");
                                              setFieldValue(
                                                "addressLine2",
                                                newValue
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            className="form-control"
                                            placeholder="Address Line 2"
                                          />
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 6",
                                      lg: "span 6",
                                      xl: "span 6",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="input-details-auth">
                                      <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                          <Index.FormHelperText className="label-input-title">
                                            City{" "}
                                            <span className="mandatory-asterisk">
                                              *
                                            </span>
                                          </Index.FormHelperText>
                                          <Index.TextField
                                            fullWidth
                                            id="fullWidth"
                                            name="city"
                                            value={values.city}
                                            onChange={(e) => {
                                              const newValue = e.target.value
                                                .replace(/^\s+/, "")
                                                .replace(/\s\s+/g, " ");
                                              setFieldValue("city", newValue);
                                            }}
                                            onBlur={handleBlur}
                                            className="form-control"
                                            placeholder="City"
                                          />
                                          {errors.city && touched.city && (
                                            <p className="error-text">
                                              {errors.city}
                                            </p>
                                          )}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 6",
                                      lg: "span 6",
                                      xl: "span 6",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="input-details-auth">
                                      <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                          <Index.FormHelperText className="label-input-title">
                                            State{" "}
                                            <span className="mandatory-asterisk">
                                              *
                                            </span>
                                          </Index.FormHelperText>
                                          <Index.TextField
                                            fullWidth
                                            id="fullWidth"
                                            name="state"
                                            value={values.state}
                                            onChange={(e) => {
                                              const newValue = e.target.value
                                                .replace(/^\s+/, "")
                                                .replace(/\s\s+/g, " ");
                                              setFieldValue("state", newValue);
                                            }}
                                            onBlur={handleBlur}
                                            className="form-control"
                                            placeholder="State"
                                          />
                                          {errors.state && touched.state && (
                                            <p className="error-text">
                                              {errors.state}
                                            </p>
                                          )}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                
                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 6",
                                      lg: "span 6",
                                      xl: "span 6",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="input-details-auth">
                                      <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                          <Index.FormHelperText className="label-input-title">
                                            Country{" "}
                                            <span className="mandatory-asterisk">
                                              *
                                            </span>
                                          </Index.FormHelperText>
                                          <Index.Box className="dropdown-box">
                                            <Index.FormControl className="form-control">
                                              <Index.Select
                                                className="dropdown-select "
                                                value={values.country}
                                                onBlur={handleBlur}
                                                name="country"
                                                // onChange={handleChange}
                                                onChange={(e) => {
                                                  const selectedCountry =
                                                    e.target.value;
                                                  const countryObj =
                                                    CuntryList.find(
                                                      (country) =>
                                                        country.iso.toLowerCase() ===
                                                        selectedCountry.toLowerCase()
                                                    );
                                                 
                                                  if (countryObj) {
                                                    setFieldValue(
                                                      "country",
                                                      countryObj?.name
                                                    );
                                                    setFieldValue(
                                                      "countryCode",
                                                      countryObj?.code
                                                    );
                                                    setFieldValue(
                                                      "deaultCountryCode",
                                                      countryObj?.iso.toLowerCase()
                                                    );
                                                    setMobileError('Please enter a valid Number')
                                                  } else {
                                                    setFieldValue(
                                                      "country",
                                                      values?.country
                                                    );
                                                  }
                                                }}
                                                displayEmpty
                                                inputProps={{
                                                  "aria-label": "Without label",
                                                }}
                                              >
                                                {/* <Index.MenuItem
                                                  value=""
                                                  className="menuitem"
                                                  disabled
                                                >
                                                  Select Country
                                                </Index.MenuItem> */}
                                                <Index.MenuItem
                                                  value={
                                                    values?.country
                                                      ? values?.country
                                                      : ""
                                                  }
                                                  className="menuitem"
                                                  disabled
                                                >
                                                  {values?.country
                                                    ? values?.country
                                                    : "Select Country"}
                                                </Index.MenuItem>
                                                {CuntryList?.map((ele) => {
                                                  return (
                                                    <Index.MenuItem
                                                      className="menuitem"
                                                      key={ele?.code}
                                                      value={ele?.iso}
                                                    >
                                                      {ele?.name}
                                                    </Index.MenuItem>
                                                  );
                                                })}
                                              </Index.Select>
                                              {errors.country &&
                                                touched.country && (
                                                  <p className="error-text">
                                                    {errors.country}
                                                  </p>
                                                )}
                                            </Index.FormControl>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>

                                  <Index.Box
                                    gridColumn={{
                                      xs: "span 12",
                                      sm: "span 6",
                                      md: "span 6",
                                      lg: "span 6",
                                      xl: "span 6",
                                    }}
                                    className="grid-column"
                                  >
                                    <Index.Box className="input-details-auth">
                                      <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                          <Index.FormHelperText className="label-input-title">
                                            Zip{" "}
                                            <span className="mandatory-asterisk">
                                              *
                                            </span>
                                          </Index.FormHelperText>
                                          <Index.TextField
                                            fullWidth
                                            id="fullWidth"
                                            value={values.zip}
                                            name="zip"
                                            onChange={(event) => {
                                              handleZipChange(
                                                event,
                                                setFieldValue
                                              );
                                            }}
                                            onBlur={handleBlur}
                                            type="text"
                                            className="form-control"
                                            placeholder="Zip"
                                          />
                                          {errors.zip && touched.zip && (
                                            <p className="error-text">
                                              {errors.zip}
                                            </p>
                                          )}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box className="captach-main  admin-captach-main login-captach-main">
                            <ReCAPTCHA
                              sitekey={
                                process.env.REACT_APP_RE_CAPTCHA_SITE_KEY
                              }
                              onChange={(e) => {
                                onCaptchaChange(e, setFieldValue);
                              }}
                              className="captach-checkbox"
                            />
                          </Index.Box>

                          <Index.Box className="checkbox-main">
                            <BpCheckbox name="agreeToTerms" />
                            {/* <Field
                              type="checkbox"
                              name="agreeToTerms"
                              className="checkbox"
                            /> */}
                            <Index.Typography className="checkbox-lable">
                              Agree to terms and conditions
                            </Index.Typography>
                          </Index.Box>
                          {errors.agreeToTerms && touched.agreeToTerms && (
                            <p className="error-text">{errors.agreeToTerms}</p>
                          )}
                          <Index.Box className="condition-content">
                            <Index.Typography className="condition-text">
                              {" "}
                              Already have an account?{" "}
                              <span className="condition-span">
                                <Index.Link className="condition-span" to="/">
                                  Log In
                                </Index.Link>
                              </span>
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="auth-primary-btn">
                            <PageIndex.PrimaryButton
                              btnLabel="Register"
                              type="submit"
                              disabled={
                                values?.isSubmit ||
                                !values?.isVerifyCaptcha ||
                                mobileError
                              }
                              startIcon={
                                values?.isSubmit && (
                                  <Index.CircularProgress
                                    size={15}
                                    color="inherit"
                                  />
                                )
                              }
                              className={
                                !values?.isVerifyCaptcha
                                  ? "comman-primary-btn register-btn disabled-login-button"
                                  : "comman-primary-btn register-btn"
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </PageIndex.Form>
                    )}
                  </PageIndex.Formik>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
