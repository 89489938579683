import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import PropTypes from "prop-types";
import DeleteModal from "../../../../component/common/deletemodal/DeleteModal";

// for tabs design

function createData2(
  S_no,
  project_title,
  tags,
  created_at,
  published_at,
  project_thumbnail,
  serial_number,
  start_date,
  end_date,
  actions
) {
  return {
    S_no,
    project_title,
    tags,
    created_at,
    published_at,
    project_thumbnail,
    serial_number,
    start_date,
    end_date,
    actions,
  };
}

function createData(
  S_no,
  project_title,
  tags,
  project_thumbnail,
  created_at,
  updated_at,
  actions
) {
  return {
    S_no,
    project_title,
    tags,
    project_thumbnail,
    created_at,
    updated_at,
    actions,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// for published table data

function PublishedCreateData(
  Sno,
  ProjectTitle,
  Tags,
  Createdat,
  Publishedat,
  Thumbnail,
  SerialNumber,
  StartDate,
  EndDate,
  Actions
) {
  return {
    Sno,
    ProjectTitle,
    Tags,
    Createdat,
    Publishedat,
    Thumbnail,
    SerialNumber,
    StartDate,
    EndDate,
    Actions,
  };
}

// for Draft table data

function DraftcreateData(
  Sno,
  ProjectTitle,
  Tags,
  ProjectThumbnail,
  Createdat,
  Updatedat,
  Actions
) {
  return {
    Sno,
    ProjectTitle,
    Tags,
    ProjectThumbnail,
    Createdat,
    Updatedat,
    Actions,
  };
}

export default function ProjectDirectory() {
  // for page navigatation
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  let type = location?.state;

  // for published table data
  const publishedtablerows = [
    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),
    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
          title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),
    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),
    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),
    PublishedCreateData(
      "01",
      "Proj01",
      "Tag1",
      "12-12-2012",
      "12-12-2012",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "QR1",
      "12-02-2022",
      "22-02-2013",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/published-and-qr-generated");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
          <Index.Button
           title="Analytic"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/analytics");
            }}
          >
            <img
              src={PageIndex.Png.analyticView}
              className="table-analytic-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),
  ];

  // for Draft table data
  const drafttablerows = [
    DraftcreateData(
      "01",
      "Project Title",
      "Tag1",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "12-12-2012",
      "12-12-2012",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/project-details");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" />
          </Index.Button>
          <Index.Button
           title="Edit"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/edit-project-details");
            }}
          >
            {" "}
            <img src={PageIndex.Svg.edit} className="table-view-icons" />
          </Index.Button>
          <Index.Button
           title="Delete"
            className="table-icon-btn"
            onClick={() => {
              handleOpenDeleteModal();
            }}
          >
            <img
              src={PageIndex.Svg.trash}
              className="table-view-icons"
              alt=""
            />
          </Index.Button>{" "}
      </Index.Box>
    ),

    DraftcreateData(
      "01",
      "Project Title",
      "Tag1",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "12-12-2012",
      "12-12-2012",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/project-details");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" />
          </Index.Button>
          <Index.Button
           title="Edit"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/edit-project-details");
            }}
          >
            {" "}
            <img src={PageIndex.Svg.edit} className="table-view-icons" />
          </Index.Button>
          <Index.Button
           title="Delete"
            className="table-icon-btn"
            onClick={() => {
              handleOpenDeleteModal();
            }}
          >
            <img
              src={PageIndex.Svg.trash}
              className="table-view-icons"
              alt=""
            />
          </Index.Button>{" "}
      </Index.Box>
    ),

    DraftcreateData(
      "01",
      "Project Title",
      "Tag1",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "12-12-2012",
      "12-12-2012",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/project-details");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" />
          </Index.Button>
          <Index.Button
           title="Edit"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/edit-project-details");
            }}
          >
            {" "}
            <img src={PageIndex.Svg.edit} className="table-view-icons" />
          </Index.Button>
          <Index.Button
           title="Delete"
            className="table-icon-btn"
            onClick={() => {
              handleOpenDeleteModal();
            }}
          >
            <img
              src={PageIndex.Svg.trash}
              className="table-view-icons"
              alt=""
            />
          </Index.Button>
      </Index.Box>
    ),

    DraftcreateData(
      "01",
      "Project Title",
      "Tag1",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "12-12-2012",
      "12-12-2012",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/project-details");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" />
          </Index.Button>
          <Index.Button
           title="Edit"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/edit-project-details");
            }}
          >
            {" "}
            <img src={PageIndex.Svg.edit} className="table-view-icons" />
          </Index.Button>
          <Index.Button
           title="Delete"
            className="table-icon-btn"
            onClick={() => {
              handleOpenDeleteModal();
            }}
          >
            <img
              src={PageIndex.Svg.trash}
              className="table-view-icons"
              alt=""
            />
          </Index.Button>{" "}
      </Index.Box>
    ),

    DraftcreateData(
      "01",
      "Project Title",
      "Tag1",
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "12-12-2012",
      "12-12-2012",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/project-details");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" />
          </Index.Button>
          <Index.Button
           title="Edit"
            className="table-icon-btn"
            onClick={() => {
              navigate("/user/edit-project-details");
            }}
          >
            {" "}
            <img src={PageIndex.Svg.edit} className="table-view-icons" />
          </Index.Button>
          <Index.Button
           title="Delete"
            className="table-icon-btn"
            onClick={() => {
              handleOpenDeleteModal();
            }}
          >
            <img
              src={PageIndex.Svg.trash}
              className="table-view-icons"
              alt=""
            />
          </Index.Button>{" "}
      </Index.Box>
    ),
  ];

  // draft column and row
  const columns = [
    { field: "S_no", headerName: "Sr.No." },
    { field: "project_title", headerName: "Project Title" },
    { field: "tags", headerName: "Tags" },
    // { field: "project_thumbnail", headerName: "Project Thumbnail", width: 20 },
    {
      field: "project_thumbnail",
      headerName: "Project Thumbnail",
      disableColumnMenu:true, sortable:false,
      renderCell: (params) => (
        <img
          src={PageIndex.Svg.thumbnail}
          className="table-thumbnail-icons"
          alt="Thumbnail"
        />
      ),
    },
    {
      field: "created_at",
      headerName: "Created At",
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      // type: "number",
    },
    // {
    //   field: "actions",
    //   headerName: "actions",
    //   width: 10,
    //   type: "number",
    // },
    {
      field: "action",
      headerName: "Actions",
      disableColumnMenu:true, sortable:false,
      renderCell: (params) => (
        <Index.Box className="table-icon-flex">
            <Index.Button
            title="View"
              className="table-icon-btn"
              onClick={() => {
                navigate("/user/project-details");
              }}
            >
              <img
                src={PageIndex.Svg.view}
                className="table-view-icons"
                alt=""
              />
            </Index.Button>
            <Index.Button
             title="Edit"
              className="table-icon-btn"
              onClick={() => {
                navigate("/user/edit-project-details");
              }}
            >
              <img
                src={PageIndex.Svg.edit}
                alt=""
                className="table-view-icons"
              />
            </Index.Button>
            <Index.Button
             title="Delete"
              className="table-icon-btn"
              onClick={() => {
                handleOpenDeleteModal();
              }}
            >
              <img
                src={PageIndex.Svg.trash}
                className="table-view-icons"
                alt=""
              />
            </Index.Button>
        </Index.Box>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      S_no: 1,
      project_title: "Project Title",
      tags: "Tag1",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      created_at: "12/12/2012",
      updated_at: "12/12/2012",
      actions: "ass",
    },
    {
      id: 2,
      S_no: 2,
      project_title: "Project Title",
      tags: "Tag2",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      created_at: "12/12/2012",
      updated_at: "12/12/2012",
      actions: "ass",
    },
    {
      id: 3,
      S_no: 3,
      project_title: "Project Title",
      tags: "Tag3",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      created_at: "12/12/2012",
      updated_at: "12/12/2012",
      actions: "ass",
    },
    {
      id: 4,
      S_no: 4,
      project_title: "Project Title",
      tags: "Tag4",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      created_at: "12/12/2012",
      updated_at: "12/12/2012",
      actions: "ass",
    },
    {
      id: 5,
      S_no: 5,
      project_title: "Project Title",
      tags: "Tag5",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      created_at: "12/12/2012",
      updated_at: "12/12/2012",
      actions: "ass",
    },
    {
      id: 6,
      S_no: 6,
      project_title: "Project Title",
      tags: "Tag6",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      created_at: "12/12/2012",
      updated_at: "12/12/2012",
      actions: "ass",
    },
    {
      id: 7,
      S_no: 7,
      project_title: "Project Title",
      tags: "Tag7",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      created_at: "12/12/2012",
      updated_at: "12/12/2012",
      actions: "ass",
    },
  ];

  // published
  const columns2 = [
    { field: "S_no", headerName: "Sr.No." },
    { field: "project_title", headerName: "Project Title" },
    { field: "tags", headerName: "Tags" },
    { field: "created_at", headerName: "Created At" },
    {
      field: "published_at",
      headerName: "Published At",
      // type: "number",
    },
    // {
    //   field: "project_thumbnail",
    //   headerName: "Project Thumbnail",
    //   width: 5,
    // },
    {
      field: "project_thumbnail",
      headerName: "Project Thumbnail",
      disableColumnMenu:true, sortable:false,
      renderCell: (params) => (
        <img
          src={PageIndex.Svg.thumbnail}
          className="table-thumbnail-icons"
          alt="Thumbnail"
        />
      ),
    },
    {
      field: "serial_number",
      headerName: "Serial Number",
    },
    {
      field: "start_date",
      headerName: "Start Date",
    },
    {
      field: "end_date",
      headerName: "End Date",
    },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   width: 25,
    // },
    {
      field: "action",
      headerName: "Actions",
      disableColumnMenu:true, sortable:false,
      renderCell: (params) => (
        <Index.Box className="table-icon-flex">
            <Index.Button
             title="View"
              className="table-icon-btn"
              onClick={() => {
                navigate("/user/published-and-qr-generated");
              }}
            >
              <img
                src={PageIndex.Svg.view}
                className="table-view-icons"
                alt=""
              />
            </Index.Button>
            <Index.Button
             title="Analytics"
              className="table-icon-btn"
              onClick={() => {
                navigate("/user/analytics");
              }}
            >
              <img
                src={PageIndex.Png.analyticView}
                className="table-analytic-icons"
                alt=""
              />
            </Index.Button>
          {/* <Index.Tooltip title="Delete" placement="top-end">
            <Index.Button
              className="table-icon-btn"
              onClick={() => {
                handleOpenDeleteModal();
              }}
            >
              <img
                src={PageIndex.Svg.trash}
                className="table-view-icons"
                alt=""
              />
            </Index.Button>
          </Index.Tooltip> */}
        </Index.Box>
      ),
    },
  ];
  const rows2 = [
    {
      id: 1,
      S_no: 1,
      project_title: "Project Title",
      tags: "Tag1",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      // created_at: "12-12-2012",
      // published_at: "12-12-2012",
      created_at: "12/12/2012",
      published_at: "12/12/2012",
      serial_number: "QR1",
      // start_date: "12-02-22",
      // end_date: "12-02-22",
      start_date: "02/12/22",
      end_date: "02/12/22",
      actions: "",
    },
    {
      id: 2,
      S_no: 2,
      project_title: "Project Title",
      tags: "Tag2",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      created_at: "12/12/2012",
      published_at: "12/12/2012",
      serial_number: "QR1",
      // start_date: "12-02-22",
      // end_date: "12-02-22",
      start_date: "02/12/22",
      end_date: "02/12/22",
      actions: "",
    },
    {
      id: 3,
      S_no: 3,
      project_title: "Project Title",
      tags: "Tag3",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      created_at: "12/12/2012",
      published_at: "12/12/2012",
      serial_number: "QR1",
      // start_date: "12-02-22",
      // end_date: "12-02-22",

      start_date: "02/12/22",
      end_date: "02/12/22",
      actions: "",
    },
    {
      id: 4,
      S_no: 4,
      project_title: "Project Title",
      tags: "Tag4",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      created_at: "12/12/2012",
      published_at: "12/12/2012",
      serial_number: "QR1",
      // start_date: "12-02-22",
      // end_date: "12-02-22",

      start_date: "02/12/22",
      end_date: "02/12/22",
      actions: "",
    },
    {
      id: 5,
      S_no: 5,
      project_title: "Project Title",
      tags: "Tag5",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      created_at: "12/12/2012",
      published_at: "12/12/2012",
      serial_number: "QR1",
      // start_date: "12-02-22",
      // end_date: "12-02-22",
      start_date: "02/12/22",
      end_date: "02/12/22",
      actions: "",
    },
    {
      id: 6,
      S_no: 6,
      project_title: "Project Title",
      tags: "Tag6",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      created_at: "12/12/2012",
      published_at: "12/12/2012",
      serial_number: "QR1",
      // start_date: "12-02-22",
      // end_date: "12-02-22",
      start_date: "02/12/22",
      end_date: "02/12/22",
      actions: "",
    },
    {
      id: 7,
      S_no: 7,
      project_title: "Proj01",
      tags: "Tag7",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      created_at: "12/12/2012",
      published_at: "12/12/2012",
      serial_number: "QR1",
      // start_date: "12-02-22",
      // end_date: "12-02-22",
      start_date: "02/12/22",
      end_date: "02/12/22",
      actions: "",
    },
    {
      id: 8,
      S_no: 8,
      project_title: "Proj01",
      tags: "Tag7",
      project_thumbnail: (
        <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />
      ),
      created_at: "12/12/2012",
      published_at: "12/12/2012",
      serial_number: "QR1",
      // start_date: "12-02-22",
      // end_date: "12-02-22",
      start_date: "02/12/22",
      end_date: "02/12/22",
      actions: "",
    },
  ];

  // for tabs
  const [value, setValue] = React.useState(type  ? 1 : 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // for delete modal
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  //for pagination
  const [page, setPage] = useState(location?.state?.tabId || 0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  console.log(value, "value");
  const [count, setCount] = useState(0);
  console.log(count, "count");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    //     if (count) {
    setCount(value === 0 ? publishedtablerows.length : drafttablerows.length);
    //     }
  }, [value]);

  return (
    <>
      <Index.Box className="dashboard-content project-directory-content">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              Project Directory
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="userlist-inner-btn-flex">
              <PageIndex.PrimaryButton
                className="adduser-btn comman-primary-btn"
                title="Add Project"
                btnLabel="Add Project"
                onClick={() => {
                  navigate("/user/add-project");
                }}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="tabs-main-box">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="user-tabs-main"
              >
                <Index.Tab
                  title="Published Projects"
                  label="Published Projects"
                  {...a11yProps(0)}
                  className="user-tab"
                />
                <Index.Tab
                  title="Draft Projects"
                  label="Draft Projects"
                  {...a11yProps(1)}
                  className="user-tab"
                />
              </Index.Tabs>
            </Index.Box>
            <TabPanel value={value} index={0} className="user-tabpanel">
              <Index.Box className="tabpanel-main common-card user-published-projects-wrape">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="user-search-flex">
                        <Index.Box className="user-page-sub-title-box">
                          <Index.Typography
                            className="user-page-sub-title"
                            component="h2"
                            variant="h2"
                          >
                            Published Projects
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Search"
                            />
                            <img
                              src={PageIndex.Svg.search}
                              className="search-grey-img"
                              alt="search grey img"
                            ></img>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="page-table-main published-projects-directory-table">
                        <Index.Box className="data-table-main">
                          <Index.DataGrid
                            rows={rows2}
                            // columns={columns2}
                            columns={columns2.map((col) => ({
                        ...col,
                        renderHeader: (params) => <PageIndex.CustomHeader {...params} column={col} />,
                      }))}
                            initialState={{
                              pagination: {
                                paginationModel: { page: 0, pageSize: 15 },
                              },
                            }}
                            pageSizeOptions={[15, 20, 25]}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="pagination-main">
                  {/* <PageIndex.Pagination
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    count={count}
                  /> */}
                  {/* <Index.Pagination
                    count={3}
                    variant="outlined"
                    shape="rounded"
                    className="pagination"
                  /> */}
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={1} className="user-tabpanel">
              <Index.Box className="tabpanel-main common-card">
                <Index.Box
                  sx={{ width: 1 }}
                  className="grid-main user-draft-project-wrape"
                >
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="user-search-flex">
                        <Index.Box className="user-page-sub-title-box">
                          <Index.Typography
                            className="user-page-sub-title"
                            component="h2"
                            variant="h2"
                          >
                            Draft Projects
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Search"
                            />
                            <img
                              src={PageIndex.Svg.search}
                              className="search-grey-img"
                              alt="search grey img"
                            ></img>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="page-table-main draft-projects-directory-table">
                        <Index.Box className="data-table-main">
                          <Index.DataGrid
                            rows={rows}
                            // columns={columns}
                            columns={columns.map((col) => ({
                        ...col,
                        renderHeader: (params) => <PageIndex.CustomHeader {...params} column={col} />,
                      }))}
                            initialState={{
                              pagination: {
                                paginationModel: { page: 0, pageSize: 15 },
                              },
                            }}
                            pageSizeOptions={[15, 20, 25]}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {/* <Index.Box className="pagination-main">
                  <PageIndex.Pagination
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    count={count}
                  />
                </Index.Box> */}
              </Index.Box>
            </TabPanel>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.DeleteModal
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
    </>
  );
}
