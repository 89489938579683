import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import PageIndex from "../container/PageIndex";
import DataService from "../config/DataService";
import { adminLogout } from "../redux/features/slices/admin/AdminSlice";
import { fetchUserMasterList } from "../redux/features/slices/admin/AdminService";
import { toast } from "react-toastify";

export default function AdminPrivateRoute() {
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  const dispatch = PageIndex.useDispatch();
  const { adminToken } = PageIndex.useSelector((state) => state.admin);
  const { isLogin } = PageIndex.useSelector((state) => state.admin);
  console.log(adminToken, isLogin, 13);
  const [userActivity, setUserActivity] = useState(true);
  const [local, setLocal] = useState(false);

  const isAuthenticate = (token) => {
    if (!token) return false;
    PageIndex.DataService.defaults.headers.common.auth = token;
    return true;
  };

  // change for api integrate start
  useEffect(() => {
    dispatch(fetchUserMasterList())
      .then((res) => {
        console.log(res?.payload?.status, 25);
      })
      .catch((error) => {
        console.log(error, 27);
      });
  }, [location.pathname, adminToken]);
  // change for api integrate end
  const isoLgin = JSON.parse(localStorage.getItem("adminLogin"));
  console.log(isoLgin, "isoLginisoLgin");

  //Static Admin Logout
  useEffect(() => {
    if (!isoLgin) {
      dispatch(PageIndex.adminLogout());
      navigate("/admin/login");
    }
  }, [location.pathname, isoLgin]);
  useEffect(() => {
    let timeoutId;

    const handleTimeout = () => {
      localStorage.setItem("adminLogout", Date.now().toString());

      dispatch(PageIndex.adminLogout());
      navigate("/admin/login");
    };

    const resetTimeout = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(handleTimeout, 30 * 60 * 1000);
    };

    resetTimeout();

    const activityListener = () => {
      setUserActivity(true);
      resetTimeout();
    };

    document.addEventListener("mousemove", activityListener);
    document.addEventListener("keydown", activityListener);

    return () => {
      document.removeEventListener("mousemove", activityListener);
      document.removeEventListener("keydown", activityListener);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [navigate]);

  useEffect(() => {
    DataService.interceptors.response.use(
      (response) => {
        const status = response.status;
        console.log(status, "statusstatus");

        return response;
      },
      (error) => {
        const status = error.response.data.status;
        if (status == 401) {
          navigate("/admin/login");
          // PageIndex.toast.success("You have been logged out");
          localStorage.setItem("adminLogin", false);
          dispatch(adminLogout());
        }
        return Promise.reject(error);
      }
    );
  }, []);

  return isAuthenticate(adminToken) && userActivity ? (
    <Outlet />
  ) : (
    <PageIndex.Navigate to="/admin/login" replace={true} />
  );
}
