import React from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import Chart from "react-apexcharts";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import HeatMap from "./charts/heatMap/HeatMap";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// for line chart Coonection Counts
const seriesLineObj = [
  {
    name: "Desktops",
    data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
  },
];
const optionsLineObj = {
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  xaxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
  },
};

// for radial chart Device types
const seriesAreaObj = [68];
const optionsAreaObj = {
  labels: ["RadialBar"],
  plotOptions: {
    radialBar: {
      hollow: {
        size: "70%",
      },
    },
  },
};

// FOR Date and Time tracker bar chart
const seriesBarObj = [
  {
    name: "Desktops",
    data: [30, 40, 25, 50, 49, 21, 70, 51],
  },
];
const optionsBarObj = {
  chart: {
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
  },
  series: [
    {
      data: [30, 40, 25, 50, 49, 21, 70, 51],
    },
  ],
};

// FOR  Device types pie chart
const seriesPieObj = [30, 40, 25, 50, 49, 21, 70, 51];
const optionsPieObj = {
  chart: {
    width: 380,
    type: "pie",
  },
  labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

// FOR Heat Map chart
const seriesHeatObj = [
  {
    name: "Metric1",
    data: generateData(18, {
      min: 0,
      max: 90,
    }),
  },
  {
    name: "Metric2",
    data: generateData(18, {
      min: 0,
      max: 90,
    }),
  },
  {
    name: "Metric3",
    data: generateData(18, {
      min: 0,
      max: 90,
    }),
  },
  {
    name: "Metric4",
    data: generateData(18, {
      min: 0,
      max: 90,
    }),
  },
  {
    name: "Metric5",
    data: generateData(18, {
      min: 0,
      max: 90,
    }),
  },
  {
    name: "Metric6",
    data: generateData(18, {
      min: 0,
      max: 90,
    }),
  },
  {
    name: "Metric7",
    data: generateData(18, {
      min: 0,
      max: 90,
    }),
  },
  {
    name: "Metric8",
    data: generateData(18, {
      min: 0,
      max: 90,
    }),
  },
  {
    name: "Metric9",
    data: generateData(18, {
      min: 0,
      max: 90,
    }),
  },
];
const optionsHeatObj = {
  chart: {
    height: 350,
    type: "heatmap",
  },
  plotOptions: {
    heatmap: {
      shadeIntensity: 0.5,
      radius: 0,
      useFillColorAsStroke: true,
      colorScale: {
        ranges: [
          {
            from: -30,
            to: 5,
            name: "low",
            color: "#00A100",
          },
          {
            from: 6,
            to: 20,
            name: "medium",
            color: "#128FD9",
          },
          {
            from: 21,
            to: 45,
            name: "high",
            color: "#FFB200",
          },
          {
            from: 46,
            to: 55,
            name: "extreme",
            color: "#FF0000",
          },
        ],
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 1,
  },
  title: {
    text: "HeatMap Chart with Color Range",
  },
};
function generateData(count, yrange) {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = "w" + (i + 1).toString();
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push({
      x: x,
      y: y,
    });
    i++;
  }
  return series;
}

export default function AdminAnalytics() {
  // for line chart Coonection Counts

  const [seriesLine, setSeriesLine] = React.useState(seriesLineObj);
  const [optionsLine, setOptionsLine] = React.useState(optionsLineObj);

  // for radial chart Device types
  const [seriesArea, setSeriesArea] = React.useState(seriesAreaObj);
  const [optionsArea, setOptionsArea] = React.useState(optionsAreaObj);

  // FOR Date and Time tracker bar chart
  const [seriesBar, setSeriesBar] = React.useState(seriesBarObj);
  const [optionsBar, setOptionsBar] = React.useState(optionsBarObj);

  // FOR Device types pie chart
  const [seriesPie, setSeriesPie] = React.useState(seriesPieObj);
  const [optionsPie, setOptionsPie] = React.useState(optionsPieObj);

  // FOR Heat Map chart
  const [seriesHeat, setSeriesHeat] = React.useState(seriesHeatObj);
  const [optionsHeat, setOptionsHeat] = React.useState(optionsHeatObj);

  // for open handleChangedropdown
  const [age, setAge] = React.useState("");
  const handleChangedropdown = (event) => {
    setAge(event.target.value);
  };

  //Geo location lat lng
  const center = [22.724521670075713, 75.84666352973338];
  const dewas = [22.968629354030895, 76.04992643198372];
  const mhow = [22.55421215490917, 75.76299155115863];

  //Marker icon
  const myIcon = new L.Icon({
    iconUrl: PageIndex.Png.markerIcon,
    iconRetinaUrl: PageIndex.Png.markerIcon,
    popupAnchor: [-0, -0],
    iconSize: [25, 41],
  });
  return (
    <>
      <Index.Box className="dashboard-content menu-report-content">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              Analytics
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            {/* <Index.FormControl className="form-control">
                <Index.Select
                  className="dropdown-select "
                  value={age}
                  onChange={handleChangedropdown}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <Index.MenuItem value="" className="menuitem">
                    Select Project
                  </Index.MenuItem>
                  <Index.MenuItem value={10} className="menuitem">
                    USD
                  </Index.MenuItem>
                  <Index.MenuItem value={20} className="menuitem">
                    USD
                  </Index.MenuItem>
                  <Index.MenuItem value={30} className="menuitem">
                    USD
                  </Index.MenuItem>
                </Index.Select>
              </Index.FormControl> */}
              <Index.Box className="userlist-inner-btn-flex">
              <Index.Box className="print-icon-box" title="Print">
                <img src={PageIndex.Svg.printicon} className="print-icons"/>
              </Index.Box>
              <PageIndex.PrimaryButton
                className="adduser-btn comman-primary-btn"
                btnLabel="Export"
                title="Export"
                imgSrc={PageIndex.Svg.exporticon}
              />
            </Index.Box>
            <Index.Box className="mulitiple-datepicker">
              <Index.Box className="date-picker-main analytic-date-picker-main">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker className="form-control"/>
                  </DemoContainer>
                </LocalizationProvider>
              </Index.Box>
              <Index.Box component="span" className="text">to</Index.Box>
              <Index.Box className="date-picker-main analytic-date-picker-main">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker className="form-control" />
                  </DemoContainer>
                </LocalizationProvider>
              </Index.Box>  
            </Index.Box>        
          </Index.Box>
        </Index.Box>

        <Index.Box className="menu-report-section">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 4, md: 4, lg: 4 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="user-page-sub-title-box">
                  <Index.Typography
                    className="user-page-sub-title"
                    component="h2"
                    variant="h2"
                  >
                    Client User Count
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="chart-main-card common-card">
                  <div className="bar">
                    <Chart
                      options={optionsBar}
                      series={seriesBar}
                      type="bar"
                      height="500"
                    />
                  </div>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="user-page-sub-title-box">
                  <Index.Typography
                    className="user-page-sub-title"
                    component="h2"
                    variant="h2"
                  >
                    Payment Received
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="chart-main-card common-card">
                  <div className="line">
                    <Chart
                      options={optionsLine}
                      series={seriesLine}
                      type="line"
                      height="500"
                    />
                  </div>
                </Index.Box>
              </Index.Box>
              {/* <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="user-page-sub-title-box">
                  <Index.Typography
                    className="user-page-sub-title"
                    component="h2"
                    variant="h2"
                  >
                    Device types
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="chart-main-card common-card">
                  <div id="chart">
                    <Chart
                      options={optionsPie}
                      series={seriesPie}
                      type="pie"
                      height="500"
                    />
                  </div>
                </Index.Box>
              </Index.Box> */}
              {/* <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="user-page-sub-title-box">
                  <Index.Typography
                    className="user-page-sub-title"
                    component="h2"
                    variant="h2"
                  >
                    Conversion Actions
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="chart-main-card common-card">
                  <div className="radialbar">
                    <Chart
                      options={optionsArea}
                      series={seriesArea}
                      type="radialBar"
                      height="500"
                    />
                  </div>
                </Index.Box>
              </Index.Box> */}

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="user-page-sub-title-box">
                  <Index.Typography
                    className="user-page-sub-title"
                    component="h2"
                    variant="h2"
                  >
                         Pin Point Geo Locations
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="chart-main-card common-card">
                  <MapContainer
                    center={center}
                    zoom={13}
                    scrollWheelZoom={true}
                    // style={{ width: "33.5vw", height: "40vh" }}
                    className="geo-location-map"
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    <Marker position={center} icon={myIcon}>
                      <Popup>Indore</Popup>
                    </Marker>
                    <Marker position={dewas} icon={myIcon}>
                      <Popup>Dewas</Popup>
                    </Marker>
                    <Marker position={mhow} icon={myIcon}>
                      <Popup>Mhow</Popup>
                    </Marker>
                  </MapContainer>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="user-page-sub-title-box">
                  <Index.Typography
                    className="user-page-sub-title"
                    component="h2"
                    variant="h2"
                  >
                    Heat Map
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="chart-main-card common-card">
                  <div id="chart">
                    <HeatMap />
                  </div>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
