import React from 'react'
import Index from '../../../Index'
import PageIndex from '../../../PageIndex'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function SelectQrCode(props) {
    // const [openSelectQrCode, setOpenSelectQrCode] = React.useState(false);
    // const handleOpenSelectQrCode = () => setOpenSelectQrCode(true);
    // const handleCloseSelectQrCode = () => setOpenSelectQrCode(false);

    const { openSelectQrCode, handleCloseSelectQrCode } = props

    const [openBulkQrSuccessfully, setOpenBulkQrSuccessfully] = React.useState(false);
    const handleOpenBulkQrSuccessfully = () => setOpenBulkQrSuccessfully(true);
    const handleCloseBulkQrSuccessfully = () => setOpenBulkQrSuccessfully(false);
    // for page navigation
    const navigate = PageIndex.useNavigate()


    return (
        <>
            {/* <Index.Button onClick={handleOpenViewQrCode}>Open modal</Index.Button> */}
            <Index.Modal
                open={openSelectQrCode}
                onClose={handleCloseSelectQrCode}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='common-modal'
            >               
                 <Index.Box sx={style} className='common-modal-inner publish-modal-inner select-qr-inner'>
                    <img src={PageIndex.Svg.close} className='modal-close-icon' />
                    <Index.Box className="save-publish-modal-content">
                        <Index.Typography className='modal-title-text'>Select QR Code Appearance</Index.Typography>
                    </Index.Box>
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box display="grid" className="display-row" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 6" }} className="grid-column">
                                <Index.Box className="input-details-auth">
                                    <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                            <Index.FormHelperText className="label-input-title">Start date</Index.FormHelperText>
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control"
                                                type='date'
                                            />
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 6" }} className="grid-column">

                                <Index.Box className="input-details-auth">
                                    <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                            <Index.FormHelperText className="label-input-title">End date</Index.FormHelperText>
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control"
                                                type='date'
                                            />
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>

                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 6" }} className="grid-column">
                                <Index.Box className="input-details-auth">
                                    <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                            <Index.FormHelperText className="label-input-title">Forground color</Index.FormHelperText>
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control"
                                                type='color'
                                            />

                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 6" }} className="grid-column">
                                <Index.Box className="input-details-auth">
                                    <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group">
                                            <Index.FormHelperText className="label-input-title">background color</Index.FormHelperText>
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control"
                                                type='color'
                                            />

                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 6" }} className="grid-column">
                                <Index.Box className="input-details-auth">
                                    <Index.Box className="input-box-auth">
                                        <Index.Box className="form-group radio-main">
                                            <Index.FormHelperText className="label-input-title">Shap of the QR code</Index.FormHelperText>
                                            <Index.RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                className='radiogroup'
                                            >
                                                <Index.FormControlLabel value="female" control={<Index.Radio className='radio' />} label="Rectengle" />
                                                <Index.FormControlLabel value="male" control={<Index.Radio className='radio' />} label="Circle" />
                                            </Index.RadioGroup>
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 12" }} className="grid-column">
                                <Index.Box className="user-btn-flex">
                                    <PageIndex.PrimaryButton className='create-user-btn comman-primary-btn' btnLabel="Submit" onClick={() => {
                                        handleOpenBulkQrSuccessfully()
                                    }} />
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal>

            <PageIndex.BulkQrSuccessfully openBulkQrSuccessfully={openBulkQrSuccessfully} handleCloseBulkQrSuccessfully={handleCloseBulkQrSuccessfully} />
        </>
    )
}
