import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { useSelector } from "react-redux";
import { getAdminData } from "../../../../redux/features/slices/admin/AdminSlice";

export default function AccountSetting() {
  const navigate = PageIndex.useNavigate();

  let initialValues = {
    paypalIntegration: "",
    mailHost:"",
    mailPort: "",
    mailUsername:"",
    mailPassword:"",
    mailFromAddress:"",
    mailFromName:"",
  };
  const handleSubmit = (values) => {
    // const urlencoded = new URLSearchParams();
  };

  return (
    <>
      <Index.Box className="dashboard-content admin-edit-profile">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              Settings
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <PageIndex.Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <PageIndex.Form>
              <Index.Box className="create-new-project-card account-setting-card common-card">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-account-setting-flex">
                        <Index.Box className=".admin-account-setting-flex-left">
                          <Index.FormHelperText className="label-input-title">
                            Paypal Integration
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="admin-account-setting-flex-right">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            placeholder="Enter paypal integration"
                            className="form-control"
                            name="paypalIntegration"
                            value={values?.paypalIntegration}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.paypalIntegration &&
                            touched.paypalIntegration && (
                              <p className="error-text">
                                {errors.paypalIntegration}
                              </p>
                            )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-account-setting-flex email-title">
                        <Index.Box className=".admin-account-setting-flex-left">
                          <Index.FormHelperText className="label-input-title">
                         Email Configuration
                          </Index.FormHelperText>
                        </Index.Box>
                       
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-account-setting-flex">
                        <Index.Box className=".admin-account-setting-flex-left">
                          <Index.FormHelperText className="label-input-title">
                            Mail Host
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="admin-account-setting-flex-right">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            placeholder="Enter mail host"
                            className="form-control"
                            name="mailHost"
                            value={values?.mailHost}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.emailConfiguration &&
                            touched.emailConfiguration && (
                              <p className="error-text">
                                {errors.emailConfiguration}
                              </p>
                            )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-account-setting-flex">
                        <Index.Box className=".admin-account-setting-flex-left">
                          <Index.FormHelperText className="label-input-title">
                            Mail Port
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="admin-account-setting-flex-right">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            placeholder="Enter mail port"
                            className="form-control"
                            name="mailPort"
                            value={values?.mailPort}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.mailPort &&
                            touched.mailPort && (
                              <p className="error-text">
                                {errors.mailPort}
                              </p>
                            )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-account-setting-flex">
                        <Index.Box className=".admin-account-setting-flex-left">
                          <Index.FormHelperText className="label-input-title">
                            Mail Username
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="admin-account-setting-flex-right">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            placeholder="Enter mail username"
                            className="form-control"
                            name="mailUsername"
                            value={values?.mailUsername}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.mailUsername &&
                            touched.mailUsername && (
                              <p className="error-text">
                                {errors.mailUsername}
                              </p>
                            )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-account-setting-flex">
                        <Index.Box className=".admin-account-setting-flex-left">
                          <Index.FormHelperText className="label-input-title">
                            Mail Password
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="admin-account-setting-flex-right">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            placeholder="Enter mail password"
                            className="form-control"
                            name="mailPassword"
                            value={values?.mailPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.mailPassword &&
                            touched.mailPassword && (
                              <p className="error-text">
                                {errors.mailPassword}
                              </p>
                            )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-account-setting-flex">
                        <Index.Box className=".admin-account-setting-flex-left">
                          <Index.FormHelperText className="label-input-title">
                            Mail From Address
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="admin-account-setting-flex-right">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            placeholder="Enter mail from address"
                            className="form-control"
                            name="mailFromAddress"
                            value={values?.mailFromAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.mailFromAddress &&
                            touched.mailFromAddress && (
                              <p className="error-text">
                                {errors.mailFromAddress}
                              </p>
                            )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box-auth admin-account-setting-flex">
                        <Index.Box className=".admin-account-setting-flex-left">
                          <Index.FormHelperText className="label-input-title">
                            Mail From Name
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="admin-account-setting-flex-right">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            placeholder="Enter mail from name"
                            className="form-control"
                            name="mailFromName"
                            value={values?.mailFromName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.mailFromName &&
                            touched.mailFromName && (
                              <p className="error-text">
                                {errors.mailFromName}
                              </p>
                            )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="user-btn-flex">
                  <PageIndex.PrimaryButton
                    className="create-user-btn comman-primary-btn"
                   onClick={()=>navigate("/admin/dashboard")}
                    btnLabel="Save"
                    title="Save"
                   imgSrc={PageIndex.Svg.plus}
                  />
                    <PageIndex.BorderButton
                    className="cancel-user-btn border-btn"
                   onClick={()=>navigate("/admin/dashboard")}
                    btnLabel="Cancel"
                    title="Cancel"
                  />
                </Index.Box>
              </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </PageIndex.Form>
          )}
        </PageIndex.Formik>
      </Index.Box>
    </>
  );
}
