import React, { useState } from "react";
// import './subscription.css'
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

function createData(title, subject, content, action, id) {
  return { title, subject, content, action, id };
}
const columns = [
  { field: "id", headerName: "Sr.No.",},
  { field: "title", headerName: "Title",},
  { field: "subject", headerName: "Subject",},
  { field: "content", headerName: "Content",},
  {
    field: "action",
    headerName: "Action",
    disableColumnMenu:true, sortable:false,
    renderCell: (params) => (
      <Index.Box className="table-icon-flex">
      <Index.Button className="table-icon-btn" title="Edit">
        <img src={PageIndex.Svg.edit} className="table-view-icons" alt="" />
      </Index.Button>
    </Index.Box>
    ),
  },
];

export default function EmailTemplateList() {
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  const updatedData = location?.state;
  console.log(updatedData,"updatedData");
  const rows = [
    createData(
      "Title",
      "Lorem ipsum... ",
      "Lorem ipsum...",
     "",
      1
    ),
    createData(
      "Title",
      "Lorem ipsum... ",
      "Lorem ipsum...",
   "",
      2
    ),
    createData(
      "Title",
      "Lorem ipsum... ",
      "Lorem ipsum...",
   "",
      3
    ),
    createData(
      "Title",
      "Lorem ipsum... ",
      "Lorem ipsum...",
   "",
      4
    ),
  ];
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState(rows.length);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddSubscription = () => {
    navigate("/admin/email-template/add");
  };
  // if(updatedData){
  //  rows.filter((data)=>data.id===updatedData.id)
  // }
  return (
    <>
      <Index.Box className="dashboard-content subscription-list">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              Email Templates
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="userlist-inner-btn-flex">
              <img alt="" src={Index.AddIcon} />
              <PageIndex.PrimaryButton
                className="adduser-btn comman-primary-btn"
                btnLabel="Add Template"
                title="Add Template"
                // imgSrc={PageIndex.Svg.plus}
                onClick={handleAddSubscription}
              />
            </Index.Box>
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img
                    src={PageIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="user-dashboard-list-row common-card">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="user-dash-box">
                  <Index.Box className="page-table-main email-template-table-main">
                    <Index.Box className="data-table-main">
                      <Index.DataGrid
                        rows={rows}
                        // columns={columns}
                        columns={columns.map((col) => ({
                        ...col,
                        renderHeader: (params) => <PageIndex.CustomHeader {...params} column={col} />,
                      }))}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              page: page,
                              pageSize: rowsPerPage,
                            },
                          },
                        }}
                        handleChangePage={handleChangePage}
                        pageSizeOptions={[15, 20, 25]}
                        onRowClick={(params) => {
    const { title, subject, content, id } = params.row;
    navigate("/admin/email-template/edit", {
      state: { title, subject, content, id },
    });
  }}
                      />
                    </Index.Box>
                   
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
