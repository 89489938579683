import React, { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.heat";

export default function Heatchart() {
  const mapRef = useRef(null);

  const [treeData, setTreeData] = React.useState([]);
  const getTreeChartData = async () => {
    const filteredData = [
      {
        name: "Indore",
        totalAmount: 10030365,
        lng: 75.8507,
        lat: 22.7055,
        value: 90.1722,
      },
      {
        name: "Dewas",
        totalAmount: 1401992851.0968,
        lng: 76.0519,
        lat: 22.9657,
        value: 80.6451,
      },
      {
        name: "Ujjain",
        totalAmount: 1401992851.0968,
        lng: 75.7877,
        lat: 23.179,
        value: 0.4586,
      },
    ];

    setTreeData(filteredData);
  };

  useEffect(() => {
    getTreeChartData();
  }, []);

  useEffect(() => {
    // const map = L.map(mapRef.current).setView([22.4707, 70.0577], 9);
    const map = L.map(mapRef.current, { zoomControl: false }).setView(
      [22.7055, 75.8507],
      8
    );

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution: false, // Remove default attribution
    }).addTo(map);

    const heatmapLatLngs = treeData?.map((data) =>
      L.latLng(data.lat, data.lng, data.value)
    );

    L.setOptions({ data: treeData });

    const heatmapOptions = {
      radius: 20,
      min: 10,
      max: 1,
      blur: 0.01,
      gradient: { 0.4: "blue", 0.75: "lime", 1: "red" },
    };

    const heatLayer = L.heatLayer(heatmapLatLngs, heatmapOptions);
    heatLayer.addTo(map);

    return () => {
      map.remove();
    };
  }, [treeData]);

  return <div ref={mapRef} className="geo-location-map" />;
}
