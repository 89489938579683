import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const OTPTimer = ({ onResend }) => {
  const [seconds, setSeconds] = useState(150); 
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    let timer;
    if (seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(timer);
      setIsDisabled(false); 
    }

    return () => {
      clearInterval(timer);
    };
  }, [seconds]);

  const handleResendClick = () => {
    if (!isDisabled) {
      onResend();
      setSeconds(150); 
      setIsDisabled(true); 
    }
  };

  return (
    <>
      {seconds > 0 ? (
        <p>
  Resend OTP in {Math.floor(seconds / 60) < 10 ? `0${Math.floor(seconds / 60)}` : Math.floor(seconds / 60)}:
  {seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60} {seconds <= 60 ? "seconds" : "minutes"}
</p>

      
      ) : (
        <Index.Box className="auth-primary-btn">
          <PageIndex.PrimaryButton
            btnLabel="Resend"
            type="submit"
            disabled={isDisabled}
            onClick={handleResendClick}
            className={
              isDisabled?.isVerifyCaptcha
                ? "comman-primary-btn register-btn disabled-login-button"
                : "comman-primary-btn register-btn"
            }
          />
        </Index.Box>
      )}
    </>
  );
};

export default OTPTimer;
  {/* <p>
          Resend OTP in {Math.floor(seconds / 60)}:{seconds % 60} {seconds<=60 ?"seconds":"minutes"}
        </p> */}