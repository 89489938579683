import React from "react";

import Index from "../../../Index";
import "./payment.css";
import icon from "../../../../assets/Svg";
import { useState } from "react";
import PageIndex from "../../../PageIndex";
function createData(paymentID, date, userEmail, mode, amount) {
  return { paymentID, date, userEmail, mode, amount };
}

const columns = [
  { field: "id", headerName: "Sr.No.", },
  { field: "paymentID", headerName: "Payment ID", },
  { field: "date", headerName: "Date",},
  { field: "userEmail", headerName: "User Email",},
  {
    field: "mode",
    headerName: "Mode",
  },
  {
    field: "amount",
    headerName: "Amount",
  },
];

const rows = [
  {
    id: 1,
    paymentID: 11201,
    date: "03/12/2023",
    userEmail: "tanu@gamil.com",
    mode: "PayPal",
    amount: "1220",
  },
  {
    id: 2,
    paymentID: 11202,
    date: "03/12/2023",
    userEmail: "thakurk@gamil.com",
    mode: "PayPal",
    amount: "1220",
  },
  {
    id: 3,
    paymentID: 11203,
    date: "03/12/2023",
    userEmail: "rksoni@gamil.com",
    mode: "PayPal",
    amount: "1220",
  },
  {
    id: 4,
    paymentID: 11204,
    date: "03/12/2023",
    userEmail: "rksoni@gamil.com",
    mode: "PayPal",
    amount: "1220",
  },
  {
    id: 5,
    paymentID: 11205,
    date: "03/12/2023",
    userEmail: "dinkark@gamil.com",
    mode: "PayPal",
    amount: "1220",
  },
  {
    id: 6,
    paymentID: 11206,
    date: "03/12/2023",
    userEmail: "rksoni@gamil.com",
    mode: "PayPal",
    amount: "1220",
  },
  {
    id: 7,
    paymentID: 11207,
    date: "03/12/2023",
    userEmail: "dinkark@gamil.com",
    mode: "PayPal",
    amount: "1220",
  },
];
const Payment = () => {
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState(rows.length);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Index.Box className="dashboard-content admin-payment">
      <Index.Box className="user-list-flex">
        <Index.Box className="user-page-title-box">
          <Index.Typography
            className="user-page-title"
            component="h2"
            variant="h2"
          >
            Payments
          </Index.Typography>
        </Index.Box>
        
        <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img
                    src={PageIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            </Index.Box>
        <Index.Box className="tabpanel-main common-card">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row "
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column coustum-payment-table"
              >
               
                <Index.Box className="data-table-main">
                  <Index.DataGrid
                    rows={rows}
                    // columns={columns}
                    columns={columns.map((col) => ({
                        ...col,
                        renderHeader: (params) => <PageIndex.CustomHeader {...params} column={col} />,
                      }))}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 15 },
                      },
                    }}
                    pageSizeOptions={[15, 20, 25]}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Payment;
