import * as React from "react";
import { styled } from "@mui/material/styles";
import Index from "../../../Index";
import PropTypes from "prop-types";
import PageIndex from "../../../PageIndex";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useState } from "react";
import { userLogout } from "../../../../redux/features/slices/user/UserSlice";
import { validateMobileNo } from "../../../../validation/UserValidation";
import MuiPhoneInput from "material-ui-phone-number";
import { CuntryList } from "../../auth/register/CountryList";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// start upload picture code
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
// end upload picture code
// for tabs design

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Profile() {
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const { userData } = PageIndex.useSelector((state) => state.user);
  const { userToken } = PageIndex.useSelector((state) => state.user);
  const [mobileError, setMobileError] = useState("");
  console.log(userToken, "userToken");
  console.log(userData, "userData");
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleZipChange = (event, setFieldValue) => {
    // const inputValue = event.target.value.replace(/\s+/g, " ");
    // const inputValue = event.target.value.replace(/\D/g, "");
    const inputValue = event.target.value.replace(/\W/g, "").substring(0, 8)

    if (inputValue.length <= 8) {
      setFieldValue("zip", inputValue);
    }
  };
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [mobileChange, setMobileChange] = useState(false);
  const [countryChange, setCountryChange] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickshowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickshowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickshowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    firstName: userData ? userData?.firstName : "",
    lastName: userData ? userData?.lastName : "",
    email: userData ? userData?.email : "",
    company: userData ? userData?.company : "",
    profile: userData ? userData?.profile : "",
    phoneNumber: userData ?  userData?.countryCode+userData?.phoneNumber :"",
    // phoneNumber: userData ? userData?.countryCode? userData?.countryCode+userData?.phoneNumber :"+91"+userData?.phoneNumber: "",
    addressLine1: userData ? userData?.addressLine1 : "",
    addressLine2: userData ? userData?.addressLine2 : "",
    city: userData ? userData?.city : "",
    state: userData ? userData?.state : "",
    country: userData ? userData?.country : "",
    zip: userData ? userData?.zip : "",
    companyName: userData ? userData?.companyName : "",
    logo: userData ? userData?.logo : "",
    deaultCountryCode: userData ? userData?.countryCode?.toString():"in",
    countryCode: userData ? userData?.countryCode:"",
    isSubmit: false,
  };
  const initialPasswordsValue = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    isSubmit: false,
  };
  const handleSubmit = (values, { setFieldValue }) => {
    if (value === 1) {
      setFieldValue("isSubmit", true);
      const urlencoded = new URLSearchParams();
      urlencoded.append("oldPassword", values.oldPassword);
      urlencoded.append("newPassword", values.newPassword);
      dispatch(PageIndex.userChangePassword(urlencoded)).then((res) => {
        if (res?.payload?.status == 200) {
          dispatch(userLogout());
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => setFieldValue("isSubmit", false), 3000);
        }
      });
    } else {
      if(!mobileError){
      setFieldValue("isSubmit", true);
      const urlencoded = new FormData();
      urlencoded.append("firstName", values.firstName);
      urlencoded.append("lastName", values.lastName);
      urlencoded.append("email", values.email);
      urlencoded.append(
        "phoneNumber",
        mobileChange  ? values.phoneNumber : countryChange ? userData?.phoneNumber : userData?.phoneNumber
      );
      // urlencoded.append("phoneNumber", values.phoneNumber);
      urlencoded.append("addressLine1", values.addressLine1);
      urlencoded.append("addressLine2", values.addressLine2);
      urlencoded.append("city", values.city);
      urlencoded.append("state", values.state);
      urlencoded.append("country", values.country);
      urlencoded.append("zip", values.zip);
      urlencoded.append(
        "countryCode",
        mobileChange || countryChange ? values.countryCode : userData?.countryCode
      );
      // urlencoded.append("countryCode", values.countryCode);
      urlencoded.append(
        "companyName",
        values.companyName ? values.companyName : ""
      );
      urlencoded.append("profile", values.profile);
      urlencoded.append("logo", values.logo);

      dispatch(PageIndex.userUpdateProfile(urlencoded)).then((res) => {
        console.log(res?.payload?.data, "response");
        if (res?.payload?.status == 200) {
          dispatch(PageIndex.getUserData(res?.payload?.data));
          setTimeout(() => {
            navigate("/user/dashboard");
          }, 1000);
        } else {
          setTimeout(() => setFieldValue("isSubmit", false), 3000);
        }
      });
    }
    }
  };
  return (
    <>
      <Index.Box className="tabs-main-box">
        <Index.Box sx={{ width: "100%" }}>
          <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Index.Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="user-tabs-main"
            >
              <Index.Tab
                title="Profile"
                label="Edit Profile"
                {...a11yProps(0)}
                className="user-tab"
              />
              <Index.Tab
                title="Change Password"
                label="Change Password"
                {...a11yProps(1)}
                className="user-tab"
              />
            </Index.Tabs>
          </Index.Box>
          <TabPanel value={value} index={0} className="user-tabpanel">
            <Index.Box className="tabpanel-main common-card">
              <PageIndex.Formik
                initialValues={initialValues}
                validationSchema={PageIndex.userUpdateProfileValidationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                }) => (
                  <PageIndex.Form>
                  {console.log(errors,210)}
                    <Index.Box className="profile-tab-content">
                      <Index.Box className="profile-header">
                        <Index.Box className="profile-input-box">
                          <label htmlFor="btn-upload">
                            <img
                              src={
                                values?.profile
                                  ? values?.profile instanceof Blob
                                    ? URL.createObjectURL(values.profile)
                                    : `${process.env.REACT_APP_API_BASE_IMAGE_URL}${values.profile}`
                                  : PageIndex.Png.profileLogo
                              }
                              alt=""
                              className="profile-img mb-15"
                            />

                            <input
                              id="btn-upload"
                              name="profile"
                              className="profile-upload-btn"
                              type="file"
                              accept="image/*"
                              // hidden
                              onChange={(e) => {
                                setFieldValue("profile", e.target.files[0]);
                              }}
                            />
                            <Index.Box className="profile-edit-main">
                              <Index.Box className="edit-div" title="Edit">
                                {" "}
                                <img
                                  className="profile-edit-img"
                                  src={PageIndex.Png.edit}
                                  alt=""
                                />
                              </Index.Box>
                            </Index.Box>
                          </label>
                          {errors.profile && touched.profile && (
                            <p className="error-text">{errors.profile}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="profile-basic-info-main">
                        <Index.Box sx={{ width: 1 }} className="grid-main">
                          <Index.Box
                            display="grid"
                            className="display-row"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 0, lg: 2 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                    First Name{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={(e) => {
                                      // const newValue = e.target.value.replace(
                                      //   /\s/g,
                                      //   ""
                                      // );
                                      const newValue = e.target.value.replace(
                                        /[^A-Za-z]/g,
                                        ""
                                      );

                                      setFieldValue("firstName", newValue);
                                    }}
                                    onBlur={handleBlur}
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder="First Name"
                                  />
                                  {errors.firstName && touched.firstName && (
                                    <p className="error-text">
                                      {errors.firstName}
                                    </p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                    Last Name{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={(e) => {
                                      // const newValue = e.target.value.replace(
                                      //   /\s/g,
                                      //   ""
                                      // );
                                      const newValue = e.target.value.replace(
                                        /[^A-Za-z]/g,
                                        ""
                                      );
                                      setFieldValue("lastName", newValue);
                                    }}
                                    onBlur={handleBlur}
                                    className="form-control"
                                    placeholder="Last Name"
                                  />
                                  {errors.lastName && touched.lastName && (
                                    <p className="error-text">
                                      {errors.lastName}
                                    </p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                    Email{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    name="email"
                                    value={values.email}
                                    // onChange={(e) => {
                                    //   const emailValue = e.target.value;
                                    //   setFieldValue("email", emailValue.trim());
                                    // }}
                                    onChange={(e) => {
                                              const { value } = e.target;
                                              const atIndex =
                                                value.indexOf("@");

                                            
                                              if (
                                                atIndex !== -1 &&
                                                atIndex <= 64
                                              ) {
                                                const newValue = value.replace(
                                                  /\s/g,
                                                  ""
                                                );

                                          
                                                const limitedValue =
                                                  newValue.slice(0, 254);
                                                setFieldValue(
                                                  "email",
                                                  limitedValue
                                                );
                                              } else if (
                                                atIndex === -1 &&
                                                value.length <= 64
                                              ) {
                                                const newValue = value.replace(
                                                  /\s/g,
                                                  ""
                                                );

                                              
                                                const limitedValue =
                                                  newValue.slice(0, 64);
                                                setFieldValue(
                                                  "email",
                                                  limitedValue
                                                );
                                              }
                                            }}
                                    // onChange={(e) => {
                                    //   const newValue = e.target.value.replace(
                                    //     /\s/g,
                                    //     ""
                                    //   );
                                    //   setFieldValue("email", newValue);
                                    // }}
                                    onBlur={handleBlur}
                                    className="form-control"
                                    placeholder="Email"
                                  />
                                  {errors.email && touched.email && (
                                    <p className="error-text">{errors.email}</p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                     Number{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  {/* <PhoneInput
                                    country={"in"}
                                    placeholder="Enter phone number"
                                    value={value} // Set the value using state
                                    onChange={(phone) => {
                                      // Handle value change
                                      // setValue(phone);
                                    }}
                                    onBlur={handleBlur}
                                    className="form-control country-form-control"
                                  /> */}
                                
                                  <MuiPhoneInput
                                            fullWidth
                                            name="phoneNumber"
                                            id="fullWidth"
                                            value={values?.phoneNumber}
                                            defaultCountry={
                                               values?.deaultCountryCode
                                            }
                                            // defaultCountry="in"
                                            onChange={(value, countryData) => {
                                              if (
                                                value.length > 0 &&
                                                value.length !== 0
                                              ) {
                                                const phoneNumberDigits =
                                                  value.replace(/\D/g, "");
                                                let countryCode =
                                                  countryData?.dialCode;
                                                setFieldValue(
                                                  "phoneNumber",
                                                  +phoneNumberDigits.slice(
                                                    countryCode?.length,
                                                    phoneNumberDigits?.length
                                                  )
                                                );
                                                // const paddedMobileNumber = phoneNumberDigits.padStart(10, '0').slice(-10); // Pad and take the last 10 digits
                                                setFieldValue(
                                                  "countryCode",
                                                  countryData?.dialCode
                                                );
                                                setFieldValue(
                                                  "country",
                                                  countryData?.name
                                                );
                                                // setFieldValue('mobileNumber', parseInt(paddedMobileNumber, 10));
                                              }
                                              setMobileError(
                                                validateMobileNo(
                                                  value,
                                                  countryData?.dialCode
                                                )
                                              );
                                              setMobileChange(true);
                                              setCountryChange(false)
                                            }}
                                            onBlur={handleBlur}
                                            className="form-control country-form-control"
                                            placeholder="Number"
                                          />
                               
                              
                                          {(errors.phoneNumber && 
                                    touched.phoneNumber ) || mobileError && (
                                      <p className="error-text">
                                      {mobileError ||
                                                errors.phoneNumber}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                    Address Line 1{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    name="addressLine1"
                                    value={values.addressLine1}
                                    onChange={(e) => {
                                      const newValue = e.target.value
                                                .replace(/^\s+/, "")
                                                .replace(/\s\s+/g, " ");
                                      setFieldValue("addressLine1", newValue);
                                    }}
                                    onBlur={handleBlur}
                                    className="form-control"
                                    placeholder="Address 1"
                                  />
                                  {errors.addressLine1 &&
                                    touched.addressLine1 && (
                                      <p className="error-text">
                                        {errors.addressLine1}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                    Address Line 2
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    name="addressLine2"
                                    value={values.addressLine2}
                                    onChange={(e) => {
                                      const newValue = e.target.value
                                                .replace(/^\s+/, "")
                                                .replace(/\s\s+/g, " ");
                                      setFieldValue("addressLine2", newValue);
                                    }}
                                    onBlur={handleBlur}
                                    className="form-control"
                                    placeholder="Address 2"
                                  />
                                  {errors.addressLine2 &&
                                    touched.addressLine2 && (
                                      <p className="error-text">
                                        {errors.addressLine2}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                    City{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    name="city"
                                    value={values.city}
                                    onChange={(e) => {
                                      const newValue = e.target.value
                                                .replace(/^\s+/, "")
                                                .replace(/\s\s+/g, " ");
                                      setFieldValue("city", newValue);
                                    }}
                                    onBlur={handleBlur}
                                    className="form-control"
                                    placeholder="City"
                                  />
                                  {errors.city && touched.city && (
                                    <p className="error-text">{errors.city}</p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                    State
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    name="state"
                                    value={values.state}
                                    onChange={(e) => {
                                      const newValue = e.target.value
                                                .replace(/^\s+/, "")
                                                .replace(/\s\s+/g, " ");
                                      setFieldValue("state", newValue);
                                    }}
                                    onBlur={handleBlur}
                                    className="form-control"
                                    placeholder="State"
                                  />
                                  {errors.state && touched.state && (
                                    <p className="error-text">{errors.state}</p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                    Country{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.Box className="dropdown-box">
                                    <Index.FormControl className="form-control">
                                      <Index.Select
                                        className="dropdown-select "
                                        value={values.country}
                                        onBlur={handleBlur}
                                        name="country"
                                        // onChange={handleChange}
                                        onChange={(e) => {
                                                  const selectedCountry =
                                                    e.target.value;
                                                  const countryObj =
                                                    CuntryList.find(
                                                      (country) =>
                                                        country.iso.toLowerCase() ===
                                                        selectedCountry.toLowerCase()
                                                    );
                                               
                                                  if (countryObj) {
                                                    setFieldValue(
                                                      "country",
                                                      countryObj.name
                                                    );
                                                    setFieldValue(
                                                      "countryCode",
                                                      countryObj.code
                                                    );
                                                    setFieldValue(
                                                      "deaultCountryCode",
                                                      countryObj?.iso.toLowerCase()
                                                    );
                                                    setMobileError('Please enter a valid Number')
                                                    setCountryChange(true)
                                                  } else {
                                                    setFieldValue(
                                                      "country",
                                                      values?.country
                                                    );
                                                   
                                                  }
                                                }}
                                        displayEmpty
                                        inputProps={{
                                          "aria-label": "Without label",
                                        }}
                                      >
                                        <Index.MenuItem
                                                  value={
                                                    values?.country
                                                      ? values?.country
                                                      : ""
                                                  }
                                                  className="menuitem"
                                                  disabled
                                                >
                                                  {values?.country
                                                    ? values?.country
                                                    : "Select Country"}
                                                </Index.MenuItem>
                                        {CuntryList?.map((ele) => {
                                          return (
                                            <Index.MenuItem
                                              className="menuitem"
                                              key={ele?.code}
                                              value={ele?.iso}
                                            >
                                              {ele?.name}
                                            </Index.MenuItem>
                                          );
                                        })}
                                      </Index.Select>
                                      {errors.country && touched.country && (
                                        <p className="error-text">
                                          {errors.country}
                                        </p>
                                      )}
                                    </Index.FormControl>
                                  </Index.Box>
                                
                                  {errors.country && touched.country && (
                                    <p className="error-text">
                                      {errors.country}
                                    </p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 4",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                    Zip
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    name="zip"
                                    value={values.zip}
                                    onChange={(event) => {
                                      handleZipChange(event, setFieldValue);
                                    }}
                                    onBlur={handleBlur}
                                    className="form-control"
                                    placeholder="Zip"
                                  />

                                  {errors.zip && touched.zip && (
                                    <p className="error-text">{errors.zip}</p>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 8",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                    Company Name
                                  </Index.FormHelperText>
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    name="companyName"
                                    value={values.companyName}
                                    onChange={(e) => {
                                      const newValue = e.target.value
                                                .replace(/^\s+/, "")
                                                .replace(/\s\s+/g, " ");
                                      setFieldValue("companyName", newValue);
                                    }}
                                    // onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="form-control"
                                    placeholder="Company Name"
                                  />
                                  {errors.companyName &&
                                    touched.companyName && (
                                      <p className="error-text">
                                        {errors.companyName}
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                    Company Logo
                                  </Index.FormHelperText>
                                  <Index.Box class="upload-btn-main profile-upload-logo-btn">
                                    <Index.Button
                                      title="Company Logo"
                                      component="label"
                                      variant="contained"
                                      htmlFor="logo"
                                      className="upload-profile-logo"
                                    >
                                      
                                      <img
                                        className={`${
                                          values.logo
                                            ? "company-upload-img-view"
                                            : userData?.logo
                                            ? "company-upload-img-view"
                                            : "company-upload-img"
                                        }`}
                                        src={
                                          // PageIndex.Svg.cloudicon
                                          values.logo instanceof Blob
                                            ? URL.createObjectURL(values.logo)
                                            : userData?.logo
                                            ? `${process.env.REACT_APP_API_BASE_IMAGE_URL}${values.logo}`
                                            : PageIndex.Svg.cloudicon
                                        }
                                        alt=""
                                      />
                                      <input
                                        type="file"
                                        name="logo"
                                        id="logo"
                                        style={{ display: "none" }}
                                        // InputProps={{ accept: "image/*, .pdf" }}

                                        onChange={(event) => {
                                          setFieldValue(
                                            "logo",
                                            event.target.files[0]
                                          );
                                        }}
                                      />
                                      {/* <VisuallyHiddenInput type="file" /> */}
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="flex-end mt-30">
                          <Index.Box className="user-btn-flex">
                            <PageIndex.PrimaryButton
                              btnLabel="Update"
                              title="Update"
                              type="submit"
                              disabled={values?.isSubmit}
                              startIcon={
                                values?.isSubmit && (
                                  <Index.CircularProgress
                                    size={15}
                                    color="inherit"
                                  />
                                )
                              }
                              className={
                                values?.isSubmit
                                  ? "comman-primary-btn cancel-user-btn border-btn login-btn disabled-login-button"
                                  : "comman-primary-btn cancel-user-btn border-btn login-btn"
                              }
                            />
                            <PageIndex.BorderButton
                              className="create-user-btn border-btn"
                              title="Cancel"
                              btnLabel="Cancel"
                              onClick={() => navigate("/user/dashboard")}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </PageIndex.Form>
                )}
              </PageIndex.Formik>
            </Index.Box>
          </TabPanel>
          <TabPanel value={value} index={1} className="user-tabpanel">
            <Index.Box className="tabpanel-main common-card">
              <PageIndex.Formik
                initialValues={initialPasswordsValue}
                validationSchema={PageIndex.adminChangePasswordValidationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                }) => (
                  <PageIndex.Form>
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        className="display-row"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box
                            display="grid"
                            className="display-row"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 0, lg: 2 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                    Old Password{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group pass_group_main">
                                    <Index.OutlinedInput
                                      className="form-control custom_form_control"
                                      id="outlined-adornment-password"
                                      placeholder="Enter old password"
                                      type={
                                        showOldPassword ? "text" : "password"
                                      }
                                      name="oldPassword"
                                      value={values?.oldPassword}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      endAdornment={
                                        <Index.InputAdornment
                                          position="end"
                                          className="pass_position"
                                        >
                                          <Index.IconButton
                                            className="icon_end_icon"
                                            aria-label="toggle password visibility"
                                            onClick={handleClickshowOldPassword}
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                          >
                                            {showOldPassword ? (
                                              <Index.VisibilityOff />
                                            ) : (
                                              <Index.Visibility />
                                            )}
                                          </Index.IconButton>
                                        </Index.InputAdornment>
                                      }
                                      label="Password"
                                    />
                                    {errors.oldPassword &&
                                      touched.oldPassword && (
                                        <p className="error-text">
                                          {errors.oldPassword}
                                        </p>
                                      )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                    New Password{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group pass_group_main">
                                    <Index.OutlinedInput
                                      className="form-control custom_form_control"
                                      id="outlined-adornment-password"
                                      placeholder="Enter New Password"
                                      type={
                                        showNewPassword ? "text" : "password"
                                      }
                                      name="newPassword"
                                      value={values?.newPassword}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      endAdornment={
                                        <Index.InputAdornment
                                          position="end"
                                          className="pass_position"
                                        >
                                          <Index.IconButton
                                            className="icon_end_icon"
                                            aria-label="toggle password visibility"
                                            onClick={handleClickshowNewPassword}
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                          >
                                            {showNewPassword ? (
                                              <Index.VisibilityOff />
                                            ) : (
                                              <Index.Visibility />
                                            )}
                                          </Index.IconButton>
                                        </Index.InputAdornment>
                                      }
                                      label="Password"
                                    />
                                    {errors.newPassword &&
                                      touched.newPassword && (
                                        <p className="error-text">
                                          {errors.newPassword}
                                        </p>
                                      )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 12",
                              }}
                              className="grid-column"
                            >
                              <Index.Box className="input-box-auth">
                                <Index.Box className="form-group">
                                  <Index.FormHelperText className="label-input-title">
                                    Confirm Password{" "}
                                    <span className="mandatory-asterisk">
                                      *
                                    </span>
                                  </Index.FormHelperText>
                                  <Index.Box className="form-group pass_group_main">
                                    <Index.OutlinedInput
                                      className="form-control custom_form_control"
                                      id="outlined-adornment-password"
                                      placeholder="Enter Confirm Password"
                                      type={
                                        showConfirmPassword
                                          ? "text"
                                          : "password"
                                      }
                                      name="confirmPassword"
                                      value={values?.confirmPassword}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      endAdornment={
                                        <Index.InputAdornment
                                          position="end"
                                          className="pass_position"
                                        >
                                          <Index.IconButton
                                            className="icon_end_icon"
                                            aria-label="toggle password visibility"
                                            onClick={
                                              handleClickshowConfirmPassword
                                            }
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                          >
                                            {showConfirmPassword ? (
                                              <Index.VisibilityOff />
                                            ) : (
                                              <Index.Visibility />
                                            )}
                                          </Index.IconButton>
                                        </Index.InputAdornment>
                                      }
                                      label="Password"
                                    />
                                    {errors.confirmPassword &&
                                      touched.confirmPassword && (
                                        <p className="error-text">
                                          {errors.confirmPassword}
                                        </p>
                                      )}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box class="password-require-section">
                            <Index.Typography class="password-require-title">
                              Password requirements:
                            </Index.Typography>
                            <Index.Typography class="password-require-ensure">
                              Ensure that these requirements are met:
                            </Index.Typography>
                            <Index.List class="password-ul">
                              <Index.ListItem>
                                Minimum 8 characters long - the more, the better
                              </Index.ListItem>
                              <Index.ListItem>
                                At least one lowercase character
                              </Index.ListItem>
                              <Index.ListItem>
                                At least one uppercase character
                              </Index.ListItem>
                              <Index.ListItem>
                                At least one number, symbol, or whitespace
                                character
                              </Index.ListItem>
                            </Index.List>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="auth-primary-btn user-btn-flex">
                            <PageIndex.PrimaryButton
                              btnLabel="Save"
                              title="Save"
                              type="submit"
                              disabled={values?.isSubmit}
                              startIcon={
                                values?.isSubmit && (
                                  <Index.CircularProgress
                                    size={15}
                                    color="inherit"
                                  />
                                )
                              }
                              className={
                                values?.isSubmit
                                  ? "comman-primary-btn cancel-user-btn border-btn login-btn disabled-login-button"
                                  : "comman-primary-btn cancel-user-btn border-btn login-btn"
                              }
                            />

                            <PageIndex.BorderButton
                              className="create-user-btn border-btn"
                              btnLabel="Cancel"
                              title="Cancel"
                              onClick={() => navigate("/user/dashboard")}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </PageIndex.Form>
                )}
              </PageIndex.Formik>
            </Index.Box>
          </TabPanel>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Profile;
