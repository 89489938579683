import React from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ViewQRCode(props) {
  const { openViewQrCode, handleCloseViewQrCode } = props;

  // const [openViewQrCode, setOpenViewQrCode] = React.useState(false);
  // const handleOpenViewQrCode = () => setOpenViewQrCode(true);
  // const handleCloseViewQrCode = () => setOpenViewQrCode(false);

  // for page navigation
  const navigate = PageIndex.useNavigate();

  // for open handleChangedropdown
  const [age, setAge] = React.useState("");
  const handleChangedropdown = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      {/* <Index.Button onClick={handleOpenViewQrCode}>Open modal</Index.Button> */}
      <Index.Modal
        open={openViewQrCode}
        onClose={handleCloseViewQrCode}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="common-modal"

      >
        <Index.Box
          sx={style}
          className="common-modal-inner publish-modal-inner"
        >
          <img
            src={PageIndex.Svg.close}
            className="modal-close-icon"
            onClick={handleCloseViewQrCode}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleCloseViewQrCode();
              }
            }}
            tabIndex={0}
            title="Close"
          />
          <Index.Box className="save-publish-modal-content">
            <Index.Typography className="modal-title-text">
              Published QR Code
            </Index.Typography>
          </Index.Box>
          <Index.Box className="qr-code-generation-section">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 6",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  {/* <Index.Box className="view-scanner-flex-main"> */}
                  <Index.Box className="qrscanner-box-main">
                    <img
                      src={PageIndex.Png.viewscanner}
                      className="view-qrscanner-img"
                      alt=""
                    />
                  </Index.Box>
                  <Index.Box className="published-qr-content-main">
                    <Index.Box className="pulished-iocn-main">
                      <Index.Box className="dropdown-box view-qr-dropdown">
                        {/* <img src={PageIndex.Svg.settings} className='style-published-icons' /> */}
                        <Index.FormControl className="form-control">
                          <Index.Select
                            className="dropdown-select "
                            value={age}
                            onChange={handleChangedropdown}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >

                            <Index.MenuItem value="" className="menuitem">
                            Simple

                            </Index.MenuItem>
                            
                            <Index.MenuItem value={20} className="menuitem">
                              Styled
                            </Index.MenuItem>
                          </Index.Select>
                        </Index.FormControl>
                      </Index.Box>
                      <Index.Box className="view-icoon-box" title="Share">
                        <img
                          src={PageIndex.Svg.share}
                          className="published-icons"
                        />
                        <Index.Typography className="published-icon-text">
                          Share
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="view-icoon-box" title="Print">
                        <img
                          src={PageIndex.Svg.printicon}
                          className="published-icons"
                        />
                        <Index.Typography className="published-icon-text">
                          Print
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="view-icoon-box" title="SVG">
                        <img
                          src={PageIndex.Svg.svgicon}
                          className="published-icons"
                        />
                        <Index.Typography className="published-icon-text">
                          SVG
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="view-icoon-box" title="JPEG">
                        <img
                          src={PageIndex.Svg.jpegicon}
                          className="published-icons"
                        />
                        <Index.Typography className="published-icon-text">
                          JPEG
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="input-details-auth copy-input-box">
                      <Index.Box className="input-box-auth">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder="Enter text"
                          />
                          <img title="Copy" src={PageIndex.Svg.copy} className="copy-icon" />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {/* </Index.Box> */}
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 6",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="published-succses-main">
                    <Index.Box className="view-priview-flex">
                      <Index.Box className="view-flex-col">
                        <Index.Typography className="view-priview-digit">
                          []
                        </Index.Typography>
                        <Index.Typography className="view-priview-text">
                          Preview
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="view-flex-col">
                        <Index.Typography className="view-priview-digit">
                          1
                        </Index.Typography>
                        <Index.Typography className="view-priview-text">
                          No. of Scans
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 6",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  {/* <Index.Box className="modal-user-btn-flex">
                                                            <PageIndex.BorderButton className='cancel-user-btn border-btn' btnLabel="Cancel" />
                                                            <PageIndex.PrimaryButton className='create-user-btn comman-primary-btn' btnLabel="Submit" onClick={() => {
                                                navigate("/user/qr-code-list")
                                          }}/>
                                                      </Index.Box> */}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
