import React,{useState} from 'react'

import { Outlet } from 'react-router-dom';
import Index from '../../../Index';
import PageIndex from '../../../PageIndex';
import AutoLogout from '../../../../component/common/autologout/AutoLogout';


export default function LayOut({toggleDarkMode}) {

  const [open,setOpen] =useState(true);
  
  return (
    <div>   
      <Index.Box className="dashboard-main">
      <Index.Box className={`dashboard-left-main ${open?"active":"admin-sidebar-deactive"}`}>
          <PageIndex.Sidebar open={open} setOpen={setOpen}/>
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <PageIndex.Header setOpen={setOpen} open={open} toggleDarkMode={toggleDarkMode}/>
          <Index.Box className="dashboard-containt-main">
          <Outlet/>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  )
}
