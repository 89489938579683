import React from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const ViewSubscription = ({
  openViewSubscription,
  handleCloseViewSubscription,
  viewData,
}) => {
  const navigate = PageIndex.useNavigate();
  const row = viewData;
  return (
    <>
      <Index.Modal
        open={openViewSubscription}
        onClose={handleCloseViewSubscription}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="common-modal"
      >
        <Index.Box
          sx={style}
          className="common-modal-inner view-subscription-modal-inner"
        >
          <img
            src={PageIndex.Svg.close}
            className="modal-close-icon"
            alt=""
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleCloseViewSubscription();
              }
            }}
            tabIndex={0}
            onClick={handleCloseViewSubscription}
            title="Close"
          />
          <Index.Box className="dashboard-content subscription-add-Edit-page">
            <Index.Box className="user-list-flex">
              <Index.Box className="user-page-title-box">
                <Index.Typography
                  className="user-page-title"
                  component="h2"
                  variant="h2"
                >
                  Subscription
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="create-new-project-card subscription-create-new-project-card">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box-auth subscription-flex">
                      <Index.Box className="subscription-flex-left">
                        <Index.FormHelperText className="label-input-title">
                          Title/Name:
                        </Index.FormHelperText>
                      </Index.Box>
                      <Index.Box className="subscription-flex-right">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            name="title"
                            value={viewData ? viewData.name : ""}
                            className="form-control"
                            type="text"
                            placeholder="Name"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box-auth subscription-flex">
                      <Index.Box className="subscription-flex-left">
                        <Index.FormHelperText className="label-input-title">
                          Price:
                        </Index.FormHelperText>
                      </Index.Box>
                      <Index.Box className="subscription-flex-right">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            value={viewData ? viewData.price : ""}
                            className="form-control"
                            type="number"
                            placeholder="Price"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box-auth subscription-flex">
                      <Index.Box className="subscription-flex-left">
                        <Index.FormHelperText className="label-input-title">
                          Active Projects:
                        </Index.FormHelperText>
                      </Index.Box>
                      <Index.Box className="subscription-flex-right">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            value={viewData ? viewData.activeProjects : ""}
                            className="form-control"
                            type="number"
                            placeholder="Please enter Number of Active Projects"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box-auth subscription-flex">
                      <Index.Box className="subscription-flex-left">
                        <Index.FormHelperText className="label-input-title">
                          Active QR:
                        </Index.FormHelperText>
                      </Index.Box>
                      <Index.Box className="subscription-flex-right">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            value={viewData ? viewData.activeQR : ""}
                            className="form-control"
                            type="number"
                            placeholder="Please enter Number of Active QR"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box-auth subscription-flex">
                      <Index.Box className="subscription-flex-left">
                        <Index.FormHelperText className="label-input-title">
                          Description:
                        </Index.FormHelperText>
                      </Index.Box>
                      <Index.Box className="subscription-flex-right">
                        <Index.Box className="form-group">
                          <Index.TextareaAutosize
                            aria-label="minimum height"
                            value={viewData ? viewData.description : ""}
                            // minRows={3}
                            placeholder="Type here description"
                            className="form-control-textarea"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="user-btn-flex">
                      <PageIndex.BorderButton
                        className="cancel-user-btn border-btn"
                        onClick={handleCloseViewSubscription}
                        title="Cancel"
                        btnLabel="Cancel"
                      />
                      {/* <PageIndex.PrimaryButton
                        className="create-user-btn comman-primary-btn"
                        btnLabel="Save"
                        onClick={() =>
                          navigate("/admin/subscription-plan/edit", {
                            state: row,
                          })
                        }
                        imgSrc={PageIndex.Svg.plus}
                      /> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default ViewSubscription;
