import React from "react";
import Index from "../../Index";
import PageIndex from "../../../container/PageIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function DeleteModal(props) {
  // for delete modal
  const { openDeleteModal, handleCloseDeleteModal } = props;
  // const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  // const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  // const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  return (
    <>
      {/* <Index.Button onClick={handleOpenDeleteModal}>Open modal</Index.Button> */}
      <Index.Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="common-modal"
      >
        <Index.Box sx={style} className="common-modal-inner delete-modal-inner">
          <img
            src={PageIndex.Svg.close}
            className="modal-close-icon"
            onClick={handleCloseDeleteModal}
            onKeyUp={(e) => {
                
                if (e.key === "Enter") {
                  handleCloseDeleteModal();
                }
              }}
              tabIndex={0}
            title="Close"
          />
          <Index.Box className="save-draft-modal-content">
            <Index.Typography className="modal-title-text">
              Are you sure you want to Delete?
            </Index.Typography>
          </Index.Box>
          <Index.Box className="qr-code-generation-section">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="modal-user-center-btn-flex">
                    <PageIndex.PrimaryButton
                      className="create-user-btn comman-primary-btn"
                      btnLabel="Delete"
                      onClick={handleCloseDeleteModal}
                      title="Delete"
                    />
                    <PageIndex.BorderButton
                      className="cancel-user-btn border-btn"
                      title="Cancel"
                      btnLabel="Cancel"
                      onClick={handleCloseDeleteModal}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
