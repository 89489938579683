import React from 'react'
import Index from '../../../Index'
import PageIndex from '../../../PageIndex'
import PropTypes from 'prop-types';


// for tabs design

function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
            <div
                  role="tabpanel"
                  hidden={value !== index}
                  id={`simple-tabpanel-${index}`}
                  aria-labelledby={`simple-tab-${index}`}
                  {...other}
            >
                  {value === index && (
                        <Index.Box sx={{ p: 3 }}>
                              <Index.Typography>{children}</Index.Typography>
                        </Index.Box>
                  )}
            </div>
      );
}

TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
};

function a11yProps(index) {
      return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
      };
}


// for published table data

function PublishedCreateData(SerialNumber, QrTitle, ProjectThumbnail, StartDate, EndDate, Actions) {
      return { SerialNumber, QrTitle, ProjectThumbnail, StartDate, EndDate, Actions };
}





export default function QRCode() {

      // for page navigation 
      const navigate = PageIndex.useNavigate()

      // for tabs
      const [value, setValue] = React.useState(0);

      const handleChange = (event, newValue) => {
            setValue(newValue);
      };

      // for published table data
      const publishedtablerows = [
            PublishedCreateData('QR1', 'T1', <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />, '12-02-2022', '12-02-2013', <Index.Box className="table-icon-flex"><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.view} className="table-view-icons" />
            </Index.Button> <Index.Button className='table-icon-btn'><img src={PageIndex.Svg.share} className="table-view-icons" />
                  </Index.Button><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.upload} className="table-view-icons" />
                  </Index.Button></Index.Box>),


            PublishedCreateData('QR1', 'T1', <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />, '12-02-2022', '12-02-2013', <Index.Box className="table-icon-flex"><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.view} className="table-view-icons" />
            </Index.Button> <Index.Button className='table-icon-btn'><img src={PageIndex.Svg.share} className="table-view-icons" />
                  </Index.Button><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.upload} className="table-view-icons" />
                  </Index.Button></Index.Box>),

            PublishedCreateData('QR1', 'T1', <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />, '12-02-2022', '12-02-2013', <Index.Box className="table-icon-flex"><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.view} className="table-view-icons" />
            </Index.Button> <Index.Button className='table-icon-btn'><img src={PageIndex.Svg.share} className="table-view-icons" />
                  </Index.Button><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.upload} className="table-view-icons" />
                  </Index.Button></Index.Box>),

            PublishedCreateData('QR1', 'T1', <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />, '12-02-2022', '12-02-2013', <Index.Box className="table-icon-flex"><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.view} className="table-view-icons" />
            </Index.Button> <Index.Button className='table-icon-btn'><img src={PageIndex.Svg.share} className="table-view-icons" />
                  </Index.Button><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.upload} className="table-view-icons" />
                  </Index.Button></Index.Box>),

            PublishedCreateData('QR1', 'T1', <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />, '12-02-2022', '12-02-2013', <Index.Box className="table-icon-flex"><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.view} className="table-view-icons" />
            </Index.Button> <Index.Button className='table-icon-btn'><img src={PageIndex.Svg.share} className="table-view-icons" />
                  </Index.Button><Index.Button className='table-icon-btn'><img src={PageIndex.Svg.upload} className="table-view-icons" />
                  </Index.Button></Index.Box>),
      ];

      return (
            <>
                  <Index.Box className="dashboard-content qr-code-content">
                        <Index.Box className="user-list-flex">
                              <Index.Box className="user-page-title-box">
                                    <Index.Typography className='user-page-title' component='h2' variant='h2'>QR Code</Index.Typography>
                              </Index.Box>
                              <Index.Box className="userlist-btn-flex">
                                    <Index.Box className="userlist-inner-btn-flex">
                                          <PageIndex.PrimaryButton className='adduser-btn comman-primary-btn' btnLabel="Generate QR" onClick={() => {
                                                navigate("/user/bulk-qr-code")
                                          }} />
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>

                        <Index.Box className="tabs-main-box">
                              <Index.Box sx={{ width: '100%' }}>
                                    <Index.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                          <Index.Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='user-tabs-main'>
                                                <Index.Tab label="Published" {...a11yProps(0)} className="user-tab" />
                                                <Index.Tab label="Non Published" {...a11yProps(1)} className="user-tab" />
                                          </Index.Tabs>
                                    </Index.Box>
                                    <TabPanel value={value} index={0} className="user-tabpanel">
                                          <Index.Box className="tabpanel-main common-card">
                                                <Index.Box sx={{ width: 1 }} className="grid-main">
                                                      <Index.Box display="grid" className="display-row" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}>
                                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                                                                  <Index.Box className="user-search-flex">
                                                                        <Index.Box className="user-page-sub-title-box">
                                                                              <Index.Typography className='user-page-sub-title' component='h2' variant='h2'>Published</Index.Typography>
                                                                        </Index.Box>
                                                                        <Index.Box className="user-search-box">
                                                                              <Index.Box className="form-group">
                                                                                    <Index.TextField
                                                                                          fullWidth
                                                                                          id="fullWidth"
                                                                                          className="form-control"
                                                                                          placeholder="Search"
                                                                                    />
                                                                                    <img
                                                                                          src={PageIndex.Svg.search}
                                                                                          className="search-grey-img" alt='search grey img'
                                                                                    ></img>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                                  <Index.Box className="page-table-main qr-code-published-table">
                                                                        <Index.TableContainer component={Index.Paper} className='table-container'>
                                                                              <Index.Table aria-label="simple table" className='table'>
                                                                                    <Index.TableHead className='table-head'>
                                                                                          <Index.TableRow className='table-row'>
                                                                                                <Index.TableCell component='th' variant='th' className='table-th'>SerialNumber</Index.TableCell>
                                                                                                <Index.TableCell component='th' variant='th' className='table-th'>QR Title</Index.TableCell>
                                                                                                <Index.TableCell component='th' variant='th' className='table-th'>Project Thumbnail</Index.TableCell>
                                                                                                <Index.TableCell component='th' variant='th' className='table-th'>Start Date</Index.TableCell>
                                                                                                <Index.TableCell component='th' variant='th' className='table-th'>End Date</Index.TableCell>
                                                                                                <Index.TableCell component='th' variant='th' className='table-th'>Actions</Index.TableCell>
                                                                                          </Index.TableRow>
                                                                                    </Index.TableHead>
                                                                                    <Index.TableBody className='table-body'>
                                                                                          {publishedtablerows.map((publishedtablerows) => (
                                                                                                <Index.TableRow
                                                                                                      key={publishedtablerows.name}
                                                                                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                                >
                                                                                                      <Index.TableCell component='td' variant='td' scope="row" className='table-td'>
                                                                                                            {publishedtablerows.SerialNumber}
                                                                                                      </Index.TableCell>
                                                                                                      <Index.TableCell component='td' variant='td' className='table-td'>{publishedtablerows.QrTitle}</Index.TableCell>
                                                                                                      <Index.TableCell component='td' variant='td' className='table-td'>{publishedtablerows.ProjectThumbnail}</Index.TableCell>
                                                                                                      <Index.TableCell component='td' variant='td' className='table-td'>{publishedtablerows.StartDate}</Index.TableCell>
                                                                                                      <Index.TableCell component='td' variant='td' className='table-td'>{publishedtablerows.EndDate}</Index.TableCell>
                                                                                                      <Index.TableCell component='td' variant='td' className='table-td'>{publishedtablerows.Actions}</Index.TableCell>
                                                                                                </Index.TableRow>
                                                                                          ))}
                                                                                    </Index.TableBody>
                                                                              </Index.Table>
                                                                        </Index.TableContainer>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="pagination-main">
                                                      <Index.Pagination count={3} variant="outlined" shape="rounded" className='pagination' />
                                                </Index.Box>
                                          </Index.Box>
                                    </TabPanel>
                                    <TabPanel value={value} index={1} className="user-tabpanel">
                                          <Index.Box className="tabpanel-main common-card">
                                                <Index.Box sx={{ width: 1 }} className="grid-main">
                                                      <Index.Box display="grid" className="display-row" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}>
                                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                                                                  <Index.Box className="user-search-flex">
                                                                        <Index.Box className="user-page-sub-title-box">
                                                                              <Index.Typography className='user-page-sub-title' component='h2' variant='h2'>Non Published</Index.Typography>
                                                                        </Index.Box>
                                                                        <Index.Box className="user-search-box">
                                                                              <Index.Box className="form-group">
                                                                                    <Index.TextField
                                                                                          fullWidth
                                                                                          id="fullWidth"
                                                                                          className="form-control"
                                                                                          placeholder="Search"
                                                                                    />
                                                                                    <img
                                                                                          src={PageIndex.Svg.search}
                                                                                          className="search-grey-img" alt='search grey img'
                                                                                    ></img>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                                  <Index.Box className="page-table-main qr-code-non-published-table">
                                                                        <Index.TableContainer component={Index.Paper} className='table-container'>
                                                                              <Index.Table aria-label="simple table" className='table' sx={{
                                                                                    "& .MuiTableRow-root:hover": {
                                                                                          backgroundColor: "#0000000a"
                                                                                    }
                                                                              }}>
                                                                                    <Index.TableHead className='table-head'>
                                                                                          <Index.TableRow className='table-row'>
                                                                                                <Index.TableCell component='th' variant='th' className='table-th'>SerialNumber</Index.TableCell>
                                                                                                <Index.TableCell component='th' variant='th' className='table-th'>QR Title</Index.TableCell>
                                                                                                <Index.TableCell component='th' variant='th' className='table-th'>Project Thumbnail</Index.TableCell>
                                                                                                <Index.TableCell component='th' variant='th' className='table-th'>Start Date</Index.TableCell>
                                                                                                <Index.TableCell component='th' variant='th' className='table-th'>End Date</Index.TableCell>
                                                                                                <Index.TableCell component='th' variant='th' className='table-th'>Actions</Index.TableCell>
                                                                                          </Index.TableRow>
                                                                                    </Index.TableHead>
                                                                                    <Index.TableBody className='table-body'>
                                                                                          {publishedtablerows.map((publishedtablerows) => (
                                                                                                <Index.TableRow
                                                                                                      key={publishedtablerows.name}
                                                                                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                                >
                                                                                                      <Index.TableCell component='td' variant='td' scope="row" className='table-td'>
                                                                                                            {publishedtablerows.SerialNumber}
                                                                                                      </Index.TableCell>
                                                                                                      <Index.TableCell component='td' variant='td' className='table-td'>{publishedtablerows.QrTitle}</Index.TableCell>
                                                                                                      <Index.TableCell component='td' variant='td' className='table-td'>{publishedtablerows.ProjectThumbnail}</Index.TableCell>
                                                                                                      <Index.TableCell component='td' variant='td' className='table-td'>{publishedtablerows.StartDate}</Index.TableCell>
                                                                                                      <Index.TableCell component='td' variant='td' className='table-td'>{publishedtablerows.EndDate}</Index.TableCell>
                                                                                                      <Index.TableCell component='td' variant='td' className='table-td'>{publishedtablerows.Actions}</Index.TableCell>
                                                                                                </Index.TableRow>
                                                                                          ))}
                                                                                    </Index.TableBody>
                                                                              </Index.Table>
                                                                        </Index.TableContainer>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="pagination-main">
                                                      <Index.Pagination count={3} variant="outlined" shape="rounded" className='pagination' />
                                                </Index.Box>
                                          </Index.Box>
                                    </TabPanel>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>

            </>
      )
}
