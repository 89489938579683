import React, { useState } from "react";
// import './subscription.css'
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import DeleteModal from "../../../../component/common/deletemodal/DeleteModal";
import ViewSubscription from "./ViewSubscription";

function createData(
  name,
  price,
  activeProjects,
  activeQR,
  action,
  description,
  id
) {
  return { name, price, activeProjects, activeQR, action, description, id };
}

export default function SubscriptionList() {
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  const updatedData = location?.state;
  const [viewData, setvViewData] = useState();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [openDeleteModal, setOpenDeleteModal] = useState();
  const handleAddSubscription = () => {
    navigate("/admin/subscription-plan/add");
  };
  const handleEditSubscription = (row) => {
    // navigate("/admin/subscription-plan/edit", { state: row });
    navigate(`/admin/subscription-plan/edit/${row.id}`, { state: row });
  };
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const [openViewSubscription, setOpenViewSubscription] = useState(false);
  const handleOpenViewSubscription = (data) => {
    setOpenViewSubscription(true);
    setvViewData(data);
  };
  const handleCloseViewSubscription = () => setOpenViewSubscription(false);
  const rows = [
    createData(
      "Subscription Name",
      "2000001",
      "120",
      "10",
      "",
      "no description yet",
      1
    ),
    createData(
      "Subscription Name",
      "100000",
      "120",
      "20",
      "",
      "no description yet",
      2
    ),
    createData(
      "Subscription Name",
      "300000",
      "120",
      "30",
      "",
      "no description yet",
      3
    ),
  ];
  const columns = [
    { field: "id", headerName: "Sr.No.",},
    { field: "name", headerName: "Name",},
    { field: "price", headerName: "Price",},
    { field: "activeProjects", headerName: "Active Projects",},
    { field: "activeQR", headerName: "Active QR",},
    {
      field: "action",
      headerName: "Action",
      disableColumnMenu:true, sortable:false,
      renderCell: (params) => (
        <Index.Box className="table-icon-flex">
            <Index.Button
             title="View"
              className="table-icon-btn"
              onClick={() => handleOpenViewSubscription(params.row)}
            >
              <img
                src={PageIndex.Svg.view}
                className="table-view-icons"
                alt=""
              />
            </Index.Button>
            <Index.Button
             title="Edit"
              className="table-icon-btn"
              onClick={() => handleEditSubscription(params.row)}
            >
              <img
                src={PageIndex.Svg.edit}
                alt=""
                className="table-view-icons"
              />
            </Index.Button>
            <Index.Button
             title="Delete"
              className="table-icon-btn"
              onClick={() => handleOpenDeleteModal()}
            >
              <img
                src={PageIndex.Svg.trash}
                className="table-view-icons"
                alt=""
              />
            </Index.Button>
        </Index.Box>
      ),
    },
  ];

  return (
    <>
      <Index.Box className="dashboard-content  admin-subscrition-list-wrape">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              Subscription Plans
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="userlist-inner-btn-flex">
              <PageIndex.PrimaryButton
                className="adduser-btn comman-primary-btn"
                title="Add Subscription plan"
                btnLabel="Add Subscription plan"
                imgSrc={PageIndex.Svg.plus}
                onClick={handleAddSubscription}
              />
            </Index.Box>
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                  />
                  <img
                    src={PageIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="user-dashboard-list-row common-card">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="user-dash-box">
                  <Index.Box className="page-table-main subscription-table-main">
                    <Index.Box className="data-table-main">
                      <Index.DataGrid
                        rows={rows}
                        // columns={columns}
                        columns={columns.map((col) => ({
                        ...col,
                        renderHeader: (params) => <PageIndex.CustomHeader {...params} column={col} />,
                      }))}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 15 },
                          },
                        }}
                        pageSizeOptions={[15, 20, 25]}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <DeleteModal
        openDeleteModal={openDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
      <ViewSubscription
        openViewSubscription={openViewSubscription}
        handleCloseViewSubscription={handleCloseViewSubscription}
        viewData={viewData}
      />
    </>
  );
}
