import React from 'react'
import Index from '../../../Index'
import PageIndex from '../../../PageIndex'
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

// for file upload

const VisuallyHiddenInput = styled('input')({
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
      bottom: 0,
      left: 0,
      whiteSpace: 'nowrap',
      width: 1,
});


export default function PublishedAndQrGenerated() {

      // for open handleChangedropdown 
      const [age, setAge] = React.useState(10);
      const handleChangedropdown = (event) => {
            setAge(event.target.value);
      };

      // for page navigatation
      const navigate = PageIndex.useNavigate()
      return (
            <>
                  <Index.Box className="dashboard-content qr-code-content">
                        {/* <Index.Box className="user-list-flex">
                              <Index.Box className="user-page-title-box">
                                    <Index.Typography
                                          className="user-page-title"
                                          component="h2"
                                          variant="h2"
                                    >
                                          Project Details(Published And QR Generated)
                                    </Index.Typography>
                              </Index.Box>
                        </Index.Box> */}
                        <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
            View  Project
            </Index.Typography>
          </Index.Box>
                        <Index.Box className="create-new-project-card common-card">
                              <Index.Box sx={{ width: 1 }} className="grid-main">
                                    <Index.Box display="grid" className="display-row" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                                          <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                                                <Index.Box className="input-box-auth">
                                                      <Index.Box className="form-group">
                                                            <Index.FormHelperText className="label-input-title">Project Title</Index.FormHelperText>
                                                            <Index.TextField
                                                                  fullWidth
                                                                  id="fullWidth"
                                                                  className="form-control"
                                                                  placeholder="Project Title"
                                                            />
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>

                                          <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                                                <Index.Box className="input-box-auth">
                                                      <Index.FormHelperText className="label-input-title">Description</Index.FormHelperText>
                                                      <Index.Box className="form-group">
                                                            <Index.TextareaAutosize
                                                                  aria-label="minimum height"
                                                                  // minRows={3}
                                                                  placeholder="Type here description"
                                                                  className="form-control-textarea"
                                                            />
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                                                <Index.Box className="add-tags-main">
                                                      <Index.Box className="add-tags-flex">
                                                            <Index.Box className="add-tags-inner-flex">
                                                                  <Index.Box className="add-tags-box">
                                                                        <Index.Typography className='add-tags-text'>Tags</Index.Typography>
                                                                  </Index.Box>
                                                                  <Index.Box className="add-tags-box">
                                                                        <Index.Typography className='add-tags-text'>Tags</Index.Typography>
                                                                  </Index.Box>
                                                                  <Index.Box className="add-tags-box">
                                                                        <Index.Typography className='add-tags-text'>Tags</Index.Typography>
                                                                  </Index.Box>
                                                                  <Index.Box className="add-tags-box">
                                                                        <Index.Typography className='add-tags-text'>Tags</Index.Typography>
                                                                  </Index.Box>
                                                                  {/* <Index.Box className="add-tags-box tags-addd-plus-main">
                                                                        <img src={PageIndex.Svg.plus} className='tag-upload-plus-icon' />
                                                                  </Index.Box> */}
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          {/* <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                                                <Index.Box className="input-box-auth">
                                                      <Index.FormHelperText className="label-input-title">Choose Project Thumbnail:</Index.FormHelperText>
                                                      <Index.Box className="file-upload-btn-main">
                                                            <Index.Button component="label" variant="contained" className='file-upload-btn'>
                                                                  <img src={PageIndex.Svg.plus} className='file-upload-plus-icon' />
                                                                  <VisuallyHiddenInput type="file" />

                                                            </Index.Button>
                                                            <Index.Typography className='choose-img-text'>Choose image from gallery</Index.Typography>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box> */}
                                          <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 3" }} className="grid-column">
                                                <Index.Box className="input-details-auth">
                                                      <Index.Box className="input-box-auth">
                                                            <Index.Box className="form-group">
                                                                  <Index.FormHelperText className="label-input-title">Serial Number <span className='mandatory-asterisk'>*</span></Index.FormHelperText>
                                                                  <Index.TextField
                                                                        fullWidth
                                                                        id="fullWidth"
                                                                        className="form-control"
                                                                        placeholder="PROJ12072023"
                                                                  />

                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 3" }} className="grid-column">
                                                <Index.Box className="input-details-auth">
                                                      <Index.Box className="input-box-auth">
                                                            <Index.Box className="form-group">
                                                                  <Index.FormHelperText className="label-input-title">Shape of the QR CODE<span className='mandatory-asterisk'>*</span></Index.FormHelperText>
                                                                  <Index.Box className="shap-text">Rectangle</Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 3" }} className="grid-column">
                                                <Index.Box className="input-details-auth">
                                                      <Index.Box className="input-box-auth">
                                                            <Index.Box className="form-group">
                                                                  <Index.FormHelperText className="label-input-title">Start Date<span className='mandatory-asterisk'>*</span></Index.FormHelperText>
                                                                  <Index.Box className="date-picker-main">
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                              <DemoContainer components={["DatePicker"]}>
                                                                                    <DatePicker className="form-control" />
                                                                              </DemoContainer>
                                                                        </LocalizationProvider>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 3" }} className="grid-column">
                                                <Index.Box className="input-details-auth">
                                                      <Index.Box className="input-box-auth">
                                                            <Index.Box className="form-group">
                                                                  <Index.FormHelperText className="label-input-title">End Date<span className='mandatory-asterisk'>*</span></Index.FormHelperText>
                                                                  <Index.Box className="date-picker-main">
                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                              <DemoContainer components={["DatePicker"]}>
                                                                                    <DatePicker
                                                                                          className="form-control"
                                                                                          format="MM/DD/YYYY"
                                                                                    />
                                                                              </DemoContainer>
                                                                        </LocalizationProvider>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 6", lg: "span 12" }} className="grid-column">
                                                {/* <Index.Box className="view-scanner-flex-main"> */}
                                                <Index.Box className="qrscanner-box-main">
                                                      <img src={PageIndex.Png.viewscanner} className='qrscanner-img' />
                                                </Index.Box>
                                                <Index.Box className="published-qr-content-main">
                                                      <Index.Box className="pulished-iocn-main">
                                                            <Index.Box className='dropdown-box view-qr-dropdown'>
                                                                  {/* <img src={PageIndex.Svg.settings} className='style-published-icons' /> */}
                                                                  <Index.FormControl className='form-control'>
                                                                        <Index.Select className='dropdown-select '
                                                                              value={age}
                                                                              onChange={handleChangedropdown}
                                                                              displayEmpty
                                                                              inputProps={{ 'aria-label': 'Without label' }}
                                                                        >
                                                                              <Index.MenuItem value={10} className='menuitem'>Simple</Index.MenuItem>
                                                                              <Index.MenuItem value={20} className='menuitem'>Styled</Index.MenuItem>
                                                                        </Index.Select>
                                                                  </Index.FormControl>
                                                            </Index.Box>
                                                            <Index.Box className="published-icoon-box" title="Share">
                                                                  <img src={PageIndex.Svg.share} className='published-icons' />
                                                                  <Index.Typography className='published-icon-text'>Share</Index.Typography>
                                                            </Index.Box>
                                                            <Index.Box className="published-icoon-box" title="Print">
                                                                  <img src={PageIndex.Svg.printicon} className='published-icons' />
                                                                  <Index.Typography className='published-icon-text'>Print</Index.Typography>
                                                            </Index.Box>
                                                            <Index.Box className="published-icoon-box" title="SVG">
                                                                  <img src={PageIndex.Svg.svgicon} className='published-icons' />
                                                                  <Index.Typography className='published-icon-text'>SVG</Index.Typography>
                                                            </Index.Box>
                                                            <Index.Box className="published-icoon-box" title="JPEG">
                                                                  <img src={PageIndex.Svg.jpegicon} className='published-icons' />
                                                                  <Index.Typography className='published-icon-text'>JPEG</Index.Typography>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                          <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                                                <Index.Box className="user-btn-flex">
                                                      <PageIndex.PrimaryButton className='create-user-btn comman-primary-btn' btnLabel="Cancel" title="Cancel" onClick={()=>navigate("/user/project-directory")}
                                                      />
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>

            </>
      )
}
