import { createSlice } from "@reduxjs/toolkit";
import PageIndex from "../../../../container/PageIndex";

export const AdminSlice = createSlice({
  name: "admin",
  initialState: {
    adminToken: "",
    isAuthenticated: false,
    isLoading: false,
    adminData: {},
    mode: false,
  },
  reducers: {
    getAdminData: (state, action) => {
      state.adminData = action.payload;
    },
    setdarkMode: (state, action) => {
      state.mode = action.payload;
    },
    adminLogout: (state, action) => {
      state.adminToken = "";
      state.isAuthenticated = false;
      state.isLoading = false;
      state.adminData = {};
      
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PageIndex.adminLogin.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.adminData = action?.payload?.data;
      state.adminToken = action?.payload?.data?.token;
    });
  },
});

export const { getAdminData, adminLogout, setdarkMode } = AdminSlice.actions;

export default AdminSlice.reducer;
