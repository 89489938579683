const Api = {
  Admin: {
    Auth: {
      login: "admin/admin-login",
      forgotPassword: "admin/forgot-password",
      verifyOtp: "admin/verify-otp",
      resetPassword: "admin/reset-password",
      changePassword: "admin/change-password",
      updateProfile: "admin/update-profile",
    },
    UserList: {
      get: "admin/get-userlist",
      getSingle: "admin/get-singleuser/",
      updateStatus: "admin/change-userstatus",
      getRecentUsers: "admin/get-recentaddedUserlist",
    },
  },
  User: {
    Auth: {
      userLogin: "user/user-login",
      userRegister: "user/user-signup",
      userForgotPassword: "user/user-forgotpassword",
      userVerifyOTP: "user/user-otpverify",
      userResendOTP: "user/user-resendotp",
      userResetPassword: "user/user-resetpassword",
      checkUser: "user/check-user",
      userUpdateProfile: "user/user-updateprofile",
      userChangePassword: "user/user-changepassword",
      checkUserVerifyOtp:"user/user-check-otpverify",
    },
  },
};
export default Api;
