import React from "react";
import PageIndex from "../../../PageIndex";
import Index from "../../../Index";
import ReCAPTCHA from "react-google-recaptcha";


export default function ForgotPassword() {
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();

  let initialValues = {
    email: "",
    isLoginPage: false,
    isVerifyCaptcha: false,
    isSubmit: false,
  };
  const onCaptchaChange = (value, setFieldValue) => {
    if (value) {
      setFieldValue("isVerifyCaptcha", true);
    } else {
      setFieldValue("isVerifyCaptcha", false);
    }
  };
  const handleSubmit = (values, { setFieldValue }) => {
    setFieldValue("isSubmit", true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", values.email);
    dispatch(PageIndex.adminForgotPassword(urlencoded)).then((res) => {
      if (res?.payload?.status == 200) {
        const id = res?.payload?.data?._id;
        setTimeout(() => {
          navigate("/admin/otp", { state: id });
        }, 1000);
      } else {
        setTimeout(()=> setFieldValue("isSubmit", false),3000) 
      }
    });
  };
  return (
    <>
      <Index.Box className="login-user-screen">
        <Index.Box className="auth-user-row">
          <Index.Box className="auth-row-col-left">
            <Index.Box className="dark-gray">
              <Index.Box className="auth-bg-content"></Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="auth-row-col-right">
            <Index.Box className="light-gray">
              <Index.Box className="auth-page-bg login-content">
                <Index.Box className="auth-bg-pd">
                  <Index.Box className="auth-title-content">
                    <Index.Typography
                      component="h6"
                      variant="h6"
                      className="signin-title"
                    >
                      Forgot Password ?
                    </Index.Typography>
                  </Index.Box>
                  <PageIndex.Formik
                    initialValues={initialValues}
                    validationSchema={PageIndex.adminLoginValidationSchema(
                      initialValues
                    )}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      setFieldValue
                    }) => (
                      <PageIndex.Form>
                        <Index.Box className="auth-signin-details">
                          <Index.Box className="input-details-auth">
                            <Index.Box className="input-box-auth">
                              <Index.Box className="form-group">
                                <Index.FormHelperText className="label-input-title">
                                  Email
                                  <span className="mandatory-asterisk">*</span>
                                </Index.FormHelperText>
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  placeholder="Email"
                                  name="email"
                                  value={values?.email}
                                  onChange={(e) => {
                                              const newValue =
                                                e.target.value.replace(
                                                  /\s/g,
                                                  ""
                                                );
                                              setFieldValue("email", newValue);
                                            }}
                                  onBlur={handleBlur}
                                />
                                {errors.email && touched.email && (
                                  <p className="error-text">{errors.email}</p>
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="captach-main  admin-captach-main login-captach-main">
                            <ReCAPTCHA
                              sitekey={
                                process.env.REACT_APP_RE_CAPTCHA_SITE_KEY
                              }
                              onChange={(e) => {
                                onCaptchaChange(e, setFieldValue);
                              }}
                              className="captach-checkbox"
                            />
                          </Index.Box>
                          <Index.Box className="auth-back-flex">
                            <Index.Box className="auth-primary-btn">
                              <PageIndex.PrimaryButton
                                btnLabel="Send OTP"
                                type="submit"
                                disabled={
                                values?.isSubmit || !values?.isVerifyCaptcha
                              }
                              startIcon={
                                values?.isSubmit && (
                                  <Index.CircularProgress
                                    size={15}
                                    color="inherit"
                                  />
                                )
                              }
                              className={
                                !values?.isVerifyCaptcha
                                  ? "comman-primary-btn login-btn disabled-login-button"
                                  : "comman-primary-btn login-btn"
                              }
                              />
                            </Index.Box>
                            <Index.Box className="back-text-main">
                              <Index.Link
                                className="back-text"
                                to="/admin/login"
                              >
                                Back to login
                              </Index.Link>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </PageIndex.Form>
                    )}
                  </PageIndex.Formik>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
