import React from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

export default function AddEditSubscription() {
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  const editData = location?.state;
  
  const initialValues = {
    name: editData ? editData?.name : "",
    price: editData ? editData?.price : "",
    activeProjects: editData ? editData?.activeProjects : "",
    activeQR: editData ? editData?.activeQR : "",
    description: editData ? editData?.description : "",
  };
const handleSubmit=(values)=>{
  navigate("/admin/subscription-plan",{state:{values}})
}
  return (
    <>
      <Index.Box className="dashboard-content subscription-add-Edit-page">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              {editData ? "Edit Subscription" : "Add Subscription"}
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <PageIndex.Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <PageIndex.Form>
        <Index.Box className="create-new-project-card subscription-create-new-project-card common-card">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box-auth subscription-flex">
                  <Index.Box className="subscription-flex-left">
                    <Index.FormHelperText className="label-input-title">
                      Title/Name:
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="subscription-flex-right">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="name"
                        value={values.name}
                        className="form-control"
                        type="text"
                        placeholder="Name"
                        onChange={handleChange}
                            onBlur={handleBlur}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box-auth subscription-flex">
                  <Index.Box className="subscription-flex-left">
                    <Index.FormHelperText className="label-input-title">
                      Price:
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="subscription-flex-right">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        name="price"
                        value={values.price}
                        className="form-control"
                        type="number"
                        placeholder="Price"
                        onChange={handleChange}
                            onBlur={handleBlur}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box-auth subscription-flex">
                  <Index.Box className="subscription-flex-left">
                    <Index.FormHelperText className="label-input-title">
                      Active Projects:
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="subscription-flex-right">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        value={values.activeProjects}
                        className="form-control"
                        type="number"
                        name="activeProjects"
                        placeholder="Please enter Number of Active Projects"
                        onChange={handleChange}
                            onBlur={handleBlur}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box-auth subscription-flex">
                  <Index.Box className="subscription-flex-left">
                    <Index.FormHelperText className="label-input-title">
                      Active QR:
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="subscription-flex-right">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        value={values.activeQR}
                        className="form-control"
                        type="number"
                        name="activeQR"
                        placeholder="Please enter Number of Active QR"
                        onChange={handleChange}
                            onBlur={handleBlur}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box-auth subscription-flex">
                  <Index.Box className="subscription-flex-left">
                    <Index.FormHelperText className="label-input-title">
                      Description:
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="subscription-flex-right">
                    <Index.Box className="form-group">
                      <Index.TextareaAutosize
                        aria-label="minimum height"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                            onBlur={handleBlur}
                        // minRows={3}
                        placeholder="Type here description"
                        className="form-control-textarea"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="user-btn-flex">
                  <PageIndex.PrimaryButton
                    className="create-user-btn comman-primary-btn"
                    title={editData ? "Save" : "Add"}
                    btnLabel={editData ? "Save" : "Add"}
                    // onClick={() => navigate("/admin/subscription-plan")}
                    type="submit"
                    imgSrc={PageIndex.Svg.plus}
                  />
                    <PageIndex.BorderButton
                    className="cancel-user-btn border-btn"
                    onClick={() => navigate("/admin/subscription-plan")}
                    btnLabel="Cancel"
                    title="Cancel"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        </PageIndex.Form>
          )}
          </PageIndex.Formik>
      </Index.Box>
    </>
  );
}
