import React from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function PublishedQrCode(props) {
  // for published qr code modal
  const { openPublishedQrCode, handleClosePublishedQrCode } = props;

  // const [openPublishedQrCode, setOpenPublishedQrCode] = React.useState(false);
  // const handleOpenPublishedQrCode = () => setOpenPublishedQrCode(true);
  // const handleClosePublishedQrCode = () => setOpenPublishedQrCode(false);

  // for open handleChangedropdown
  const [age, setAge] = React.useState("");
  const handleChangedropdown = (event) => {
    setAge(event.target.value);
  };

  const navigate = PageIndex.useNavigate();
  return (
    <>
      {/* <Index.Button onClick={handleOpenPublishedQrCode}>Open modal</Index.Button> */}
      <Index.Modal
        open={openPublishedQrCode}
        onClose={handleClosePublishedQrCode}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="common-modal"
      >
        <Index.Box
          sx={style}
          className="common-modal-inner publish-qr-modal-inner"
        >
          <img
            src={PageIndex.Svg.close}
            className="modal-close-icon"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleClosePublishedQrCode();
              }
            }}
            tabIndex={0}
            onClick={handleClosePublishedQrCode}
            title="Close"
          />
          <Index.Box className="save-publish-modal-content">
            <Index.Typography className="modal-title-text">
              Published QR Code
            </Index.Typography>
          </Index.Box>
          <Index.Box className="qr-code-generation-section">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="qrscanner-box-main">
                    <img
                      src={PageIndex.Png.viewscanner}
                      className="qrscanner-img"
                    />
                  </Index.Box>
                  <Index.Box className="published-qr-content-main">
                    <Index.Box className="pulished-iocn-main">
                      <Index.Box className="dropdown-box published-qr-dropdown">
                        {/* <img src={PageIndex.Svg.settings} className='style-published-icons' /> */}
                        <Index.FormControl className="form-control">
                          <Index.Select
                            className="dropdown-select "
                            value={age}
                            onChange={handleChangedropdown}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <Index.MenuItem value="" className="menuitem">
                              Simple
                            </Index.MenuItem>
                            <Index.MenuItem value={20} className="menuitem">
                              Styled
                            </Index.MenuItem>
                          </Index.Select>
                        </Index.FormControl>
                      </Index.Box>
                      <Index.Box className="published-icoon-box" title="Sahre">
                        <img
                          src={PageIndex.Svg.share}
                          className="published-icons"
                        />
                        <Index.Typography className="published-icon-text">
                          Share
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="published-icoon-box" title="Print">
                        <img
                          src={PageIndex.Svg.printicon}
                          className="published-icons"
                        />
                        <Index.Typography className="published-icon-text">
                          Print
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="published-icoon-box" title="SVG">
                        <img
                          src={PageIndex.Svg.svgicon}
                          className="published-icons"
                        />
                        <Index.Typography className="published-icon-text">
                          SVG
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="published-icoon-box" title="JPEG">
                        <img
                          src={PageIndex.Svg.jpegicon}
                          className="published-icons"
                        />
                        <Index.Typography className="published-icon-text">
                          JPEG
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="published-succses-main">
                    <img
                      src={PageIndex.Svg.closecircle}
                      className="upload-succsess-icon"
                    />
                    <Index.Typography className="published-para-text">
                      To view the work on your mobile device please go to this
                      link or scan the QR CODE or the side.
                    </Index.Typography>
                    <Index.Box className="input-details-auth copy-input-box">
                      <Index.Box className="input-box-auth">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            placeholder="Enter your code"
                            id="fullWidth"
                            className="form-control"
                          />
                          <img
                            title="Copy"
                            src={PageIndex.Svg.copy}
                            className="copy-icon"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Typography className="published-para-text">
                      Check your mail inbox for the QR Code publish
                      confirmation.
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="user-btn-flex">
                    <PageIndex.PrimaryButton
                      className="create-user-btn comman-primary-btn"
                      btnLabel="OK"
                      title="OK"
                      onClick={() => {
                        navigate("/user/qr-code-list?tabId=0");
                      }}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
