import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import "./adminDashboard.css";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { useEffect, useState } from "react";

// for recent project table data

function createData(ProjectThumbnail, ProjectTitle, Actions) {
  return { ProjectThumbnail, ProjectTitle, Actions };
}

export default function AdminDashboard() {
  // for page navigation
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const [creatorsData, setCreatorsData] = useState("");
  const [loading, setLoading] = useState(true);
  // for recent project table data
  const rows = [
    createData(
      <img
        src={PageIndex.Svg.thumbnail}
        className="table-thumbnail-icons"
        alt=""
      />,
      "Project Title",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/admin/analytic");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
          </Index.Button>
      </Index.Box>
    ),

    createData(
      <img
        src={PageIndex.Svg.thumbnail}
        className="table-thumbnail-icons"
        alt=""
      />,
      "Project Title",
      <Index.Box className="table-icon-flex">
        <Index.Button
         title="View"
          className="table-icon-btn"
          onClick={() => {
            navigate("/admin/analytic");
          }}
        >
          <img src={PageIndex.Svg.view} className="table-view-icons" alt="" />
        </Index.Button>{" "}
      </Index.Box>
    ),

    createData(
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "Project Title",
      <Index.Box className="table-icon-flex">
        <Index.Button
         title="View"
          className="table-icon-btn"
          onClick={() => {
            navigate("/admin/analytic");
          }}
        >
          <img src={PageIndex.Svg.view} className="table-view-icons" />
        </Index.Button>{" "}
      </Index.Box>
    ),

    createData(
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "Project Title",
      <Index.Box className="table-icon-flex">
        <Index.Button
         title="View"
          className="table-icon-btn"
          onClick={() => {
            navigate("/admin/analytic");
          }}
        >
          <img src={PageIndex.Svg.view} className="table-view-icons" />
        </Index.Button>{" "}
      </Index.Box>
    ),

    createData(
      <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
      "Project Title",
      <Index.Box className="table-icon-flex">
          <Index.Button
           title="View"
            className="table-icon-btn"
            onClick={() => {
              navigate("/admin/analytic");
            }}
          >
            <img src={PageIndex.Svg.view} className="table-view-icons" />
          </Index.Button>{" "}
      </Index.Box>
    ),
  ];

  const getTopCreators = () => {
    dispatch(PageIndex.getRecentUsers()).then((res) => {
      if (res?.payload?.status == 200) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setCreatorsData(res?.payload?.data);
      } else {
        setLoading(false);
        setCreatorsData([]);
      }
    });
  };

  useEffect(() => {
    getTopCreators();
  }, []);


  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-page-title-box">
          <Index.Typography
            className="user-page-title"
            component="h2"
            variant="h2"
          >
            Admin Dashboard
          </Index.Typography>
        </Index.Box>
        <Index.Box className="user-dashboad-row">
          <Index.Box sx={{ width: 1 }} className="grid-main stat-boxes">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 1 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 4",
                  lg: "span 4",
                  xl: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="user-dashboard-box">
                  <Index.Box className="user-dashboard-inner-box" title="Active Projects">
                    <Index.Box className="user-dashbaord-card-flex">
                      <Index.Box className="user-dashbaord-card-left">
                        <Index.Typography
                          className="user-dash-text"
                          variant="p"
                          component="p"
                        >
                          Active Projects
                        </Index.Typography>
                        <Index.Typography
                          className="user-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          274
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-dashbaord-card-right">
                        <img
                          src={PageIndex.Svg.dashboard1}
                          className="dashboard-icon"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 4",
                  lg: "span 4",
                  xl: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="user-dashboard-box">
                  <Index.Box className="user-dashboard-inner-box" title="Total QR Codes">
                    <Index.Box className="user-dashbaord-card-flex">
                      <Index.Box className="user-dashbaord-card-left">
                        <Index.Typography
                          className="user-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total QR Codes
                        </Index.Typography>
                        <Index.Typography
                          className="user-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          3403
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-dashbaord-card-right">
                        <img
                          src={PageIndex.Svg.dashboard2}
                          className="dashboard-icon"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 4",
                  lg: "span 4",
                  xl: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="user-dashboard-box">
                  <Index.Box className="user-dashboard-inner-box" title="QR Codes Created Today">
                    <Index.Box className="user-dashbaord-card-flex">
                      <Index.Box className="user-dashbaord-card-left">
                        <Index.Typography
                          className="user-dash-text"
                          variant="p"
                          component="p"
                        >
                          QR Codes Created Today
                        </Index.Typography>
                        <Index.Typography
                          className="user-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-dashbaord-card-right">
                        <img
                          src={PageIndex.Svg.dashboard3}
                          className="dashboard-icon"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 4",
                  lg: "span 4",
                  xl: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="user-dashboard-box">
                  <Index.Box className="user-dashboard-inner-box" title="24 Hrs Total Scans">
                    <Index.Box className="user-dashbaord-card-flex">
                      <Index.Box className="user-dashbaord-card-left">
                        <Index.Typography
                          className="user-dash-text"
                          variant="p"
                          component="p"
                        >
                          24 Hrs Total Scans
                        </Index.Typography>
                        <Index.Typography
                          className="user-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          129
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-dashbaord-card-right">
                        <img
                          src={PageIndex.Svg.dashboard4}
                          className="dashboard-icon"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 4",
                  lg: "span 4",
                  xl: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box
                  className="user-dashboard-box"
                  onClick={() => {
                    navigate("/admin/analytic");
                  }}
                >
                  <Index.Box className="user-dashboard-inner-box" title=" 24hr Top Project">
                    <Index.Box className="user-dashbaord-card-flex">
                      <Index.Box className="user-dashbaord-card-left">
                        <Index.Typography
                          className="user-dash-text"
                          variant="p"
                          component="p"
                        >
                          24hr Top Project
                        </Index.Typography>
                        <Index.Typography
                          className="user-dash-title"
                          variant="h1"
                          component="h1"
                        >
                          Project Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-dashbaord-card-right">
                        <img
                          src={PageIndex.Svg.thumbnail}
                          className="dashboard-icon dark-to-light-img"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 4",
                  lg: "span 4",
                  xl: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box
                  className="user-dashboard-box"
                  onClick={() => {
                    navigate("/admin/analytic");
                  }}
                >
                  <Index.Box className="user-dashboard-inner-box" title="30 Days Top Project">
                    <Index.Box className="user-dashbaord-card-flex">
                      <Index.Box className="user-dashbaord-card-left">
                        <Index.Typography
                          className="user-dash-text"
                          variant="p"
                          component="p"
                        >
                          30 Days Top Project
                        </Index.Typography>
                        <Index.Typography
                          className="user-dash-title"
                          variant="h1"
                          component="h1"
                        >
                          Project Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-dashbaord-card-right">
                        <img
                          src={PageIndex.Svg.thumbnail}
                          className="dashboard-icon dark-to-light-img"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="recent-project-section">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 6",
                  xl: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="user-page-sub-title-box">
                  <Index.Typography
                    className="user-page-sub-title"
                    component="h2"
                    variant="h2"
                  >
                    Recent Project
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="user-dash-box common-card">
                  <Index.Box className="page-table-main recent-project-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        aria-label="simple table"
                        className="table"
                        sx={{
                          "& .MuiTableRow-root:hover": {
                            backgroundColor: "#0000000a",
                          },
                        }}
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                                title="Project Thumbnail"
                              variant="th"
                              className="table-th"
                            >
                              Project Thumbnail
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                                title="Project Title"
                              className="table-th"
                            >
                              Project Title
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              title="Action"
                              className="table-th"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {rows.map((row) => (
                            <Index.TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row.ProjectThumbnail}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.ProjectTitle}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.Actions}
                              </Index.TableCell>
                            </Index.TableRow>
                          ))}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                  {/* <Index.Box className="pagination-main">
                    <Index.Pagination count={3} variant="outlined" shape="rounded" className='pagination' />
                  </Index.Box> */}
                </Index.Box>
              </Index.Box>
              {loading ? (
                <PageIndex.Loader className="component-loader" />
              ) : (
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 6",
                    xl: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="user-page-sub-title-box">
                    <Index.Typography
                      className="user-page-sub-title"
                      component="h2"
                      variant="h2"
                    >
                      Top Creators
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="user-dash-box common-card">
                    <Index.Box className="page-table-main recent-project-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          aria-label="simple table"
                          className="table"
                          sx={{
                            "& .MuiTableRow-root:hover": {
                              backgroundColor: "#0000000a",
                            },
                          }}
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                title="Company Logo"
                                className="table-th"
                              >
                                Company Logo
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                title="Company Title"
                                className="table-th"
                              >
                                Company Title
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                title="Action"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {creatorsData.map((row) => (
                              <Index.TableRow
                                key={row?._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  <img
                                    src={
                                      row.profile
                                        ? row.profile instanceof Blob
                                          ? URL.createObjectURL(row.profile)
                                          : `${process.env.REACT_APP_API_BASE_IMAGE_URL}${row.profile}`
                                        : row.logo
                                        ? row.logo instanceof Blob
                                          ? URL.createObjectURL(row.logo)
                                          : `${process.env.REACT_APP_API_BASE_IMAGE_URL}${row.logo}`
                                        : PageIndex.Png.profileLogo
                                    }
                                    alt="Image Preview"
                                    className="table-thumbnail-icons top-creators-icons"
                                  />
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row?.companyName
                                    ? row?.companyName
                                    : row?.firstName}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                
                                  <Index.Box className="table-icon-flex">
                                    <Index.Button
                                     title="View"
                                      className="table-icon-btn"
                                      onClick={() => {
                                        navigate(
                                          "/admin/user-management/user-details",
                                          { state: { row } }
                                        );
                                      }}
                                    >
                                      <img
                                        src={PageIndex.Svg.view}
                                        className="table-view-icons"
                                      />
                                    </Index.Button>{" "}
                                  </Index.Box>
                                  
                                </Index.TableCell>
                              </Index.TableRow>
                            ))}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                    {/* <Index.Box className="pagination-main">
                    <Index.Pagination count={3} variant="outlined" shape="rounded" className='pagination' />
                  </Index.Box> */}
                  </Index.Box>
                </Index.Box>
              )}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
