// import React from 'react'
import React from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Subscription(props) {
  const { openSubscription, handleCloseSubscription } = props;

  // for Subscription modal
  // const [openSubscription, setOpenSubscription] = React.useState(false);
  // const handleOpenSubscription = () => setOpenSubscription(true);
  // const handleCloseSubscription = () => setOpenSubscription(false);

  // for page navigation
  const navigate = PageIndex.useNavigate();

  return (
    <>
      {/* <Index.Button onClick={handleOpenSubscription}>Open modal</Index.Button> */}
      <Index.Modal
        open={openSubscription}
        onClose={handleCloseSubscription}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="common-modal"
      >
        <Index.Box
          sx={style}
          className="common-modal-inner subscription-modal-inner"
        >
          <img
            src={PageIndex.Svg.close}
            className="modal-close-icon"
            title="Close"
            onClick={() => {
              handleCloseSubscription();
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                  handleCloseSubscription();
              }
            }}
            tabIndex={0}
          />
          <Index.Box className="save-publish-modal-content">
            <Index.Typography className="modal-title-text">
              Current Plan
            </Index.Typography>
          </Index.Box>
          <Index.Box className="subscript-box">
            <Index.Box className="publish-radio-content-main active-plan">
              <Index.Typography className="publish-radio-title">
                Plan 1
              </Index.Typography>
              <Index.Typography className="publish-radio-para">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                a lectus vitae tortor ultrices aliquam. Sed porttitor velit eu
                risus ultricies, sit amet consectetur justo ultrices.
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="user-page-sub-title-box">
            <Index.Typography
              className="user-page-sub-title"
              component="h2"
              variant="h2"
            >
              Choose Plan to Upgrade
            </Index.Typography>
          </Index.Box>
          <Index.Box className="publish-radio-flex">
            <Index.Box className="publish-radio-box">
              <Index.Box className="publish-radio-content-main">
                <Index.Typography className="publish-radio-title">
                  Plan 2
                </Index.Typography>
                <Index.Typography className="publish-radio-para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Mauris a lectus vitae tortor ultrices aliquam. Sed porttitor
                  velit eu risus ultricies, sit amet consectetur justo ultrices.
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="publish-radio-box">
              <Index.Box className="publish-radio-content-main">
                <Index.Typography className="publish-radio-title">
                  Plan 3
                </Index.Typography>
                <Index.Typography className="publish-radio-para">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Mauris a lectus vitae tortor ultrices aliquam. Sed porttitor
                  velit eu risus ultricies, sit amet consectetur justo ultrices.
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="modal-user-btn-flex">
            <PageIndex.PrimaryButton
              className="create-user-btn comman-primary-btn"
              btnLabel="Upgrade"
              title="Upgrade"
            />
            <PageIndex.BorderButton
              className="cancel-user-btn border-btn"
              btnLabel="Cancel"
              title="Cancel"
              onClick={() => {
                handleCloseSubscription();
              }}
            />
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
