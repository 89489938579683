import { styled, alpha } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import PageIndex from "../../PageIndex";
import Index from "../../Index";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function MyApp() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  return (
    <Index.Box className="dark-light-btn-main">
      {/* {theme.palette.mode} */}
      <Index.IconButton
        onClick={colorMode.toggleColorMode}
        color="inherit"
        className="dark-light-btn"
      >
        {theme.palette.mode === "dark" ? (
          <Index.Brightness7Icon />
        ) : (
          <Index.Brightness4Icon />
        )}
      </Index.IconButton>
    </Index.Box>
  );
}

export default function Header(props) {
  const dispatch = PageIndex.useDispatch();
  const navigate = PageIndex.useNavigate();
  const { userData, darkMode } = PageIndex.useSelector((state) => state.user);
  const profile = userData?.profile;
  const logo = userData?.logo;
  // for open in header profile menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = styled((props) => (
    <Index.Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 3,
      marginTop: theme.spacing(1),
      minWidth: 258,
      paddingTop: 30,
      paddingBottom: 0,
      paddingLeft: 23,
      // marginTop: 38,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow: "0px 3px 6px #00000029",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  // for darkmode and lightmode
  const [mode, setMode] = React.useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );
  const handleLogout = () => {
    navigate("/");

    dispatch(PageIndex.userLogout());
    localStorage.setItem("userLogin", false);

  };

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <>
      <Index.Box className={isActive ? "your_className" : null}>
        <Index.Box
          className={`user-header-main ${
            props.open ? "active" : "user-header-deactive"
          }`}
        >
          <Index.Box className="user-header-left">
            <Index.Box className="user-header-logo-main">
              <img
                  src={
                    logo
                      ? logo instanceof Blob
                        ? URL.createObjectURL(logo)
                        : `${process.env.REACT_APP_API_BASE_IMAGE_URL}${logo}`
                      : PageIndex.Png.logo
                  }
                  className="user-sidebar-logo mobile-show"
                  alt="logo"
                ></img>
              <Index.Button
                className="user-bergur-button"
                onClick={() => {
                  props.setOpen(!props.open);
                  document.body.classList[props.open ? "add" : "remove"](
                    "body-overflow"
                  );
                }}
              >
                <img
                  src={PageIndex.Svg.bergurmenu}
                  className="bergurmenu-icon dark-to-light-img"
                  alt="dashboard logo"
                ></img>
              </Index.Button>
            </Index.Box>
            <Index.Box className="user-header-right dark-light-btn-main">
              <Index.Button className="user-header-icon-box" title="Notification">
                <img
                  src={PageIndex.Svg.notification}
                  className="user-header-icon dark-to-light-img"
                  alt="dashboard logo"
                ></img>
              </Index.Button>
              {/* <Index.Button className="user-header-icon-box">
                <ColorModeContext.Provider value={colorMode}>
                  <ThemeProvider theme={theme}>
                    <MyApp />
                  </ThemeProvider>
                </ColorModeContext.Provider>
              </Index.Button> */}
               <Index.Button  className="user-header-icon-box dark-light-btn" title="Switch Mode" onClick={props.toggleDarkMode}>
                {darkMode ? (
                  <Index.Brightness7Icon  title="Switch to Light Mode" />
                ) : (
                  <Index.Brightness4Icon  title="Switch to Dark Mode" />
                )}
              </Index.Button>
              <Index.Box className="user-header-drop-main">
                <Index.Button
                 title="Profile"
                  className="drop-header-btn"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <Index.Box className="flex-drop-main">
                    <img
                      src={
                        profile
                          ? profile instanceof Blob
                            ? URL.createObjectURL(profile)
                            : `${process.env.REACT_APP_API_BASE_IMAGE_URL}${profile}`
                          : PageIndex.Png.usericon
                      }
                      className={
                        darkMode
                          ? "user-header-profile-icon  dark-to-light-img"
                          : "user-header-profile-icon"
                      }
                      alt="dashboard bell icon"
                    ></img>
                    <Index.Box className="title-user-drop">
                      {/* <Index.Typography variant="h5" component="h5" className='user-header-drop'>user</Index.Typography> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Button>
              </Index.Box>
              <Index.Menu
                className={
                  darkMode ? "drop-header-menu  dark-theme" : "drop-header-menu"
                }
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <Index.MenuItem
                  title="My Profile"
                  className="drop-header-menuitem"
                  onClick={() => {
                    navigate("/user/profile");
                    handleClose();
                  }}
                >
                  {" "}
                  <img
                    src={PageIndex.Svg.profilegrey}
                    className="drop-header"
                  />{" "}
                  My Profile
                </Index.MenuItem>

                <Index.MenuItem
                  title="Payment"
                  className="drop-header-menuitem"
                  onClick={() => {
                    navigate("/user/payments");
                    handleClose();
                  }}
                >
                  {" "}
                  <img src={PageIndex.Svg.payment} className="drop-header" />
                  Payment
                </Index.MenuItem>

                <Index.MenuItem
                  title="Log Out"
                  className="drop-header-menuitem"
                  onClick={handleLogout}
                >
                  {" "}
                  <img
                    src={PageIndex.Svg.logout}
                    className="drop-header"
                  />{" "}
                  Log Out
                </Index.MenuItem>
              </Index.Menu>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
