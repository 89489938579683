import React from 'react'
import Index from '../../../Index'
import PageIndex from '../../../PageIndex'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BulkQrSuccessfully(props) {
    // BulkQrSuccessfully modal

    const { openBulkQrSuccessfully, handleCloseBulkQrSuccessfully } = props
    // const [openBulkQrSuccessfully, setOpenBulkQrSuccessfully] = React.useState(false);
    // const handleOpenBulkQrSuccessfully = () => setOpenBulkQrSuccessfully(true);
    // const handleCloseBulkQrSuccessfully = () => setOpenBulkQrSuccessfully(false);

        // for page navigatation
  const navigate = PageIndex.useNavigate()
    return (

        <>
            {/* <Index.Button onClick={handleOpenBulkQrSuccessfully}>Open modal</Index.Button> */}
            <Index.Modal
                open={openBulkQrSuccessfully}
                onClose={handleCloseBulkQrSuccessfully}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='common-modal'
            >
                <Index.Box sx={style} className='common-modal-inner bulk-successfully-inner'>
                    <img src={PageIndex.Svg.close} className='modal-close-icon' />
                    <Index.Box className="save-publish-modal-content">
                        <Index.Typography className='modal-title-text'>Bulk QR Generaed successfully</Index.Typography>
                    </Index.Box>
                    <Index.Box className="bulk-successfully-card-main">
                        <img src={PageIndex.Svg.closecircle} className='successfully-icon' />
                    </Index.Box>
                    <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                        <Index.Box className="user-btn-flex">
                            <PageIndex.PrimaryButton className='create-user-btn comman-primary-btn' btnLabel="Ok" onClick={() => {
                                        navigate("/user/qr-code")
                                    }}/>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal>



        </>
    )
}
