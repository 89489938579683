import axios from "axios";
import { useNavigate } from "react-router-dom";

import PageIndex from "../container/PageIndex";

// const END_POINT="http://localhost:3039/api/"
const DataService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // baseURL: END_POINT,
});

export default DataService;

//Get
export const doGet = async (url) => {
  try {
    const response = await DataService.get(url);
    if (response?.data?.status == 200) {
      //   PageIndex.toast.success();
      return response;
    }
  } catch (error) {
    if(error?.response?.data?.status !== 401){
    PageIndex.toast.error(error?.response?.data?.message);
    }
  }
};

//Get Params
export const doGetParams = async (url,id) => {
  try {
    const response = await DataService.get(`${url}${id}`);
    if (response?.data?.status == 200) {
      //   PageIndex.toast.success();
      return response;
    }
  } catch (error) {
    PageIndex.toast.error(error?.response?.data?.message);
  }
};

//Post
export const doPost = async (url, data) => {
  try {
    const response = await DataService.post(url, data);
    if (response?.data?.status == 200 || 201) {
      PageIndex.toast.success(response?.data?.message);
      return response;
    }
  } catch (error) {
    if(error?.response?.data?.status !== 401){
    PageIndex.toast.error(error?.response?.data?.message);
    }
  }
};

//Form Data Post
export const doPostFormData = async (url, data) => {
  try {
    const response = await DataService.post(url, data);
    if (response?.data?.status == 200 || 201) {
      PageIndex.toast.success(response?.data?.message);
      return response;
    }
  } catch (error) {
    PageIndex.toast.error(error?.response?.data?.message);
  }
};
