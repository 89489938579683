import React, { useState } from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PageIndex";

function createPaymentData(paymentId, mode, date, subscriptionPlan, amount,id) {
  return {
    paymentId,
    mode,
    date,
    subscriptionPlan,
    amount,
    id
  };
}
const columns = [
  { field: "id", headerName: "Sr.No.",},
  { field: "paymentId", headerName: "Payment Id",},
  { field: "mode", headerName: "Mode",},
  { field: "date", headerName: "Date",},
  { field: "subscriptionPlan", headerName: "Subscription Plan",},
  { field: "amount", headerName: "Amount($)",},

 
];
const paymentData = [
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",1),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",2),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",3),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",4),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",5),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",6),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",7),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",8),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",9),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",10),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",11),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",12),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",13),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",14),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",15),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",16),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",17),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",18),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",19),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",20),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",21),
  createPaymentData("10192", "Paypal", "01/10/2023", "Plan name", "120",22),
];
const UserPaymentDetails = () => {
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState(paymentData.length);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Index.Box className="tabpanel-main common-card project-history-wrape">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="user-search-flex">
                <Index.Box className="user-page-sub-title-box">
                  <Index.Typography
                    className="user-page-sub-title"
                    component="h2"
                    variant="h2"
                  >
                    Payment History
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="userlist-btn-flex">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search"
                      />
                      <img
                        src={PageIndex.Svg.search}
                        className="search-grey-img"
                        alt="search grey img"
                      ></img>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="page-table-main user-details-table-main">
                <Index.Box className="data-table-main">
                  <Index.DataGrid
                        rows={paymentData}
                        // columns={columns}
                        columns={columns.map((col) => ({
                        ...col,
                        renderHeader: (params) => <PageIndex.CustomHeader {...params} column={col} />,
                      }))}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 15 },
                          },
                        }}
                        pageSizeOptions={[15, 20, 25]}
                      />
                      </Index.Box>
             
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
       
      </Index.Box>
    </>
  );
};

export default UserPaymentDetails;
