import React, { useState } from 'react'
import Index from '../../../Index'
import PageIndex from '../../../PageIndex'
import PropTypes from 'prop-types';

// for tabs design

function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
            <div
                  role="tabpanel"
                  hidden={value !== index}
                  id={`simple-tabpanel-${index}`}
                  aria-labelledby={`simple-tab-${index}`}
                  {...other}
            >
                  {value === index && (
                        <Index.Box sx={{ p: 3 }}>
                              <Index.Typography>{children}</Index.Typography>
                        </Index.Box>
                  )}
            </div>
      );
}

TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
};

function a11yProps(index) {
      return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
      };
}


export default function MediaLibrary() {
      const filter = [
            {
                  text: "Audio",
                  selected: true
            },
            {
                  text: "Images",
                  selected: false
            },
            {
                  text: "3D VR Files",
                  selected: false
            },
            {
                  text: "Video Files",
                  selected: false
            },
      ]
      // for tabs
      const [filterState, setFilterState] = React.useState(filter);
      const [value, setValue] = React.useState(0);

      const handleChange = (event, newValue) => {
            setValue(newValue);
      };

      // for page navigation
      const navigate = PageIndex.useNavigate()

      // for delete modal
      const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
      const handleOpenDeleteModal = () => setOpenDeleteModal(true);
      const handleCloseDeleteModal = () => setOpenDeleteModal(false);

      // pagination
      const [page, setPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(15);
      const [count, setCount] = useState(8);
      const handleChangePage = (event, newPage) => {
            setPage(newPage);
      };

      const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
      };
      return (
            <>

                  <Index.Box className="dashboard-content media-library-content">
                        <Index.Box className="user-list-flex">
                              <Index.Box className="user-page-title-box">
                                    <Index.Typography className='user-page-title' component='h2' variant='h2'>Media Library</Index.Typography>
                              </Index.Box>
                              <Index.Box className="userlist-btn-flex">
                              <Index.Box className="userlist-inner-btn-flex">
                                          <PageIndex.PrimaryButton className='adduser-btn comman-primary-btn' title="Upload" btnLabel="Upload" onClick={() => {
                                                navigate("/user/upload-file")
                                          }} />
                                    </Index.Box>
                                    <Index.Box className="user-search-main">
                                          <Index.Box className="user-search-box">
                                                <Index.Box className="form-group">
                                                      <Index.TextField
                                                            fullWidth
                                                            id="fullWidth"
                                                            className="form-control"
                                                            placeholder="Search"
                                                      />
                                                      <img
                                                            src={PageIndex.Svg.search}
                                                            className="search-grey-img" alt='search grey img'
                                                      ></img>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                        <Index.Box className="tabs-main-box">
                              <Index.Box sx={{ width: '100%' }}>
                                    <Index.Box className="media-section">
                                          <Index.Box className="media-btns-main">
                                                {filterState.map((data, key) => {
                                                      return (
                                                            <Index.Button title={data.text} className={`media-btns ${data?.selected ? "active" : ""}`} onClick={() => {
                                                                  const dataItem = filter.map((item, index) => {
                                                                        if (index === key) {
                                                                              item.selected = true
                                                                        } else {
                                                                              item.selected = false
                                                                        }
                                                                        return item
                                                                  })
                                                                  setFilterState(dataItem)
                                                            }}>{data.text}</Index.Button>
                                                      )
                                                })}
                                                {/* <Index.Button className='media-btns'>Images</Index.Button>
                                                <Index.Button className='media-btns'>3D VR Files</Index.Button>
                                                <Index.Button className='media-btns'>Video Files</Index.Button> */}
                                          </Index.Box>
                                          <Index.Box className='grid-row'>
                                                <Index.Box sx={{ width: 1 }} className="grid-main">
                                                      <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }} className="grid-column">
                                                                  <Index.Box className="media-main-card common-card">
                                                                        <Index.Box className="media-img-box">
                                                                              <img src={PageIndex.Jpg.avtarimages} className='media-img' />
                                                                        </Index.Box>
                                                                        <Index.Box className="media-flex">
                                                                              <Index.Box className="media-content-box">
                                                                                    <Index.Typography className='media-caption-text'>Caption</Index.Typography>
                                                                                    <Index.Typography className='media-size-text'>size</Index.Typography>
                                                                              </Index.Box>
                                                                              <Index.Button className='media-trash-btn' title="Delete" onClick={() => {
                                                                                    handleOpenDeleteModal()
                                                                              }}><img src={PageIndex.Svg.trash} className='trash-icon' /></Index.Button>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }} className="grid-column">
                                                                  <Index.Box className="media-main-card common-card">
                                                                        <Index.Box className="media-img-box">
                                                                              <img src={PageIndex.Jpg.avtarimages} className='media-img' />
                                                                        </Index.Box>
                                                                        <Index.Box className="media-flex">
                                                                              <Index.Box className="media-content-box">
                                                                                    <Index.Typography className='media-caption-text'>Caption</Index.Typography>
                                                                                    <Index.Typography className='media-size-text'>size</Index.Typography>
                                                                              </Index.Box>
                                                                              <Index.Button className='media-trash-btn' title="Delete" onClick={() => {
                                                                                    handleOpenDeleteModal()
                                                                              }}><img src={PageIndex.Svg.trash} className='trash-icon' /></Index.Button>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }} className="grid-column">
                                                                  <Index.Box className="media-main-card common-card">
                                                                        <Index.Box className="media-img-box">
                                                                              <img src={PageIndex.Jpg.avtarimages} className='media-img' />
                                                                        </Index.Box>
                                                                        <Index.Box className="media-flex">
                                                                              <Index.Box className="media-content-box">
                                                                                    <Index.Typography className='media-caption-text'>Caption</Index.Typography>
                                                                                    <Index.Typography className='media-size-text'>size</Index.Typography>
                                                                              </Index.Box>
                                                                              <Index.Button className='media-trash-btn' title="Delete" onClick={() => {
                                                                                    handleOpenDeleteModal()
                                                                              }}><img src={PageIndex.Svg.trash} className='trash-icon' /></Index.Button>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }} className="grid-column">
                                                                  <Index.Box className="media-main-card common-card">
                                                                        <Index.Box className="media-img-box">
                                                                              <img src={PageIndex.Jpg.avtarimages} className='media-img' />
                                                                        </Index.Box>
                                                                        <Index.Box className="media-flex">
                                                                              <Index.Box className="media-content-box">
                                                                                    <Index.Typography className='media-caption-text'>Caption</Index.Typography>
                                                                                    <Index.Typography className='media-size-text'>size</Index.Typography>
                                                                              </Index.Box>
                                                                              <Index.Button className='media-trash-btn' title="Delete" onClick={() => {
                                                                                    handleOpenDeleteModal()
                                                                              }}><img src={PageIndex.Svg.trash} className='trash-icon' /></Index.Button>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }} className="grid-column">
                                                                  <Index.Box className="media-main-card common-card">
                                                                        <Index.Box className="media-img-box">
                                                                              <img src={PageIndex.Jpg.avtarimages} className='media-img' />
                                                                        </Index.Box>
                                                                        <Index.Box className="media-flex">
                                                                              <Index.Box className="media-content-box">
                                                                                    <Index.Typography className='media-caption-text'>Caption</Index.Typography>
                                                                                    <Index.Typography className='media-size-text'>size</Index.Typography>
                                                                              </Index.Box>
                                                                              <Index.Button className='media-trash-btn' title="Delete" onClick={() => {
                                                                                    handleOpenDeleteModal()
                                                                              }}><img src={PageIndex.Svg.trash} className='trash-icon' /></Index.Button>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }} className="grid-column">
                                                                  <Index.Box className="media-main-card common-card">
                                                                        <Index.Box className="media-img-box">
                                                                              <img src={PageIndex.Jpg.avtarimages} className='media-img' />
                                                                        </Index.Box>
                                                                        <Index.Box className="media-flex">
                                                                              <Index.Box className="media-content-box">
                                                                                    <Index.Typography className='media-caption-text'>Caption</Index.Typography>
                                                                                    <Index.Typography className='media-size-text'>size</Index.Typography>
                                                                              </Index.Box>
                                                                              <Index.Button className='media-trash-btn' title="Delete" onClick={() => {
                                                                                    handleOpenDeleteModal()
                                                                              }}><img src={PageIndex.Svg.trash} className='trash-icon' /></Index.Button>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }} className="grid-column">
                                                                  <Index.Box className="media-main-card common-card">
                                                                        <Index.Box className="media-img-box">
                                                                              <img src={PageIndex.Jpg.avtarimages} className='media-img' />
                                                                        </Index.Box>
                                                                        <Index.Box className="media-flex">
                                                                              <Index.Box className="media-content-box">
                                                                                    <Index.Typography className='media-caption-text'>Caption</Index.Typography>
                                                                                    <Index.Typography className='media-size-text'>size</Index.Typography>
                                                                              </Index.Box>
                                                                              <Index.Button className='media-trash-btn' title="Delete" onClick={() => {
                                                                                    handleOpenDeleteModal()
                                                                              }}><img src={PageIndex.Svg.trash} className='trash-icon' /></Index.Button>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                            <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 4", lg: "span 3" }} className="grid-column">
                                                                  <Index.Box className="media-main-card common-card">
                                                                        <Index.Box className="media-img-box">
                                                                              <img src={PageIndex.Jpg.avtarimages} className='media-img' />
                                                                        </Index.Box>
                                                                        <Index.Box className="media-flex">
                                                                              <Index.Box className="media-content-box">
                                                                                    <Index.Typography className='media-caption-text'>Caption</Index.Typography>
                                                                                    <Index.Typography className='media-size-text'>size</Index.Typography>
                                                                              </Index.Box>
                                                                              <Index.Button className='media-trash-btn' title="Delete" onClick={() => {
                                                                                    handleOpenDeleteModal()
                                                                              }}><img src={PageIndex.Svg.trash} className='trash-icon' /></Index.Button>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>

                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>

                        <Index.Box className="pagination-main">
                              <PageIndex.Pagination
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    count={count}
                              />
                        </Index.Box>
                  </Index.Box >
                  <PageIndex.DeleteModal openDeleteModal={openDeleteModal} handleCloseDeleteModal={handleCloseDeleteModal} />
            </>
      )
}
