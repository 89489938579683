import React from 'react'

export default function Loader(props) {
      return (
            <>
                  <div class={`overlay-main ${props.className} `}>
                        <div class="spinner-box">
                              <div class="pulse-container">
                                    <div class="pulse-bubble pulse-bubble-1"></div>
                                    <div class="pulse-bubble pulse-bubble-2"></div>
                                    <div class="pulse-bubble pulse-bubble-3"></div>
                              </div>
                        </div>
                  </div>
            </>
      )
}
