import React from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import '../uploadfile/uploadfile.css'


// for tabs design

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// for file upload

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AddProject() {
  // for tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // for page navigatation
  const navigate = PageIndex.useNavigate();
  return (
    <>
      <Index.Box className="dashboard-content project-directory-content">
        <Index.Box className="tabs-main-box">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="user-tabs-main"
              >
                <Index.Tab
                  title="Create New Project"
                  label="Create New Project"
                  {...a11yProps(0)}
                  className="user-tab"
                />
                <Index.Tab
                  title="Edit existing one"
                  label="Edit existing one"
                  {...a11yProps(1)}
                  className="user-tab"
                  onClick={() => {
                    navigate("/user/project-directory", {
                      state: "Draft Projects",
                    });
                  }}
                />
              </Index.Tabs>
            </Index.Box>
            <TabPanel value={value} index={0} className="user-tabpanel">
              <Index.Box className="tabpanel-main">
                <Index.Box className="create-new-project-card common-card">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box-auth">
                          <Index.Box className="form-group">
                            <Index.FormHelperText className="label-input-title">
                              Project Title
                            </Index.FormHelperText>
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Project Title"
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box-auth">
                          <Index.FormHelperText className="label-input-title">
                            Description
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextareaAutosize
                              aria-label="minimum height"
                              // minRows={3}
                              placeholder="Description"
                              className="form-control-textarea"
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="add-tags-main">
                          <Index.Box className="add-tags-flex">
                            <Index.Box className="add-tags-inner-flex">
                              <Index.Box className="add-tags-box">
                                <Index.Typography className="add-tags-text">
                                  Tags
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="add-tags-box">
                                <Index.Typography className="add-tags-text">
                                  Tags
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="add-tags-box">
                                <Index.Typography className="add-tags-text">
                                  Tags
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="add-tags-box">
                                <Index.Typography className="add-tags-text">
                                  Tags
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box title="Add" className="add-tags-box tags-addd-plus-main">
                                <img
                                  src={PageIndex.Svg.plus}
                                  className="tag-upload-plus-icon"
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box-auth file-upload-box">
                          <Index.FormHelperText className="label-input-title">
                             Project Thumbnail:
                          </Index.FormHelperText>
                          <Index.Box className="file-upload-btn-main media-upload-btn-main">
                            <Index.Button
                              title="Choose Project Thumbnail"
                              component="label"
                              variant="contained"
                              className="file-upload-btn media-upload-btn"
                            >
                              <img
                                src={PageIndex.Svg.plus}
                                className="file-upload-plus-icon"
                              />
                              <VisuallyHiddenInput type="file" />
                            </Index.Button>
                            <Index.Typography className="choose-img-text">
                              Choose image from gallery
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="user-btn-flex">
                          <PageIndex.PrimaryButton
                            className="create-user-btn comman-primary-btn"
                            btnLabel="Next"
                            title="Next"
                            onClick={() => {
                              navigate("/user/new-project");
                            }}
                          />
                          <PageIndex.BorderButton
                            className="cancel-user-btn border-btn"
                            title="Cancel"
                            btnLabel="Cancel"
                            onClick={() => {
                              navigate("/user/project-directory");
                            }}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={1} className="user-tabpanel">
              <Index.Box className="tabpanel-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    ></Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </TabPanel>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
