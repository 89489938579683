import React from 'react'
import Index from '../../../Index'
import PageIndex from '../../../PageIndex'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

// for tabs design

function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
            <div
                  role="tabpanel"
                  hidden={value !== index}
                  id={`simple-tabpanel-${index}`}
                  aria-labelledby={`simple-tab-${index}`}
                  {...other}
            >
                  {value === index && (
                        <Index.Box sx={{ p: 3 }}>
                              <Index.Typography>{children}</Index.Typography>
                        </Index.Box>
                  )}
            </div>
      );
}

TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
};

function a11yProps(index) {
      return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
      };
}

export default function UploadSuccessfully() {
      const location=PageIndex.useLocation()
      const tabNum=location?.state?.value
console.log(tabNum,1245);
      // for tabs
      const [value, setValue] = React.useState(tabNum?tabNum:0);
      // const [value, setValue] = React.useState(tabNum);
      const handleChange = (event, newValue) => {
            setValue(newValue);
      };

      // for page navigation
      const navigate = PageIndex.useNavigate()


      return (
            <>
                  <Index.Box className="dashboard-content upload-successsfully-content">
                        <Index.Box className="tabs-main-box">
                              <Index.Box sx={{ width: '100%' }}>
                                    <Index.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                          <Index.Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='user-tabs-main'>
                                                <Index.Tab label="Audio Files" {...a11yProps(0)} className="user-tab" />
                                                <Index.Tab label="Images" {...a11yProps(1)} className="user-tab" />
                                                <Index.Tab label="3D VR Files" {...a11yProps(1)} className="user-tab" />
                                                <Index.Tab label="Video Files " {...a11yProps(0)} className="user-tab" />
                                              
                                          </Index.Tabs>
                                    </Index.Box>
                                    <TabPanel value={value} index={0} className="user-tabpanel">
                                          <Index.Box className="tabpanel-main">
                                                <Index.Box className="create-new-project-card common-card successfully-card">
                                                      <Index.Box sx={{ width: 1 }} className="grid-main">
                                                            <Index.Box display="grid" className="display-row" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">

                                                                        <Index.Box className="successfully-card-main">
                                                                              <Index.Box className="upload-sucsess-text">
                                                                                    Media File Uploaded Successfully
                                                                              </Index.Box>
                                                                              <img src={PageIndex.Svg.closecircle} className='successfully-icon' />
                                                                        </Index.Box>
                                                                  </Index.Box>

                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                                                                        <Index.Box className="user-btn-flex">
                                                                              <PageIndex.PrimaryButton className='cancel-user-btn comman-primary-btn' btnLabel="Ok" title="Ok" onClick={() => {
                                                                                    navigate("/user/media-library")
                                                                              }} />
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                    </TabPanel>
                                    <TabPanel value={value} index={1} className="user-tabpanel">
                                          <Index.Box className="tabpanel-main">
                                                <Index.Box className="create-new-project-card common-card successfully-card">
                                                      <Index.Box sx={{ width: 1 }} className="grid-main">
                                                            <Index.Box display="grid" className="display-row" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">

                                                                        <Index.Box className="successfully-card-main">
                                                                              <Index.Box className="upload-sucsess-text">
                                                                                    Media File Uploaded Successfully
                                                                              </Index.Box>
                                                                              <img src={PageIndex.Svg.closecircle} className='successfully-icon' />
                                                                        </Index.Box>
                                                                  </Index.Box>

                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                                                                        <Index.Box className="user-btn-flex">
                                                                              <PageIndex.PrimaryButton className='cancel-user-btn comman-primary-btn' btnLabel="Ok" title="Ok" onClick={() => {
                                                                                    navigate("/user/media-library")
                                                                              }} />
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                    </TabPanel>

                                    <TabPanel value={value} index={2} className="user-tabpanel">
                                          <Index.Box className="tabpanel-main">
                                                <Index.Box className="create-new-project-card common-card successfully-card">
                                                      <Index.Box sx={{ width: 1 }} className="grid-main">
                                                            <Index.Box display="grid" className="display-row" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">

                                                                        <Index.Box className="successfully-card-main">
                                                                              <Index.Box className="upload-sucsess-text">
                                                                                    Media File Uploaded Successfully
                                                                              </Index.Box>
                                                                              <img src={PageIndex.Svg.closecircle} className='successfully-icon' />
                                                                        </Index.Box>
                                                                  </Index.Box>

                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                                                                        <Index.Box className="user-btn-flex">
                                                                              <PageIndex.PrimaryButton className='cancel-user-btn comman-primary-btn' btnLabel="Ok" title="Ok" onClick={() => {
                                                                                    navigate("/user/media-library")
                                                                              }} />
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                    </TabPanel>

                                     <TabPanel value={value} index={3} className="user-tabpanel">
                                          <Index.Box className="tabpanel-main">
                                                <Index.Box className="create-new-project-card common-card successfully-card">
                                                      <Index.Box sx={{ width: 1 }} className="grid-main">
                                                            <Index.Box display="grid" className="display-row" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">

                                                                        <Index.Box className="successfully-card-main">
                                                                              <Index.Box className="upload-sucsess-text">
                                                                                    Media File Uploaded Successfully
                                                                              </Index.Box>
                                                                              <img src={PageIndex.Svg.closecircle} className='successfully-icon' />
                                                                        </Index.Box>
                                                                  </Index.Box>

                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                                                                        <Index.Box className="user-btn-flex">
                                                                              <PageIndex.PrimaryButton className='cancel-user-btn comman-primary-btn' btnLabel="Ok" title="Ok" onClick={() => {
                                                                                    navigate("/user/media-library")
                                                                              }} />
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                          </Index.Box>
                                    </TabPanel>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>

            </>
      )
}
