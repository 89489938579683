import PrimaryButton from "../component/common/button/PrimaryButton";
import Header from "./user/defaulLayout/Header";
import Sidebar from "./user/defaulLayout/Sidebar";

import Png from "../assets/Png";
import Svg from "../assets/Svg";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { adminLogout } from "../redux/features/slices/admin/AdminSlice";
import DeleteModal from "./common/deletemodal/DeleteModal";
import ViewQRCode from "../container/user/pages/viewqrcode/ViewQRCode";
import { userLogout } from "../redux/features/slices/user/UserSlice";
import { CustomHeader } from "./common/custometableheader/CustomTableheader";

const PageIndex = {
    PrimaryButton,
    Header,
    Sidebar,
    Png,
    Svg,
    useLocation,
    useNavigate,
    useDispatch,
    useSelector,
    adminLogout,
    userLogout,
    DeleteModal,
    ViewQRCode,
    createSearchParams,
    CustomHeader
}

export default PageIndex;