import React from 'react'
import Index from '../../Index';
const Pagination = ({page,rowsPerPage,handleChangePage,handleChangeRowsPerPage,count}) => {
    // const [page, setPage] = React.useState(2);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    // const handleChangePage = (event, newPage) => {
    //   setPage(newPage);
    // };
  
    // const handleChangeRowsPerPage = (event) => {
    //   setRowsPerPage(parseInt(event.target.value, 10));
    //   setPage(0);
    // };
  return (
    <Index.TablePagination
    component="div"
    count={count}
    page={page}
    onPageChange={handleChangePage}
    rowsPerPage={rowsPerPage}
    rowsPerPageOptions={[15, 20, 25]}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />
  )
}

export default Pagination