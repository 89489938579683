import React, { useEffect, useState } from "react";
import Index from "../../Index";
import PageIndex from "../../PageIndex";

export default function Sidebar(props) {
  // for submenu

  const location = PageIndex.useLocation();
  const { userData } = PageIndex.useSelector((state) => state.user);
  const logo = userData?.logo;
  const [openQrCode, setOpenQrCode] = React.useState(false);
  const handleClickQrCode = () => {
    setOpenQrCode(!openQrCode);
  };

  const [openReport, setOpenReport] = React.useState(false);
  const handleClickReport = () => {
    setOpenReport(!openReport);
  };

  const [screen, setScreen] = useState("");
  useEffect(() => {
    if (window.screen.width < 768) {
      setScreen("Mobile");
    } else {
      setScreen("Desktop");
    }
  }, [window.screen.availHeight]);

  // for custom switch design

  const IOSSwitch = Index.styled((props) => (
    <Index.Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 34,
    height: 20,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 3,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#3e54a0",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 14,
      height: 14,
      boxShadow: "none",
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#5a5959" : "#3e54a0",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <>
      <Index.Box
        className={`user-sidebar-main ${
          (screen == "Mobile" ? !props.open : props.open) ? "active" : ""
        }`}
      >
        <Index.Box className="user-sidebar-inner-main">
          <Index.Box className="user-sidebar-logo-main">
            <Index.Box className="user-sibar-logo-flex">
              <Index.Link className="" to="/user/dashboard">
                <img
                  src={
                    logo
                      ? logo instanceof Blob
                        ? URL.createObjectURL(logo)
                        : `${process.env.REACT_APP_API_BASE_IMAGE_URL}${logo}`
                      : PageIndex.Png.logo
                  }
                  className="user-sidebar-logo"
                  alt="logo"
                ></img>
              </Index.Link>
              <Index.Typography className="user-sidebar-logo-text">
                {/* {userData?.companyName ? userData?.companyName:"Web AR" } */}
                Code Scooter
              </Index.Typography>
            </Index.Box>
            <Index.Button
              onClick={() => {
                props.setOpen(!props.open);
                document.body.classList[props.open ? "add" : "remove"](
                  "body-overflow"
                );
              }}
              className="sidebar-close-btn"
            >
              <img
                src={PageIndex.Svg.closeicon}
                className="close-icon"
                alt="logo"
              />
            </Index.Button>
          </Index.Box>

          <Index.Box className="user-sidebar-list-main">
            <Index.List className="user-sidebar-list">
              <Index.ListItem className="user-sidebar-listitem">
                  <Index.Link
                    to="/user/dashboard"
                    title="Dashboard"
                    // className="user-sidebar-link active"
                    className={
                      location?.pathname.includes("/user/dashboard")
                        ? "user-sidebar-link active"
                        : "user-sidebar-link"
                    }
                    
                    onClick={() => {
                      window.innerWidth <= 1023 && props.setOpen(!props.open);
                    }}
                  >
                    <img
                      src={PageIndex.Png.dashboard}
                      alt="sidebar icon"
                      className="user-sidebar-icons"
                    />
                    <span className="sidebar-link-span">Dashboard</span>
                  </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="user-sidebar-listitem">
                  <Index.Link
                   title=" Project Directory"
                    to="/user/project-directory"
                    className={
                      location?.pathname.includes("/user/project-directory")
                        ? "user-sidebar-link active"
                        : "user-sidebar-link"
                    }
                    onClick={() => {
                      window.innerWidth <= 1023 && props.setOpen(!props.open);
                    }}
                  >
                    <img
                      src={PageIndex.Png.projectdirectory}
                      alt="sidebar icon"
                      className="user-sidebar-icons"
                    />
                    <span className="sidebar-link-span">Project Directory</span>
                  </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="user-sidebar-listitem user-submenu-listitem-main">
                <Index.Box className="user-submenu-link">
                    <Index.Box
                     title="QR Code"
                      onClick={handleClickQrCode}
                      className="user-submenu-link-box user-sidebar-link"
                    >
                      <img
                        src={PageIndex.Png.qrcode}
                        alt="sidebar icon"
                        className="user-sidebar-icons"
                      />
                      <span className="sidebar-link-span">QR Code</span>
                      {openQrCode ? (
                        <Index.ExpandLess className="expandless-icon" />
                      ) : (
                        <Index.ExpandMore className="expandmore-icon" />
                      )}
                    </Index.Box>
                  <Index.Box className="submenu-main">
                    <Index.Collapse
                      in={openQrCode}
                      timeout="auto"
                      unmountOnExit
                      className="submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="user-submenu-submenulist"
                      >
                        <Index.ListItem className="user-submenu-listitem">
                            <Index.Link
                             title=" QR Code List"
                              className={
                                location?.search.includes("?tabId=0")
                                  ? "user-sidebar-link active"
                                  : "user-sidebar-link"
                              }
                              to={{
                                pathname: "/user/qr-code-list",
                                search: PageIndex?.createSearchParams({
                                  tabId: 0,
                                }).toString(),
                              }}
                              onClick={() => {
                                window.innerWidth <= 1023 && props.setOpen(!props.open);
                              }}
                            >
                              <img
                                src={PageIndex.Png.qrcode}
                                alt="sidebar icon"
                                className="user-sidebar-icons"
                              />
                              <span className="sidebar-link-span">
                                QR Code List
                              </span>
                            </Index.Link>
                        </Index.ListItem>
                        <Index.ListItem className="user-submenu-listitem">
                            <Index.Link
                               title="Bulk QR Code"
                              className={
                                location?.search.includes("?tabId=1")
                                  ? "user-sidebar-link active"
                                  : "user-sidebar-link"
                              }
                              // className="user-submenu-link user-sidebar-link"
                              to={{
                                pathname: "/user/qr-code-list",
                                search: PageIndex?.createSearchParams({
                                  tabId: 1,
                                }).toString(),
                              }}
                              onClick={() => {
                                window.innerWidth <= 1023 && props.setOpen(!props.open);
                              }}
                            >
                              <img
                                src={PageIndex.Png.qrcode}
                                alt="sidebar icon"
                                className="user-sidebar-icons"
                              />
                              <span className="sidebar-link-span">
                                Bulk QR Code
                              </span>
                            </Index.Link>
                        </Index.ListItem>
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
              <Index.ListItem className="user-sidebar-listitem">
                  <Index.Link
                   title="Media Library"
                    to="/user/media-library"
                    className={
                      location?.pathname.includes("/user/media-library")
                        ? "user-submenu-link user-sidebar-link active"
                        : "user-submenu-link user-sidebar-link"
                    }
                    onClick={() => {
                      window.innerWidth <= 1023 && props.setOpen(!props.open);
                    }}
                  >
                    <img
                      src={PageIndex.Png.mediaplayer}
                      alt="sidebar icon"
                      className="user-sidebar-icons"
                    />
                    <span className="sidebar-link-span">Media Library</span>
                  </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="user-sidebar-listitem">
                  <Index.Link
                   title="Analytics"
                    to="/user/analytics"
                    className={
                      location?.pathname.includes("/user/analytics")
                        ? "user-submenu-link user-sidebar-link active"
                        : "user-submenu-link user-sidebar-link"
                    }
                    onClick={() => {
                      window.innerWidth <= 1023 && props.setOpen(!props.open);
                    }}
                  >
                    <img
                      src={PageIndex.Png.analytics}
                      alt="sidebar icon"
                      className="user-sidebar-icons"
                    />
                    <span className="sidebar-link-span">Analytics</span>
                  </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="user-sidebar-listitem user-submenu-listitem-main">
                <Index.Box className="user-submenu-link">
                    <Index.Box
                     title="Reports"
                      onClick={handleClickReport}
                      className="user-submenu-link-box user-sidebar-link"
                    >
                      <img
                        src={PageIndex.Png.reports}
                        alt="sidebar icon"
                        className="user-sidebar-icons"
                      />
                      <span className="sidebar-link-span">Reports</span>
                      {openReport ? (
                        <Index.ExpandLess className="expandless-icon" />
                      ) : (
                        <Index.ExpandMore className="expandmore-icon" />
                      )}
                    </Index.Box>
                  <Index.Box className="submenu-main">
                    <Index.Collapse
                      in={openReport}
                      timeout="auto"
                      unmountOnExit
                      className="submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="user-submenu-submenulist"
                      >
                        <Index.ListItem className="user-submenu-listitem">
                            <Index.Link
                             title=" Project Reports"
                              to="/user/project-report"
                              className={
                                location?.pathname.includes(
                                  "/user/project-report"
                                )
                                  ? "user-submenu-link user-sidebar-link active"
                                  : "user-submenu-link user-sidebar-link"
                              }
                              onClick={() => {
                                window.innerWidth <= 1023 && props.setOpen(!props.open);
                              }}
                            >
                              <img
                                src={PageIndex.Png.projectdirectory}
                                alt="sidebar icon"
                                className="user-sidebar-icons"
                              />
                              <span className="sidebar-link-span">
                                Project Reports
                              </span>
                            </Index.Link>
                        </Index.ListItem>
                        <Index.ListItem className="user-submenu-listitem">
                            <Index.Link
                             title="QR Code Reports"
                              className={
                                location?.pathname.includes(
                                  "/user/qr-code-report"
                                )
                                  ? "user-submenu-link user-sidebar-link active"
                                  : "user-submenu-link user-sidebar-link"
                              }
                              to="/user/qr-code-report"
                              onClick={() => {
                                window.innerWidth <= 1023 && props.setOpen(!props.open);
                              }}
                            >
                              <img
                                src={PageIndex.Png.projectdirectory}
                                alt="sidebar icon"
                                className="user-sidebar-icons"
                              />
                              <span className="sidebar-link-span">
                                QR Code Reports
                              </span>
                            </Index.Link>
                        </Index.ListItem>
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>

              <Index.ListItem className="user-sidebar-listitem">
                  <Index.Link
                   title="Help and Support"
                    to="/user/help-and-support"
                    className="user-sidebar-link"
                    onClick={() => {
                      window.innerWidth <= 1023 && props.setOpen(!props.open);
                    }}
                  >
                    <img
                      src={PageIndex.Svg.help}
                      alt="sidebar icon"
                      className="user-sidebar-icons"
                    />
                    <span className="sidebar-link-span">Help and Support</span>
                  </Index.Link>
              </Index.ListItem>
            </Index.List>
          </Index.Box>

          {/* <Index.Box className="switch-main user-sidebar-switch-main">
                                    <Index.FormControlLabel
                                          control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                                          label="Dark Mode" className='switch-lable'
                                    />
                              </Index.Box> */}
        </Index.Box>
      </Index.Box>
    </>
  );
}
