import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
// start checkbox

// for custom checkbox design

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  border: "1px solid #114A65",
  width: 12,
  height: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

// Inspired by blueprintjs

// end checkbox

// for tabs design

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// for published table data

const rows = [
  {
    id: 1,
    SrNo: "01",
    SerialCode: "QR1",
    QrTitle: "QR1",

    ProjectThumbnail: "",
    StartDate: "02/23/2023",
    EndDate: "02/24/2023",
  },
  {
    id: 2,
    SrNo: "02",
    SerialCode: "QR1",
    QrTitle: "QR1",

    ProjectThumbnail: "",
    StartDate: "02/23/2023",
    EndDate: "02/24/2023",
  },
  {
    id: 3,
    SrNo: "03",
    SerialCode: "QR1",
    QrTitle: "QR1",

    ProjectThumbnail: "",
    StartDate: "02/23/2023",
    EndDate: "02/24/2023",
  },
  {
    id: 4,
    SrNo: "04",
    SerialCode: "QR1",
    QrTitle: "QR1",

    ProjectThumbnail: "",
    StartDate: "02/23/2023",
    EndDate: "02/24/2023",
  },
  {
    id: 5,
    SrNo: "05",
    SerialCode: "QR1",
    QrTitle: "QR1",

    ProjectThumbnail: "",
    StartDate: "02/23/2023",
    EndDate: "02/24/2023",
  },
];

export default function QRCodeList() {
  // for page navigation
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  const tabId = new URLSearchParams(location.search).get("tabId");

  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  // const toggleSelectAll = () => {
  //   if (selectedItems.length === nonPublishedtablerows.length) {
  //     setSelectedItems([]);
  //     setSelectAll(false);
  //   } else {
  //     setSelectedItems(nonPublishedtablerows.map((row) => row.ProjectTitle));
  //     setSelectAll(true);
  //   }
  // };

  // Function to handle individual row checkbox selection
  // const handleChildCheckbox = (name) => {
  //   const updatedSelectedItems = [...selectedItems];
  //   if (updatedSelectedItems.includes(name)) {
  //     updatedSelectedItems.splice(updatedSelectedItems.indexOf(name), 1);
  //   } else {
  //     updatedSelectedItems.push(name);
  //   }
  //   setSelectedItems(updatedSelectedItems);

  //   // Check if all individual rows are selected to update the "Select All" checkbox
  //   if (updatedSelectedItems.length === nonPublishedtablerows.length) {
  //     setSelectAll(true);
  //   } else {
  //     setSelectAll(false);
  //   }
  // };

  // for tabs
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    setValue(+tabId);
  }, [tabId]);
  const handleChange = (event, newValue) => {
    navigate({
      pathname: "/user/qr-code-list",
      search: PageIndex?.createSearchParams({
        tabId: newValue,
      }).toString(),
    });
    setValue(newValue);
  };
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // for ViewQrCode modal
  const [openViewQrCode, setOpenViewQrCode] = React.useState(false);
  const handleOpenViewQrCode = () => setOpenViewQrCode(true);
  const handleCloseViewQrCode = () => setOpenViewQrCode(false);

  const nonpublishedcolumns = [
    { field: "id", headerName: "Sr.No." },
    { field: "ProjectTitle", headerName: "Project Title" },
    { field: "Tags", headerName: "Tags" },
    { field: "CreatedDate", headerName: "Created Date" },

    { field: "PublishedDate", headerName: "Published Date" },

    {
      field: "action",
      headerName: "Action",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Index.Box className="table-icon-flex">
          <Index.Button className="table-icon-btn" title="Generate QR">
            <img
              src={PageIndex.Png.qrCode}
              className="table-view-icons"
              alt=""
              onClick={() => {
                navigate("/user/qr-code-generation");
              }}
              onKeyUp={() => {}}
              tabIndex={0}
            />
          </Index.Button>
        </Index.Box>
      ),
    },
  ];

  const columns = [
    { field: "SrNo", headerName: "Sr.No." },
    { field: "SerialCode", headerName: "Serial Code" },
    { field: "QrTitle", headerName: "QR Title" },

    {
      field: "ProjectThumbnail",
      headerName: "Project Thumbnail",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <img
          src={PageIndex.Svg.thumbnail}
          className="table-thumbnail-icons"
          alt="Thumbnail"
        />
      ),
    },
    { field: "StartDate", headerName: " Start Date" },
    { field: "EndDate", headerName: "End Date" },
    {
      field: "action",
      headerName: "Actions",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Index.Box className="table-icon-flex">
          <Index.Button className="table-icon-btn" title="View">
            <img
              src={PageIndex.Svg.view}
              className="table-view-icons"
              alt=""
              onClick={() => {
                handleOpenViewQrCode();
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleOpenViewQrCode();
                }
              }}
              tabIndex={0}
            />
          </Index.Button>
          <Index.Button className="table-icon-btn" title="Share">
            <img
              src={PageIndex.Svg.share}
              className="table-view-icons"
              alt=""
            />
          </Index.Button>
          <Index.Button className="table-icon-btn" title="Download">
            <img
              src={PageIndex.Svg.downloads}
              className="table-view-icons"
              alt=""
            />
          </Index.Button>
        </Index.Box>
      ),
    },
  ];
  const nonpublishedrows = [
    {
      id: 1,
      ProjectTitle: "Project1",
      Tags: "QR1",
      CreatedDate: "02/01/2000",
      PublishedDate: "02/23/2023",
      action: "view",
    },
    {
      id: 2,
      ProjectTitle: "Project1",
      Tags: "QR1",
      CreatedDate: "02/01/2000",
      PublishedDate: "02/23/2023",
      action: "view",
    },
    {
      id: 3,
      ProjectTitle: "Project1",
      Tags: "QR1",
      CreatedDate: "02/01/2000",
      PublishedDate: "02/23/2023",
      action: "view",
    },
    {
      id: 4,
      ProjectTitle: "Project1",
      Tags: "QR1",
      CreatedDate: "02/01/2000",
      PublishedDate: "02/23/2023",
      action: "view",
    },
  ];
  return (
    <>
      <Index.Box className="dashboard-content qr-code-content">
        <Index.Box className="user-list-flex">
          <Index.Box className="user-page-title-box">
            <Index.Typography
              className="user-page-title"
              component="h2"
              variant="h2"
            >
              QR Codes
            </Index.Typography>
          </Index.Box>
        </Index.Box>

        <Index.Box className="tabs-main-box">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="user-tabs-main"
              >
                <Index.Tab
                  title="Published"
                  label="Published"
                  {...a11yProps(0)}
                  className="user-tab"
                />
                <Index.Tab
                  title="Non Published"
                  label="Non Published"
                  {...a11yProps(1)}
                  className="user-tab"
                />
              </Index.Tabs>
            </Index.Box>
            <TabPanel value={value} index={0} className="user-tabpanel">
              <Index.Box className="tabpanel-main common-card">
                <Index.Box
                  sx={{ width: 1 }}
                  className="grid-main user-qr-code-list-published-wrape"
                >
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="user-search-flex">
                        <Index.Box
                          className="user-page-sub-title-box"
                          title="Published"
                        >
                          <Index.Typography
                            className="user-page-sub-title"
                            component="h2"
                            variant="h2"
                          >
                            Published
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="userlist-btn-flex">
                          <Index.Box className="user-search-box">
                            <Index.Box className="form-group">
                              <img
                                src={PageIndex.Svg.search}
                                className="search-grey-img"
                                alt="search grey img"
                              ></img>
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Search"
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="page-table-main qr-published-projects-table">
                        <Index.Box className="data-table-main">
                          <Index.DataGrid
                            rows={rows}
                            // columns={columns}
                            columns={columns.map((col) => ({
                              ...col,
                              renderHeader: (params) => (
                                <PageIndex.CustomHeader
                                  {...params}
                                  column={col}
                                />
                              ),
                            }))}
                            initialState={{
                              pagination: {
                                paginationModel: { page: 0, pageSize: 15 },
                              },
                            }}
                            pageSizeOptions={[15, 20, 25]}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {/* <Index.Box className="pagination-main">
                  <PageIndex.Pagination
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    count={count}
                  />
                </Index.Box> */}
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={1} className="user-tabpanel">
              <Index.Box className="tabpanel-main common-card">
                <Index.Box
                  sx={{ width: 1 }}
                  className="grid-main user-qr-code-list-non-published"
                >
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="user-search-flex">
                        <Index.Box
                          className="user-page-sub-title-box"
                          title="Non Published"
                        >
                          <Index.Typography
                            className="user-page-sub-title"
                            component="h2"
                            variant="h2"
                          >
                            Non Published
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="userlist-btn-flex">
                          <Index.Box className="userlist-inner-btn-flex">
                            <PageIndex.PrimaryButton
                              className="adduser-btn comman-primary-btn"
                              title="Generate QR Code"
                              btnLabel="Generate QR Code"
                              onClick={() =>
                                navigate("/user/qr-code-generation")
                              }
                            />
                          </Index.Box>
                          <Index.Box className="user-search-box user-search-box-inner">
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Search"
                              />
                              <img
                                src={PageIndex.Svg.search}
                                className="search-grey-img"
                                alt="search grey img"
                              ></img>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="page-table-main bulk-non-published-projects-table">
                        <Index.Box className="data-table-main">
                          <Index.DataGrid
                            rows={nonpublishedrows}
                            // columns={nonpublishedcolumns}
                            columns={nonpublishedcolumns.map((col) => ({
                              ...col,
                              renderHeader: (params) => (
                                <PageIndex.CustomHeader
                                  {...params}
                                  column={col}
                                />
                              ),
                            }))}
                            initialState={{
                              pagination: {
                                paginationModel: { page: 0, pageSize: 15 },
                              },
                            }}
                            pageSizeOptions={[15, 20, 25]}
                            checkboxSelection
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                {/* <Index.Box className="pagination-main">
                  <Index.Pagination
                    count={3}
                    variant="outlined"
                    shape="rounded"
                    className="pagination"
                  />
                  <PageIndex.Pagination
                    page={page}
                    rowsPerPage={rowsPerPage}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    count={count}
                  />
                </Index.Box> */}
              </Index.Box>
            </TabPanel>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <PageIndex.ViewQRCode
        openViewQrCode={openViewQrCode}
        handleCloseViewQrCode={handleCloseViewQrCode}
      />
    </>
  );
}
