import usericon from './images/png/user.png';
import logo from './images/png/logo.png';
import edit from './images/png/edit.png';
import userlist1 from './images/png/userlist1.png';
import bergurmenu from './images/png/bergur-menu.png';
import close from './images/png/close.png';
import user1 from './images/png/user-1.png';
import avtar from './images/png/avtar.png';
import profileLogo from './images/png/profile-logo.png';
import dashboard from './images/png/dashboard.9dccc0b7e087c5a2b7eb.png';
import projectdirectory from './images/png/project-directory.416e206f14cffafe8240.png';
import qrcode from './images/png/qr-code.5ec8f2e6c07f5168e16a.png';
import mediaplayer from './images/png/media-player.ae3b14f83d458cd7a963.png';
import analytics from './images/png/analytics.b812918fd6e68cc98baa.png';
import reports from './images/png/reports.0f28f7584c0ffa00b1f1.png';
import markerIcon from './images/png/marker-icon.png';
import viewscanner from './images/png/view-scanner.png';
import analyticView from './images/png/analyticview.png';
import qrCode from './images/png/qr-code.png';




const Png = {
      usericon,
      logo,
      edit,
      userlist1,
      bergurmenu,
      close,
      user1,
      avtar,
      profileLogo,
      dashboard,
      projectdirectory,
      qrcode,
      mediaplayer,
      analytics,
      reports,
      markerIcon,
      viewscanner,
      analyticView,
      qrCode
}

export default Png;