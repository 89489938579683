import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import "./userDetails.css";
import icon from "../../../../../assets/Svg";
import { fetchSingleUserMaster } from "../../../../../redux/features/slices/admin/AdminService";
import PageIndex from "../../../../PageIndex";
import PropTypes from "prop-types";
import UserPaymentDetails from "./UserPaymentDetails";

function createData(
  ProjectTitle,
  ProjectTags,
  PublishDate,
  ORgenerated,
  Serialnumber,
  ProjThumbnails,
  startDate,
  endDate,
  id
) {
  return {
    ProjectTitle,
    ProjectTags,
    PublishDate,
    ORgenerated,
    Serialnumber,
    ProjThumbnails,
    startDate,
    endDate,
    id
  };
}
const columns = [
  { field: "id", headerName: "Sr.No."},
  { field: "ProjectTitle", headerName: "Project Title" },
  { field: "ProjectTags", headerName: "Project Tags" },
  { field: "PublishDate", headerName: "Publish Date" },
  { field: "ORgenerated", headerName: "QR generated" },
  { field: "Serialnumber", headerName: "Serial Number" },
  { field: "ProjThumbnails", headerName: "Proj. Thumbnails", disableColumnMenu:true, sortable:false, renderCell: (params) => (
    <img
      src={PageIndex.Svg.thumbnail}
      className="table-thumbnail-icons"
      alt="Thumbnail"
    />
  ),},
  { field: "startDate", headerName: "Start Date" },
  { field: "endDate", headerName: "End Date" },

];
const rows = [
  createData(
    "PROJ1",
    "Tag1",
    "01/10/2023",
    "Yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "01/10/2023",
    "05/10/2023",
    1
  ),
  createData(
    "PROJ1",
    "Tag1",
    "01/10/2023",
    "Yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "01/10/2023",
    "05/10/2023",
    2
  ),
  createData(
    "PROJ1",
    "Tag1",
    "01/10/2023",
    "Yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "01/10/2023",
    "05/10/2023",
    3
  ),
  createData(
    "PROJ1",
    "Tag1",
    "01/10/2023",
    "Yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "01/10/2023",
    "05/10/2023",
    4
  ),
  createData(
    "PROJ1",
    "Tag1",
    "01/10/2023",
    "Yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "01/10/2023",
    "05/10/2023",
    5
  ),
  createData(
    "PROJ1",
    "Tag1",
    "01/10/2023",
    "Yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "01/10/2023",
    "05/10/2023",
    6
  ),
  createData(
    "PROJ1",
    "Tag1",
    "01/10/2023",
    "Yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "01/10/2023",
    "05/10/2023",
    7
  ),
  createData(
    "PROJ1",
    "Tag1",
    "01/10/2023",
    "Yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "01/10/2023",
    "05/10/2023",
    8
  ),
  createData(
    "PROJ1",
    "Tag1",
    "01/10/2023",
    "Yes",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "01/10/2023",
    "05/10/2023"
    , 9
  ),
  createData(
    "PROJ1",
    "Tag1",
    "01/10/2023",
    "No",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "01/10/2023",
    "05/10/2023",
    10
  ),
  createData(
    "PROJ1",
    "Tag1",
    "01/10/2023",
    "No",
    "QR1",
    <img src={PageIndex.Svg.thumbnail} className="table-thumbnail-icons" />,
    "01/10/2023",
    "05/10/2023",
    11
  ),
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const UserDetails = () => {
  const dispatch = PageIndex.useDispatch();
  const location = PageIndex.useLocation();
  const navigate = PageIndex.useDispatch();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const id = location?.state?.data?._id;
  const data = location?.state?.row;
  console.log(data, 23);
  console.log(location, "location");
  //State
  const [singleUser, setSingleUser] = useState({});
  const [loading, setLoading] = useState(true);
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState(rows.length);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Fetch Single User Call
  const fetchSingleUser = (id) => {
    setLoading(true);
    if (data) {
      console.log("found", data);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setSingleUser(data);
    } else {
      dispatch(fetchSingleUserMaster(id)).then((res) => {
        if (res?.payload?.status == 200) {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setSingleUser(res?.payload?.data);
        } else {
          setSingleUser({});
        }
      });
    }
  };
  useEffect(() => {
    fetchSingleUser(id);
  }, []);
  return (
    <>
      {loading ? (
        <PageIndex.Loader className={"component-loader"} />
      ) : (
        <Index.Box className="dashboard-content user-management-content">
          <Index.Box className="user-list-flex">
            <Index.Box className="user-page-title-box">
              <Index.Typography
                className="user-page-title"
                component="h2"
                variant="h2"
              >
                User Details
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box user-details-dash-box">
                    <Index.Box
                      sx={{ width: 1 }}
                      className="grid-main common-card"
                    >
                      <Index.Box
                        display="grid"
                        className="display-row"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                            xl: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-details-main">
                            <Index.Box className="user-details-flex">
                              <Index.Typography className="user-details-lable">
                                First Name
                              </Index.Typography>
                              <Index.Typography className="user-details-data">
                                {singleUser?.firstName}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                            xl: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-details-main">
                            <Index.Box className="user-details-flex">
                              <Index.Typography className="user-details-lable">
                                Last Name
                              </Index.Typography>
                              <Index.Typography className="user-details-data">
                                {singleUser?.lastName}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                            xl: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-details-main">
                            <Index.Box className="user-details-flex">
                              <Index.Typography className="user-details-lable">
                                Email
                              </Index.Typography>
                              <Index.Typography className="user-details-data">
                                {singleUser?.email}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                            xl: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-details-main">
                            <Index.Box className="user-details-flex">
                              <Index.Typography className="user-details-lable">
                                Phone{" "}
                              </Index.Typography>
                              <Index.Typography className="user-details-data">
                                +91 {singleUser?.phoneNumber}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                            xl: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-details-main">
                            <Index.Box className="user-details-flex">
                              <Index.Typography className="user-details-lable">
                                Address line1
                              </Index.Typography>
                              <Index.Typography className="user-details-data">
                                {singleUser?.addressLine1}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                            xl: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-details-main">
                            <Index.Box className="user-details-flex">
                              <Index.Typography className="user-details-lable">
                                City
                              </Index.Typography>
                              <Index.Typography className="user-details-data">
                                {singleUser?.city}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                            xl: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-details-main">
                            <Index.Box className="user-details-flex">
                              <Index.Typography className="user-details-lable">
                                Address line2
                              </Index.Typography>
                              <Index.Typography className="user-details-data">
                                {singleUser?.addressLine2===""?"-":singleUser?.addressLine2}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                            xl: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-details-main">
                            <Index.Box className="user-details-flex">
                              <Index.Typography className="user-details-lable">
                                State
                              </Index.Typography>
                              <Index.Typography className="user-details-data">
                                {singleUser?.state}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                            xl: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-details-main">
                            <Index.Box className="user-details-flex">
                              <Index.Typography className="user-details-lable">
                                Zip Code
                              </Index.Typography>
                              <Index.Typography className="user-details-data">
                                {singleUser?.zip}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                            xl: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-details-main">
                            <Index.Box className="user-details-flex">
                              <Index.Typography className="user-details-lable">
                                Country
                              </Index.Typography>
                              <Index.Typography className="user-details-data">
                                {singleUser?.country}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                            xl: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-details-main">
                            <Index.Box className="user-details-flex">
                              <Index.Typography className="user-details-lable">
                                Active Project
                              </Index.Typography>
                              <Index.Typography className="user-details-data">
                                11
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="campaigns-section-main">
            <Index.Box className="tabs-main-box">
              <Index.Box sx={{ width: "100%" }}>
                <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Index.Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="user-tabs-main"
                  >
                    <Index.Tab
                      title="Projects"
                      label="Projects"
                      {...a11yProps(0)}
                      className="user-tab"
                    />
                    <Index.Tab
                      title="Payment History"
                      label="Payment History"
                      {...a11yProps(1)}
                      className="user-tab"
                    />
                  </Index.Tabs>
                </Index.Box>
                <TabPanel value={value} index={0} className="user-tabpanel project-tab-wrape">
                  <Index.Box className="tabpanel-main common-card">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        className="display-row"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-search-flex">
                            <Index.Box className="user-page-sub-title-box">
                              <Index.Typography
                                className="user-page-sub-title"
                                component="h2"
                                variant="h2"
                              >
                                Projects
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="userlist-btn-flex">
                              <Index.Box className="user-search-box">
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder="Search"
                                  />
                                  <img
                                    src={PageIndex.Svg.search}
                                    className="search-grey-img"
                                    alt="search grey img"
                                  ></img>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="page-table-main user-details-table-main">
                          <Index.Box className="data-table-main">
                  <Index.DataGrid
                        rows={rows}
                        // columns={columns}
                        columns={columns.map((col) => ({
                        ...col,
                        renderHeader: (params) => <PageIndex.CustomHeader {...params} column={col} />,
                      }))}
          
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 15 },
                          },
                        }}
                        pageSizeOptions={[15, 20, 25]}
                      />
                      </Index.Box>
                            
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                  </Index.Box>
                </TabPanel>
                <TabPanel value={value} index={1} className="user-tabpanel project-history-wrape">
                  <UserPaymentDetails />
                </TabPanel>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default UserDetails;
