import signin from '../assets/images/jpg/sign-in.jpg'
import avtarimages from '../assets/images/jpg/avtarimages.jpg'
import analyticView from "./images/jpg/analyticview.jpg"





const Jpg = {
    signin,
    avtarimages,
    analyticView
}

export default Jpg;