import "./App.css";
import Routes from "./routes/Routes";
import "../src/container/user/auth/auth.css";
import "../src/container/user/auth/auth.responsive.css";
import "../src/assets/style/global.css";
import "../src/component/user/defaulLayout/defaullayout.css";
import "../src/container/user/pages/dashboard/dashboard.css";
import "../src/container/user/pages/layout/layout.css";
import "../src/container/user/pages/projectdirectory/projectdirectory.css";
import "../src/container/user/pages/qrcode/qrcode.css";
import "../src/container/user/pages/addproject/addproject.css";
import "../src/container/user/pages/projectreport/projectreport.css";
import "../src/container/user/pages/uploadsuccessfully/uploadsuccessfully.css";
import "../src/container/user/pages/medialibrary/medialibrary.css";
import "../src/container/user/pages/analytics/analytics.css";
import "../src/container/user/pages/arprojectstudio/arprojectstudio.css";
import "../src/container/user/pages/saveasdraft/saveasdraft.css";
import "../src/container/user/pages/saveandpublish/saveandpublish.css";
import "../src/container/user/pages/asktogenerateqr/asktogenerateqr.css";
import "../src/container/user/pages/publishedqrcode/publishedqrcode.css";
import "../src/container/user/pages/newproject/newproject.css";
import "../src/container/user/pages/qrcodelist/qrcodelist.css";
import "../src/container/user/pages/viewqrcode/viewqrcode.css";
import "../src/container/user/pages/profile/profile.css";
import "../src/container/user/pages/bulkqrcode/bulkqrcode.css";
import "../src/container/user/pages/selectqrcode/selectqrcode.css";
import "../src/container/user/pages/bulkqrsuccessfully/bulkqrsuccessfully.css";
import "../src/container/user/pages/payments/payment.css";
import "../src/container/user/pages/uploadfile/uploadfile.css";
import "../src/container/user/pages/subscription/subscription.css";
import "../src/component/common/deletemodal/deletemodal.css";
import "../src/container/user/pages/qrcodereport/qrcodereport.css";
import "../src/container/user/pages/helpandsupport/helpandsupport.css";
import "../src/container/user/pages/publishedandqrgenerated/publishedandqrgenerated.css";

import "../src/assets/style/global.responsive.css";
import "../src/component/user/defaulLayout/defaullayout.responsive.css";
import "../src/container/user/pages/dashboard/dashboard.responsive.css";
import "../src/container/user/pages/layout/layout.responsive.css";
import "../src/container/user/pages/projectdirectory/projectdirectory.responsive.css";
import "../src/container/user/pages/qrcode/qrcode.responsive.css";
import "../src/container/user/pages/addproject/addproject.responsive.css";
import "../src/container/user/pages/projectreport/projectreport.responsive.css";
import "../src/container/user/pages/uploadsuccessfully/uploadsuccessfully.responsive.css";
import "../src/container/user/pages/medialibrary/medialibrary.responsive.css";
import "../src/container/user/pages/analytics/analytics.reponsive.css";
import "../src/container/user/pages/arprojectstudio/arprojectstudio.responsive.css";
import "../src/container/user/pages/saveasdraft/saveasdraft.responsive.css";
import "../src/container/user/pages/saveandpublish/saveandpublish.responsive.css";
import "../src/container/user/pages/asktogenerateqr/asktogenerateqr.responsive.css";
import "../src/container/user/pages/qrcodegeneration/qrcodegeneration.css";
import "../src/container/user/pages/publishedqrcode/publishedqrcode.responsive.css";
import "../src/container/user/pages/newproject/newproject.responsive.css";
import "../src/container/user/pages/qrcodelist/qrcodelist.responsive.css";
import "../src/container/user/pages/viewqrcode/viewqrcode.responsive.css";
import "../src/container/user/pages/profile/profile.responsive.css";
import "../src/container/user/pages/selectqrcode/selectqrcode.responsive.css";
import "../src/container/user/pages/helpandsupport/helpandsupport.responsive.css";
import "../src/container/user/pages/publishedandqrgenerated/publishedandqrgenerated.responsive.css";
import "../src/container/user/pages/uploadfile/uploadfile.responsive.css";
import "../src/container/user/pages/subscription/subscription.responsive.css";
import "../src/container/user/pages/qrcodegeneration/qrcodegeneration.responsive.css";
import "../src/container/user/pages/saveandpublish/saveandpublish.responsive.css";
import "../src/component/common/loader/loader.css";

// Admin css
import "./container/admin/pages/subscription/subscription.css";
import "./container/admin/pages/emailTemplate/emailTemplate.css";
import "./container/admin/pages/changePassword/adminChangePassword.css";
import "./container/admin/pages/profile/adminProfile.css";
import "../src/container/admin/pages/adminAnalytics/adminanalytics.css";
import "../src/container/admin/pages/adminProjectReport/adminprojectreport.css";
import "../src/container/admin/pages/adminQRCodeReport/adminqrcodereport.css";

import "./container/admin/pages/payment/payment.css";
import "./container/admin/pages/userMangement/userManagement.css";
import { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import "./container/admin/pages/userMangement/deleteModal/deletemodal.css";

function App() {

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Roberto Sans, sans-serif",
      },
    },
  });
  // {
  //   console.log = () => {};
  //   console.error = () => {};
  //   console.debug = () => {};
  // }
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes />
      </div>
    </ThemeProvider>
  );
}

export default App;
