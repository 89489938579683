import PrimaryButton from "../component/common/button/PrimaryButton";
import Svg from "../assets/Svg";
import Png from "../assets/Png";
import Jpg from "../assets/Jpg";
import Header from "../component/user/defaulLayout/Header";
import Sidebar from "../component/user/defaulLayout/Sidebar";
import {
  useNavigate,
  useLocation,
  useParams,
  Navigate,
  Outlet,
  createSearchParams,
} from "react-router-dom";
import { Formik, Form } from "formik";
import { ToastContainer, toast } from "react-toastify";
import {
  adminChangePasswordValidationSchema,
  adminLoginValidationSchema,
  adminResetPasswordValidationSchema,
  adminUpdateProfileValidationSchema,
  adminVerifyOtpValidationSchema,
} from "../validation/Validation";
import DataService, {
  doGet,
  doPost,
  doPostFormData,
  doGetParams,
} from "../config/DataService";
import Api from "../config/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  adminLogin,
  adminForgotPassword,
  adminVerifyOtp,
  adminResetPassword,
  adminChangePassword,
  adminUpdateProfile,
  getRecentUsers,
} from "../redux/features/slices/admin/AdminService";
import AdminPrivateRoute from "../routes/AdminPrivateRoute";
import BorderButton from "../component/common/button/BorderButton";
import AdminHeader from "../component/admin/defaulLayout/AdminHeader";
import AdminSidebar from "../component/admin/defaulLayout/AdminSidebar";
import SaveAsDraft from "./user/pages/saveasdraft/SaveAsDraft";
import SaveAndPublish from "./user/pages/saveandpublish/SaveAndPublish";
import AskToGenerateQr from "./user/pages/asktogenerateqr/AskToGenerateQr";
import DeleteModal from "../component/common/deletemodal/DeleteModal";
import ViewQRCode from "./user/pages/viewqrcode/ViewQRCode";
import PublishedQrCode from "./user/pages/publishedqrcode/PublishedQrCode";
import SelectQrCode from "./user/pages/selectqrcode/SelectQrCode";
import BulkQrSuccessfully from "./user/pages/bulkqrsuccessfully/BulkQrSuccessfully";
import HeatMap from "./admin/pages/adminAnalytics/charts/heatMap/HeatMap";
import Subscription from "./user/pages/subscription/Subscription";

import {
  isValidEmail,
  isValidPhoneNumber,
  userLoginValidationSchema,
  userRegistrationValidationSchema,
  userUpdateProfileValidationSchema,
} from "../validation/UserValidation";
import {
  userForgotPassword,
  userLogin,
  userRegistration,
  userResetPassword,
  userResendOTP,
  userVerifyOTP,
  checkUser,
  userUpdateProfile,
  userChangePassword,
  checkUserVerifyOTP,
} from "../redux/features/slices/user/UserService";
import Loader from "../component/common/loader/Loader";
import { getUserData } from "../redux/features/slices/user/UserSlice";
import Pagination from "../component/common/pagination/Pagination";
import { adminLogout } from "../redux/features/slices/admin/AdminSlice";
import { userLogout } from "../redux/features/slices/user/UserSlice";
import MuiPhoneInput from "material-ui-phone-number";
import { CustomHeader } from "../component/common/custometableheader/CustomTableheader";

const PageIndex = {
  PrimaryButton,
  Loader,
  Svg,
  Header,
  Sidebar,
  AdminHeader,
  AdminSidebar,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
  Formik,
  MuiPhoneInput,
  Form,
  AdminPrivateRoute,
  //Validations
  adminLoginValidationSchema,
  adminResetPasswordValidationSchema,
  adminVerifyOtpValidationSchema,
  adminChangePasswordValidationSchema,
  adminUpdateProfileValidationSchema,
  // user validation
  userRegistrationValidationSchema,
  userLoginValidationSchema,
  userUpdateProfileValidationSchema,
  isValidPhoneNumber,
  isValidEmail,
  ToastContainer,
  toast,
  useDispatch,
  useSelector,
  DataService,
  Api,
  doGet,
  doPost,
  doPostFormData,
  doGetParams,
  //Api's
  adminLogin,
  adminForgotPassword,
  adminVerifyOtp,
  adminResetPassword,
  adminChangePassword,
  adminUpdateProfile,
  getRecentUsers,
  // user Api's
  userRegistration,
  userForgotPassword,
  userVerifyOTP,
  userResendOTP,
  userLogin,
  userResetPassword,
  userChangePassword,
  userUpdateProfile,
  checkUserVerifyOTP,
  checkUser,
  BorderButton,
  Png,
  SaveAsDraft,
  SaveAndPublish,
  AskToGenerateQr,
  DeleteModal,
  ViewQRCode,
  Jpg,
  PublishedQrCode,
  SelectQrCode,
  BulkQrSuccessfully,
  HeatMap,
  createSearchParams,
  getUserData,
  Subscription,
  Pagination,
  adminLogout,
  userLogout,
  CustomHeader
};

export default PageIndex;
