import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import {
  fetchUserMasterList,
  updateUserMasterStatus,
} from "../../../../redux/features/slices/admin/AdminService";
import { Tooltip } from "@mui/material";
// import { CommonModal } from "../../../../common/CommonModal";
import DeleteModal from "../../../../component/common/deletemodal/DeleteModal";
import ActiveDeactiveModal from "../../../../component/common/activedeactivemodal/ActiveDeactiveModal";

// for custom switch design

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3e54a0",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#5a5959" : "#3e54a0",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function UserManagement({ handleDeleteModal }) {
  const navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();

  //States
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [statusId, setStatusId] = useState("");
  console.log(statusId, "statuId");
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [count, setCount] = useState();
  const [open, setOpen] = useState(false);
  const [deleteopen, setDeleteOpen] = useState(false);

  const [active, setActive] = useState();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handelOpenModel = () => {
    setDeleteOpen(true);
  };

  const openActiveDeactiveModal = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDeleteOpen(false);
  };

  //Update user status api call
  const updateUserState = async () => {
    setStatusId(active?._id);
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", active?._id);
    urlencoded.append("status", !active?.isActive);
    setIsChecked(true);
    dispatch(updateUserMasterStatus(urlencoded)).then((res) => {
      console.log(res, "response");
      if (res?.payload?.status == 200) {
        dispatch(fetchUserMasterList()).then((res) => {
          if (res?.payload?.status == 200) {
            fetchUserList();
            handleClose();
          } else {
            setIsChecked(false);
          }
        });
      }
    });
  };

  //Get user api call
  const fetchUserList = async () => {
    setLoading(true);
    dispatch(fetchUserMasterList()).then((res) => {
      if (res?.payload?.status == 200) {
        setTimeout(() => {
          setLoading(false);
          setIsChecked(false);
          setStatusId("");
        }, 1000);
        setUserList(
          res?.payload?.data.map((user, index) => ({
            ...user,
            serialNumber: index + 1,
          }))
        );
        setCount(res?.payload?.data?.length);
      } else {
        setLoading(false);
        setStatusId("");
        setUserList([]);
      }
    });
  };

  //Go to detail page
  const goToUserDetail = (data) => {
    navigate("/admin/user-management/user-details", { state: { data } });
  };

  useEffect(() => {
    fetchUserList();
  }, []);
  const columns = [
    { field: "serialNumber", headerName: "Sr.No.",},
    { field: "firstName", headerName: "User First Name",},
    { field: "lastName", headerName: "User Last Name" },
    { field: "email", headerName: "Email" },
    { field: "phoneNumber", headerName: "Phone" },
    {
      field: "isActive",
      headerName: "Status",
      disableColumnMenu:true, sortable:false,
      renderCell: (params) => (
        <Index.Box className="form-group">
          <Index.Box className="switch-main">
            {isChecked && statusId == params.row?._id ? (
              <Index.CircularProgress size={25} sx={{ color: "#3e53a0" }} />
            ) : (
                <Index.FormControlLabel
                title={params.row?.isActive ? "Active" : "Inactive"}
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={params.row?.isActive}
                      // onChange={() => {
                      //   updateUserState(
                      //     row?._id,
                      //     !row?.isActive
                      //   );
                      // }}
                      onChange={() => {
                        openActiveDeactiveModal();
                        setActive(params.row);
                      }}
                    />
                  }
                  className="switch-lable"
                />
            )}
          </Index.Box>
        </Index.Box>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      disableColumnMenu:true, sortable:false,
      renderCell: (params) => (
        <Index.Box className="table-icon-flex">
            <Index.Button
             title="View"
              className="table-icon-btn"
              onClick={() => goToUserDetail(params.row)}
            >
              <img
                src={PageIndex.Svg.view}
                className="table-view-icons"
                alt=""
              />
            </Index.Button>
            <Index.Button
              className="table-icon-btn"
              title="Delete"
              onClick={() => handelOpenModel()}
            >
              <img
                src={PageIndex.Svg.trash}
                className="table-view-icons"
                alt=""
              />
            </Index.Button>
        </Index.Box>
      ),
    },
  ];
 
  return (
    <>
      {loading && !isChecked ? (
        <PageIndex.Loader className="component-loader" />
      ) : (
        <Index.Box className="dashboard-content user-management-content">
          <Index.Box className="user-list-flex">
            <Index.Box className="user-page-title-box">
              <Index.Typography
                className="user-page-title"
                component="h2"
                variant="h2"
              >
                List of Users
              </Index.Typography>
            </Index.Box>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <img
                      src={PageIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="menu-report-section common-card user-management-wrape">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="page-table-main user-management-table-main">
                    <Index.Box className="data-table-main">
                      <Index.DataGrid
                        rows={userList}
                        // columns={columns}
                        columns={columns.map((col) => ({
                        ...col,
                        renderHeader: (params) => <PageIndex.CustomHeader {...params} column={col} />,
                      }))}
                        getRowId={(row) => row._id}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 15 },
                          },
                        }}
                        pageSizeOptions={[15, 20, 25]}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}

      <DeleteModal
        handleCloseDeleteModal={handleClose}
        openDeleteModal={deleteopen}
        // setOpen={setOpen}
        // handleDeleteModalButton={setOpen}
      />

      <ActiveDeactiveModal
        openActiveDeactiveModal={open}
        handleCloseActiveDeactiveModal={handleClose}
        setOpen={setOpen}
        updateUserState={updateUserState}
      />
    </>
  );
}
